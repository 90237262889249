/* eslint-disable brace-style */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  input,
  model,
  OnChanges,
  output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { PagingComponent } from '@components/paging/paging.component';
import { SearchFieldComponent } from '@components/search-field/search-field.component';
import { FromGeoJsonDirective } from '@core/directive/geojson.directive';
import { PagingInfo } from '@core/model/paging-info.model';
import { User } from '@core/model/user.model';
import { Indexable, KeysOfType } from '@core/utils/general.utils';
import { get, isEmpty, isNil, memoize, omitBy } from 'lodash-es';
import {
  ActionType,
  CellType,
  Column,
  ColumnFilter,
  DefaultAction,
  DefaultType,
  GeneralActions,
  SelectionAction,
  Task,
} from './table.model';

const modules = [
  CommonModule,
  MatDividerModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatSelectModule,
  MatAutocompleteModule,
  SearchFieldComponent,
  FormsModule,
  MatTooltipModule,
  MatTableModule,
  MatSortModule,
  MatMenuModule,
  RouterModule,
  PagingComponent,
  HelpOverlayComponent,
  MatSlideToggleModule,
  MatInputModule,
  MatFormFieldModule,
  FromGeoJsonDirective,
  ReactiveFormsModule,
  FormFieldWrapperComponent,
  MultiSelectComponent,
];

@Component({
  selector: 'smv-table',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: modules,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<N extends number | string, T extends DefaultType<N>, V extends DefaultType<N>>
  implements OnChanges, AfterViewInit
{
  public CellType = CellType;
  public ActionType = ActionType;
  public task: Task<T, N> = {
    selected: false,
    subtasks: [],
  };
  public allSelected = false;

  public columnsDef: Column<T, V>[] = [];
  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource<T>();
  public inputSliderValues: Indexable<boolean> = {
    editor: false,
    admin: false,
    rightAdmin: false,
    reader: false,
    validator: false,
    specifier: false,
    superAdmin: false,
  };
  public searchInput = '';
  public setterFormGroup?: FormGroup<Indexable<FormControl>>;
  public defaultSetterBtnLabel = $localize`Appliquer`;

  generalActions = input<GeneralActions<T>>();

  columns = input<Column<T, V>[]>([]);
  data = input<T[]>([]);
  rowControls = model<FormArray<FormGroup<Indexable<FormControl>>>>();
  formCreation = input<(row?: T) => FormGroup<Indexable<FormControl>>>();
  identifier = input<KeysOfType<T, string>>();
  sortDisabled = input(false);
  paginatorDisabled = input(false);

  currentUser = input<User>();
  pagingInfo = input<PagingInfo>(new PagingInfo('list'));

  showUserFilter = input(false);

  columnFilters = input<ColumnFilter<T>[]>([]);

  selectedRows = output<T[]>();
  refresh = output<PagingInfo>();
  valueChange = output<T>();
  add = output();
  modify = output<N>();
  defaultAction = output<DefaultAction>();
  delete = output<SelectionAction<N>>();
  rightAction = output();
  actionSelected = output<SelectionAction<N>>();

  rowDetailTemplate = computed(() => {
    return this.columns().find((col) => col.type === CellType.RowDetail)?.rowDetailTemplate;
  });
  expandedRow?: T;

  private isSetterInit = false;

  ngAfterViewInit(): void {
    const defaultSort = this.generalActions()?.defaultSort;
    if (defaultSort) {
      this.sortData(defaultSort);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.columnsDef = this.columns();
    this.displayedColumns = this.columns()
      .filter((column) => !column.hide)
      .map((c) => c.field);
    if (!this.generalActions()?.checkboxDisabled) {
      this.displayedColumns.unshift('select');
    }

    if (this.data()) {
      this.resetSelection();
      const identifier = this.identifier();
      if (!this.generalActions()?.checkboxDisabled && identifier) {
        for (const element of this.data()) {
          // Handle element preselection
          const isSelected = 'selected' in element ? !!element['selected'] : false;
          this.task.subtasks.push({
            id: element.id,
            identifier: element[identifier] as string,
            selected: isSelected,
            element: element,
          });
        }
        // Do not trigger the selectAll event if it's false as it will override
        // a preselection
        if (this.generalActions()?.checkboxAllSelected) {
          this.selectAll(this.generalActions()?.checkboxAllSelected ?? true);
        }
      }
      const formFunction = this.formCreation();
      if (formFunction) {
        this.rowControls.set(new FormArray(this.data().map((datum) => formFunction(datum))));
        this.getControl.cache.clear?.();

        if (!this.isSetterInit) {
          this.setterFormGroup = formFunction();
          this.getSetterControl.cache.clear?.();
          this.isSetterInit = true;
        }
      }
      this.dataSource = new MatTableDataSource(this.data());
    } else {
      this.dataSource = new MatTableDataSource(<T[]>[]);
      this.rowControls.set(undefined);
      this.getControl.cache.clear?.();
    }
  }

  expandRow(row: T, event: Event): void {
    event.stopPropagation();
    this.expandedRow = this.expandedRow === row ? undefined : row;
  }

  onRefresh() {
    this.refresh.emit(this.pagingInfo());
  }

  getControl = memoize(
    (index, field) => this.rowControls()?.controls?.at(index)?.controls?.[field],
    (index, field) => `${index}-${field}`
  );

  getSetterControl = memoize((field) => this.setterFormGroup?.controls?.[field]);

  search(filter: string | null) {
    this.searchInput = filter ?? '';
    this.setPagingInfoAndRefresh();
  }

  onColumnFilterChange(filter: ColumnFilter<T>, selected: string) {
    filter.selectedOption = selected;
    this.setPagingInfoAndRefresh();
  }

  setPagingInfoAndRefresh() {
    const filter = this.searchInput;
    const sliders = this.showUserFilter() ? this.inputSliderValues : [];
    this.pagingInfo().filters = [];
    this.columnFilters().forEach((columnFilter) => {
      const selected = columnFilter.selectedOption;
      if (selected) {
        this.pagingInfo().filters.push({ name: columnFilter.filterColumn as string, value: selected });
      }
    });
    if (filter) {
      this.pagingInfo().filters.push({ name: 'filter', value: filter });
    }
    if (sliders) {
      for (const [key, value] of Object.entries(sliders)) {
        this.pagingInfo().filters.push({ name: key, value: value });
      }
    }
    this.pagingInfo().currentPage = 0;
    this.onRefresh();
  }

  sortData(sort: Sort) {
    const index = this.pagingInfo().sort.findIndex((actualSort) => (actualSort.column = sort.active));
    if (index >= 0) {
      this.pagingInfo().sort.splice(index);
    }

    this.pagingInfo().sort.push({ column: sort.active, order: sort.direction });
    this.onRefresh();
  }

  resetSelection() {
    this.allSelected = false;
    this.task.selected = false;
    this.task.subtasks = [];
  }

  selectAll(selected: boolean) {
    this.allSelected = selected;
    this.task.subtasks?.forEach((t) => {
      if (!this.generalActions()?.hideCheckboxCondition?.(t.element)) {
        t.selected = selected;
      }
    });
    this.checkSelectionUpdate();
  }

  updateAllSelected() {
    this.allSelected = this.task.subtasks.every((t) => {
      if (!this.generalActions()?.hideCheckboxCondition?.(t.element)) {
        return t.selected;
      }
      return true;
    });
    this.checkSelectionUpdate();
  }

  someSelected(): boolean {
    return !isNil(this.task.subtasks.find((t) => t.selected)) && !this.allSelected;
  }

  onChange(row: T) {
    this.valueChange.emit(row);
  }

  onAdd() {
    this.add.emit();
  }

  onModify(id: N) {
    this.modify.emit(id);
  }

  onDefaultAction(identifier: string, key: string) {
    const data: DefaultAction = {
      identifier,
      key,
    };
    this.defaultAction.emit(data);
  }

  askToDelete() {
    const identifiers = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.identifier);
    const ids = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.id);
    this.onDelete(identifiers, ids);
  }

  onDelete(identifiers: string[], ids: N[]) {
    const data: SelectionAction<N> = {
      identifiers: identifiers,
      ids: ids,
    };
    this.delete.emit(data);
  }

  openRightAction() {
    this.rightAction.emit();
  }

  onActionSelected() {
    const identifiers = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.identifier);
    const ids = this.task.subtasks.filter((subtask) => subtask.selected).map((subtask) => subtask.id);
    const data: SelectionAction<N> = {
      identifiers: identifiers,
      ids: ids,
    };
    this.actionSelected.emit(data);
  }

  checkIconVisibility(column: Column<T, V>, row: T) {
    if (isNil(column.iconVisibility)) {
      return false;
    }
    if (typeof column.iconVisibility === 'boolean') {
      return column.iconVisibility;
    }
    return column.iconVisibility(row);
  }

  getIcon(column: Column<T, V>, row: T) {
    if (isNil(column.icon)) {
      return false;
    }
    if (typeof column.icon === 'string') {
      return column.icon;
    }
    return column.icon(row);
  }

  getAttribute<S>(row: T, paths: string | string[]): S {
    return get(row, paths);
  }

  private checkSelectionUpdate() {
    const selectedIds = this.task.subtasks
      .filter((subtask) => {
        if (!this.generalActions()?.hideCheckboxCondition?.(subtask.element)) {
          return subtask.selected;
        }
        return false;
      })
      .map((subtask) => subtask.id);

    const selectedData = this.data().filter((datum) => selectedIds.includes(datum.id));
    this.selectedRows.emit(selectedData);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  setSelectionProperties() {
    const selectedItems: number[] = [];
    this.task.subtasks.forEach((row, index) => {
      if (row.selected) {
        selectedItems.push(index);
      }
    });
    const rows = this.rowControls();
    const toApply = omitBy(this.setterFormGroup?.getRawValue() ?? {}, isNil);
    if (!isEmpty(toApply) && rows) {
      selectedItems.forEach((index) => rows.at(index).patchValue(structuredClone(toApply)));
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ApplicationDefinition } from '@assets/applications/applications';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { Customer } from '@core/model/customer.model';
import { CustomerService } from '@core/services/customer.service';
import { NotificationService } from '@core/services/notification.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'smv-customer-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
  ],
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss'],
})
export class CustomerInformationComponent {
  public customer: FormControl<Customer | null> = new FormControl<Customer | null>(null);

  constructor(
    private readonly customerService: CustomerService,
    private readonly notificationService: NotificationService
  ) {}

  isDisabled() {
    return this.customer.invalid || typeof this.customer.value == 'string';
  }

  customerFilter = (search: string, filtered: ReplaySubject<Customer[] | DropdownOption<Customer>[]>): void => {
    const filterValue = search.toLowerCase();

    this.customerService.searchCustomersOfApp(ApplicationDefinition.appEudemis, filterValue).subscribe({
      next: (response: Customer[]) => {
        filtered.next(
          response.map((customer) => {
            return {
              label: customer.name,
              value: customer,
            };
          })
        );
      },
      error: () => {
        this.notificationService.error(
          $localize`:Icare Administration:Une erreur est survenue lors de la récupération des clients, veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
        );
      },
    });
  };
}

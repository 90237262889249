@if (control) {
  @if (hideField()) {
    <button mat-icon-button (click)="picker.click()" type="button">
      <mat-icon [style]="{ color: control.value ?? 'black' }">square</mat-icon>
    </button>
  } @else {
    <smv-form-field-wrapper [formControl]="control" [errorMessages]="errorMessages" hasAction>
      <span mat-label>
        <ng-content />
      </span>
      <button mat-icon-button (click)="picker.click()" type="button">
        <mat-icon [style]="{ color: control.value ?? 'black' }">square</mat-icon>
      </button>
    </smv-form-field-wrapper>
  }

  <!-- Put the trigger outside of the wrapper so that it is not contained within mat-form-field -->
  <div #picker [colorPicker]="control.value" (colorPickerChange)="updateColor($event)"></div>
}

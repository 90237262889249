import { Injectable } from '@angular/core';
import { ContextItem } from '@core/model/context-item.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { Indexable } from '@core/utils/general.utils';
import { isEmpty } from 'lodash-es';
import { Timeline } from './timeline/timeline.model';
import { TimelineService } from './timeline/timeline.service';

export type GeoserverRequestVariables = Indexable<string>;

enum TimelineVariables {
  TIMELINE_START_DATE = 'TIMELINE_START_DATE',
  TIMELINE_END_DATE = 'TIMELINE_END_DATE',
  TIMELINE_VALUE = 'TIMELINE_VALUE',
}

export enum EditionVariable {
  EDITION_VALIDATION = 'EDITION_VALIDATION',
}

interface EditionValidationVariables {
  validation: boolean;
  editionDuplicates: string;
  editionWarnings: string;
  editionType: string;
  editors: string[];
  dateRange: string[];
}

interface OptiSemisVariables {
  crop: string;
  recommendation: string;
  constraint: string;
}

interface RevoltVariables {
  recommendation: string;
  constraint: string;
  soilType: string;
}

interface InterraScanVariables {
  technician: string;
  operator: string;
  scanned: string;
}

@Injectable({ providedIn: 'root' })
export class GeoserverVariablesService {
  private static instance?: GeoserverVariablesService;
  private readonly requestVariables: GeoserverRequestVariables = {
    TIMELINE_START_DATE: '',
    TIMELINE_END_DATE: '',
    TIMELINE_VALUE: '',
    USER_EMAIL: '',
    ICARE_GENERATION: '',
    OPTISEMIS_FILTER: '',
    REVOLT_FILTER: '',
    EDITION_VALIDATION: '',
    INTERRASCAN_FILTER: '',
    SPECIFIER: '',
  };

  public geoserverVariablesState = new ContextItem<GeoserverRequestVariables>(this.requestVariables);

  public readonly customerFilterState = new ContextItem<string>('');
  public readonly icareGenerationState = new ContextItem<string>('true');
  public readonly optiSemisFilterState = new ContextItem<OptiSemisVariables>({
    crop: '',
    recommendation: '',
    constraint: '',
  });
  public readonly interrascanFilterState = new ContextItem<InterraScanVariables>({
    operator: '',
    scanned: '',
    technician: '',
  });
  public readonly revoltFilterState = new ContextItem<RevoltVariables>({
    recommendation: '',
    constraint: '',
    soilType: '',
  });
  public readonly editionValidationState = new ContextItem<EditionValidationVariables>({
    validation: false,
    editionDuplicates: '',
    editionWarnings: '',
    editionType: 'true = false',
    editors: [],
    dateRange: [],
  });
  public readonly specifierFilterState = new ContextItem<string>('true = true');

  constructor(
    private readonly timelineService: TimelineService,
    private readonly authService: AuthService,
    private readonly applicationService: ApplicationApiService
  ) {
    GeoserverVariablesService.instance = this;
    this.timelineService.timelineState
      .getStream()
      .subscribe((timelineValue) => this.updateTimelineVariables(timelineValue));

    this.icareGenerationState
      .getStream()
      .subscribe((filterGeneration) => this.updateIcareGenerationVariables(filterGeneration));
    this.optiSemisFilterState
      .getStream()
      .subscribe((filterVariables) => this.updateCropWiseFilterVariables(filterVariables, 'OPTISEMIS_FILTER'));
    this.revoltFilterState
      .getStream()
      .subscribe((filterVariables) => this.updateCropWiseFilterVariables(filterVariables, 'REVOLT_FILTER'));
    this.interrascanFilterState.getStream().subscribe((variables) => this.updateInterrascanVariables(variables));
    this.authService.getUserEmail().subscribe((email) => this.updateUserVariables(email));

    this.editionValidationState
      .getStream()
      .subscribe((editionVariables) =>
        this.updateEditionVariables(
          editionVariables,
          this.applicationService.currentApplication.getValue()?.functionnalityConfig.code
        )
      );
    this.applicationService.currentApplication
      .getStream()
      .subscribe((app) =>
        this.updateEditionVariables(this.editionValidationState.getValue(), app?.functionnalityConfig.code)
      );

    this.specifierFilterState
      .getStream()
      .subscribe((specifierFilter) => this.updateSpecifierVariables(specifierFilter));
  }

  public static getInstance(): GeoserverVariablesService | undefined {
    return GeoserverVariablesService.instance;
  }

  public reset() {
    this.icareGenerationState.reset();
    this.optiSemisFilterState.reset();
    this.editionValidationState.reset();
    this.specifierFilterState.reset();
    this.interrascanFilterState.reset();
  }

  private updateTimelineVariables(timelineValue: Timeline | undefined) {
    this.requestVariables[TimelineVariables.TIMELINE_START_DATE] = timelineValue?.startValue?.split('T')[0] ?? '';
    this.requestVariables[TimelineVariables.TIMELINE_END_DATE] = timelineValue?.endValue?.split('T')[0] ?? '';
    this.requestVariables[TimelineVariables.TIMELINE_VALUE] = timelineValue?.value.split('T')[0] ?? '';

    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateUserVariables(email: string) {
    this.requestVariables['USER_EMAIL'] = email;

    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateIcareGenerationVariables(filterGeneration: string) {
    this.requestVariables['ICARE_GENERATION'] = filterGeneration;

    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateInterrascanVariables(variables: InterraScanVariables) {
    let filter = Object.entries(variables)
      .filter(([_, value]) => Boolean(value))
      .map((field) => field.join('='))
      .join(' AND ');
    if (isEmpty(filter)) {
      filter = 'true = true';
    }
    this.requestVariables['INTERRASCAN_FILTER'] = filter;
    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateCropWiseFilterVariables(filterVariables: OptiSemisVariables | RevoltVariables, filterName: string) {
    let filter = Object.values(filterVariables)
      .filter((variable: string) => !isEmpty(variable))
      .join(' AND ');
    if (isEmpty(filter)) {
      filter = 'true = false';
    }
    this.requestVariables[filterName] = filter;

    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateEditionVariables(editionVariables: EditionValidationVariables, appType?: string) {
    let filter = '';
    if (editionVariables.validation) {
      if (editionVariables.editionType.length) {
        filter += editionVariables.editionType;
      }
      if (editionVariables.editionDuplicates.length) {
        filter += ' AND ' + editionVariables.editionDuplicates;
      }
      if (editionVariables.editionWarnings.length) {
        filter += ' AND ' + editionVariables.editionWarnings;
      }
      filter += " AND user_modif in ('" + editionVariables.editors.join("','").replaceAll('@', '%40') + "')";
      if (editionVariables.dateRange.length === 1) {
        filter += ' AND date_modif = ' + editionVariables.dateRange[0];
      } else if (editionVariables.dateRange.length === 2) {
        filter +=
          ' AND (date_modif BETWEEN ' + editionVariables.dateRange[0] + ' AND ' + editionVariables.dateRange[1] + ')';
      }
      filter += ` AND modified_in = '${appType}'`;
    } else {
      const email = this.authService.getUserEmailInSync();
      filter =
        'is_modified = false' +
        (email ? " OR (user_modif = '" + email.replaceAll('@', '%40') + `' AND modified_in = '${appType}')` : '');
    }

    this.requestVariables[EditionVariable.EDITION_VALIDATION] = filter;

    this.geoserverVariablesState.setValue(this.requestVariables);
  }

  private updateSpecifierVariables(specifierFilter: string) {
    this.requestVariables['SPECIFIER'] = specifierFilter;

    this.geoserverVariablesState.setValue(this.requestVariables);
  }
}

<div [hidden]="panelHide()">
  <mat-card class="side-panel opti-semis-panel">
    <mat-card-title>
      <div>
        <span i18n="OptiSemis|Title widget">OptiSemis</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <smv-opti-semis-filter />
      <smv-customer-filter [appId]="applicationId()" />

      @if (canValid()) {
        <smv-zoom-position [label]="labelZoom" [positionFunction]="getPosition" />
        <button mat-raised-button color="primary" (click)="openTrapStats()" class="opti-semis-stats">
          <span i18n="OptiSemis Administration|OptiSemis Parcel Summary">Récapitulatif des parcelles</span>
        </button>
      }
      @if (canImport()) {
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header i18n>Import de parcelles</mat-expansion-panel-header>
          <smv-upload-file [url]="'/optiSemis/parcel/import/' + applicationId" [label]="importLabel" accept=".csv" />
        </mat-expansion-panel>
      }
    </mat-card-content>
  </mat-card>
</div>

import { Component, input, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { Customer } from '@core/model/customer.model';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/services/auth.service';
import { CustomerService } from '@core/services/customer.service';
import { NotificationService } from '@core/services/notification.service';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'smv-customer-filter',
  standalone: true,
  imports: [MatExpansionModule, MultiSelectComponent],
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.scss'],
})
export class CustomerFilterComponent implements OnInit {
  appId = input.required<number>();

  public label = $localize`:Customer filter|Field Label:Filtre des clients`;
  public hint = $localize`:Customer filter|Hint:Renseigner 2 caractères de l'identifiant ou du code minimum`;
  public placeholder = $localize`:Customer filter|Placeholder:Identifiant ou code`;
  public currentUser?: User;

  private readonly allCustomerFilter: Customer = { id: 0, name: 'Tous', code: '*' };
  public allCustomers: Customer[] = [];
  public selectedCustomers: Customer[] = [];

  private cqlFilter = '';

  constructor(
    private readonly authService: AuthService,
    private readonly customerService: CustomerService,
    private readonly geoserverFilter: GeoserverVariablesService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.authService.whoAmI().subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
        this.customerService.getCustomersWithActiveTraps(this.appId()).subscribe((customers) => {
          if (user.isSuperAdmin() || user.syngenta) {
            this.allCustomers.unshift(this.allCustomerFilter);
            this.selectedCustomers.push(this.allCustomerFilter);
          } else {
            this.selectedCustomers.push(...customers);
          }
          this.allCustomers.push(...customers);
          this.onSelectionChange();
        });
      }
    });
  }

  searchCondition(search: string) {
    return search.length > 1;
  }

  filter = (search: string, filtered: ReplaySubject<Customer[]>) => {
    const filterValue = search.toLowerCase();

    this.customerService.searchCustomer(filterValue).subscribe({
      next: (response: Customer[]) => {
        const selectedCustomerIds = this.selectedCustomers.map((customer) => customer.id);
        if (
          this.currentUser &&
          (this.currentUser.isSuperAdmin() || this.currentUser.syngenta) &&
          !selectedCustomerIds?.includes(0)
        ) {
          filtered.next([
            this.allCustomerFilter,
            ...response.filter((customer) => !selectedCustomerIds?.includes(customer.id)),
          ]);
        } else {
          filtered.next(response.filter((customer) => !selectedCustomerIds?.includes(customer.id)));
        }
      },
      error: () => {
        this.notificationService.error(
          $localize`:Customer filter|Error:Une erreur est survenue lors de la récupération des clients, veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
        );
      },
    });
  };

  displayCustomer(customer: Customer) {
    return customer.name;
  }

  displayCustomerEm(customer: Customer) {
    const code = $localize`:Client code:code`;
    return `(${code} ${customer.code})`;
  }

  onSelectionChange() {
    if (this.selectedCustomers.includes(this.allCustomerFilter)) {
      this.cqlFilter = '*';
    } else if (this.selectedCustomers.length != 0) {
      this.cqlFilter =
        "'" +
        this.selectedCustomers
          .map((x) => {
            return x.code;
          })
          .join("','") +
        "'";
    } else {
      this.cqlFilter = '';
    }
    this.geoserverFilter.customerFilterState.setValue(this.cqlFilter);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CommonService } from './common.service';

export interface Token {
  token: string;
}

@Injectable({ providedIn: 'root' })
export class AdminService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/admin';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  protected pipeExtractToken(obs: Observable<Token>): Observable<string> {
    return obs.pipe(map((result: Token) => result.token));
  }
}

import { Component, OnDestroy, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AttributeType, LayerProperty } from '@core/model/application-api/layer.model';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { SelectionService } from '@core/services/selection.service';
import { Indexable } from '@core/utils/general.utils';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { MapUtils } from '@core/utils/map.utils';
import { FeatureAttributesParser } from '@core/utils/parsers/feature-attributes-parser';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { EditionCommentService } from '../edition-comment.service';
import { EditionAttributes } from '../edition-validation.model';
import { EditionValidationService } from '../edition-validation.service';

import { isNil } from 'lodash-es';
import { Feature } from 'ol';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { debounceTime, Subscription } from 'rxjs';

interface GeneralInfo {
  label: string;
  control: FormControl;
  type: string;
}

@Component({
  selector: 'smv-validation-feature',
  templateUrl: './validation-feature.component.html',
  styleUrls: ['./validation-feature.component.scss'],
})
export class ValidationFeatureComponent implements OnDestroy {
  feature = output<Feature | undefined>();

  public title = $localize`Proposition sélectionnée`;
  public titleAttributes = $localize`Attributs modifiés`;

  public generalInfos: GeneralInfo[] = [];
  public areAttrModif = false;

  public selectedLayer?: DataLayerConfig;
  public selectedFeature?: Feature;
  public originalFeature?: Feature;
  public originalFeatureProperties?: Indexable<string>;
  public onlyModifAttr = new FormControl<boolean>(true);

  public comment = new FormControl<string>('');

  public parser?: FeatureAttributesParser;
  public attributes: LayerProperty[] = [];
  public modifAttributes: LayerProperty[] = [];
  public AttributeType = AttributeType;

  public isSaving = false;

  private readonly submissions = {
    add: $localize`:Edition type:Ajout`,
    modifyGeom: $localize`:Edition type:Modification de géométrie`,
    modifyAttr: $localize`:Edition type:Modification d'attributs`,
    modify: $localize`:Edition type:Modification d'attributs et de géométrie`,
    delete: $localize`:Edition type:Suppression`,
  };

  private readonly submissionType = new FormControl<string>('');
  private readonly editor = new FormControl<string>('');
  private readonly modifDate = new FormControl<Date | null>(null);
  private readonly creatDate = new FormControl<Date | null>(null);

  private readonly defaultGeneralInfosLength: number = 0;
  private parserOk = false;
  private originalOk = false;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly validationService: EditionValidationService,
    private readonly editionCommentService: EditionCommentService,
    private readonly selectionService: SelectionService
  ) {
    this.subscriptions.add(
      this.selectionService.selectedFeatures
        .getStream()
        .pipe(debounceTime(100))
        .subscribe((selections) => {
          const selection = selections?.find((s) => s.features.length);
          this.selectedLayer = selection?.layer;
          if (selection && this.selectedLayer?.isEditing) {
            this.selectedFeature = selection.features[0];
            this.resetVariables(this.selectedFeature.getId());

            this.computeFeatureData();
            this.checkCommentForProposal(this.selectedLayer, this.selectedFeature);
            if (this.areAttrModif) {
              if (this.selectedLayer === selection?.layer && this.parser) {
                this.parser.form.patchValue(this.selectedFeature.getProperties());
                this.handleSpecificDisplay();
              } else {
                this.resetParser();
                this.selectedLayer = selection?.layer;
                this.parser = new FeatureAttributesParser(
                  this.selectedFeature,
                  this.selectedLayer.config.properties,
                  false
                );
                this.attributes = this.parser.attributes.filter(
                  (property) => !EditionAttributes.includes(property.name)
                );
                this.modifAttributes.push(...this.attributes);
              }
              this.parserOk = true;
              if (this.originalOk) {
                this.compareAttributes();
                this.handleSpecificDisplay();
              } else {
                this.getOriginalFeature(this.selectedLayer.config as WfsLayerConfig, this.selectedFeature);
              }
            } else if (!isNil(this.selectedFeature.get('linked_entity'))) {
              this.getOriginalFeature(this.selectedLayer.config as WfsLayerConfig, this.selectedFeature);
            }
          } else {
            this.resetParser();
            this.resetVariables();
            this.selectedFeature = undefined;
          }

          this.feature.emit(this.selectedFeature);
        })
    );

    this.generalInfos.push(
      {
        label: $localize`Type d'édition`,
        control: this.submissionType,
        type: 'text',
      },
      {
        label: $localize`Editeur`,
        control: this.editor,
        type: 'text',
      },
      {
        label: $localize`Date de modification`,
        control: this.modifDate,
        type: 'date',
      }
    );
    this.defaultGeneralInfosLength = this.generalInfos.length;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkCommentForProposal(layer: DataLayerConfig, feature: Feature) {
    if (layer.config.validationConfiguration?.comments) {
      const config: WfsLayerConfig = layer.config as WfsLayerConfig;

      const proposalId = MapUtils.getFeatureId(feature);
      const dataLayerCode = config.geoserverLayerName;

      const email = this.editor.getRawValue();
      if (email) {
        this.editionCommentService.getCommentOfProposal(proposalId, dataLayerCode, email).subscribe({
          next: (comment) => {
            if (comment) {
              this.comment.setValue(comment);
            }
          },
          error: () => {
            this.comment.reset();
          },
        });
      }
    }
  }

  private getOriginalFeature(layer: WfsLayerConfig, feature: Feature) {
    const id = feature.get('linked_entity');
    if (id) {
      const url = GeoserverLayersUtils.wfsGetFeatureUrl(layer, id);
      if (url) {
        this.validationService.getFeaturesFromGeoserver(url).subscribe((originalFeatures) => {
          if (originalFeatures.length) {
            const features = new GeoJSONFormat().readFeatures(originalFeatures[0]);
            this.originalFeature = features.length ? features[0] : undefined;
          }
          this.originalFeatureProperties = this.originalFeature?.getProperties();

          if (this.parserOk) {
            this.compareAttributes();
            this.handleSpecificDisplay();
          }
          this.originalOk = true;
        });
      }
    } else {
      this.handleSpecificDisplay();
    }
  }

  private computeFeatureData() {
    if (this.selectedFeature) {
      this.computeSubmissionType(this.selectedFeature);
      this.editor.setValue(this.selectedFeature.get('user_modif'));
      this.modifDate.setValue(this.selectedFeature.get('date_modif')?.split('Z')[0].split('T')[0]);
    }
  }

  private resetVariables(id?: string | number) {
    this.areAttrModif = false;
    this.parserOk = false;
    this.comment.reset();
    if (this.generalInfos.length > this.defaultGeneralInfosLength) {
      this.generalInfos.splice(this.defaultGeneralInfosLength);
    }
    if (this.originalFeature?.getId() != id) {
      this.originalFeature = undefined;
      this.originalFeatureProperties = undefined;
      this.originalOk = false;
    }
  }

  private resetParser() {
    this.parser = undefined;
    this.attributes = [];
    this.modifAttributes = [];
  }

  private addCreationDate() {
    this.generalInfos.push({
      label: $localize`Date de création`,
      control: this.creatDate,
      type: 'date',
    });
  }

  private computeSubmissionType(feature: Feature) {
    let haveGeomModif = false;
    if (feature.get('to_create')) {
      this.submissionType.setValue(this.submissions.add);
      this.creatDate.setValue(feature.get('date_creat').split('Z')[0].split('T')[0]);
      this.addCreationDate();
      this.areAttrModif = true;
    }
    if (feature.get('to_modify_geometry')) {
      haveGeomModif = true;
      this.submissionType.setValue(this.submissions.modifyGeom);
    }
    if (feature.get('to_modify_attributes')) {
      this.submissionType.setValue(haveGeomModif ? this.submissions.modify : this.submissions.modifyAttr);
      this.areAttrModif = true;
    }
    if (feature.get('to_delete')) {
      this.submissionType.setValue(this.submissions.delete);
    }
  }

  private compareAttributes() {
    if (this.parser && this.originalFeatureProperties) {
      const modifyValues = this.parser.form.value as Indexable<string>;

      const properties = this.attributes.map((attribute) => attribute.name);

      const modifiedAttr: string[] = [];
      for (const property of properties) {
        if (modifyValues[property] != this.originalFeatureProperties[property]) {
          modifiedAttr.push(property);
        }
      }
      this.modifAttributes = this.attributes.filter((property) => modifiedAttr.includes(property.name));
    }
  }

  private handleSpecificDisplay() {
    this.attributes
      .filter(
        (attribute) =>
          !!attribute.configuration?.dataSourceDisplay &&
          [AttributeType.STRING, AttributeType.STRING_ARRAY].includes(attribute.type)
      )
      .forEach((attribute) => {
        let url = attribute.configuration?.dataSourceDisplay;
        if (this.parser?.form.controls[attribute.name].value && url) {
          const value = this.parser.form.controls[attribute.name].value;
          url = url.replace('{}', value).replace('|', '----');
          this.validationService
            .retrieveSpecificDisplay(url)
            .subscribe((displayValue: string) =>
              this.parser?.form.controls[attribute.name].setValue(`${displayValue} (${value})`)
            );
        }
      });
  }

  getInputType(type: AttributeType) {
    return FeatureAttributesParser.getInputType(type);
  }
}

@if (!isNoHeaderFooter()) {
  <smv-header [isLoggedIn]="isLoggedIn" (toggleMenu)="drawer.toggle()" />
}
<mat-drawer-container
  hasBackdrop
  [ngClass]="{ 'smv-large-page-background': hasPageBackground() && !isNoHeaderFooter() }"
>
  <mat-drawer mode="over" #drawer>
    <smv-sidemenu (closeSideNav)="drawer.close()" />
  </mat-drawer>
  <mat-drawer-content>
    <div class="main-content">
      @if (isBackendUp) {
        <router-outlet />
      } @else {
        <h1 i18n="Main Content|Services in maintenance">
          Les services MapView sont actuellement en maintenance, veuillez patienter.
        </h1>
      }
    </div>
    @if (!isLoggedIn && !isNoHeaderFooter()) {
      <smv-anonymous-footer />
    }
  </mat-drawer-content>
</mat-drawer-container>

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  booleanAttribute,
  forwardRef,
  inject,
  input,
} from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FloatLabelType, MatFormFieldModule, SubscriptSizing } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorMessages } from '@components/form-field-errors/form-error-messages';
import { FormFieldErrorsComponent } from '@components/form-field-errors/form-field-errors.component';
import { HelpOverlayComponent } from '@components/help-overlay/help-overlay.component';
import { ControlValueAccessorImpl } from '@core/form/control-value-accessor.impl';
import { GeneralUtils, KeysOfType } from '@core/utils/general.utils';
import { isNil } from 'lodash-es';
import { ReplaySubject } from 'rxjs';

export type ControlType = 'input' | 'select' | 'textarea' | 'autocomplete';
export interface DropdownOption<T> {
  label: string;
  value: T;
}

const modules = [
  CommonModule,
  FormsModule,
  FormFieldErrorsComponent,
  HelpOverlayComponent,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
];

@Component({
  selector: 'smv-form-field-wrapper',
  standalone: true,
  imports: modules,
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldWrapperComponent),
      multi: true,
    },
  ],
})
export class FormFieldWrapperComponent<T> extends ControlValueAccessorImpl<T> implements AfterViewInit, OnChanges {
  type = input('text');
  step = input(1);
  placeholder = input('');
  readonly = input(false, { transform: booleanAttribute });
  required = input(false, { transform: booleanAttribute });
  requireSelection = input(false, { transform: booleanAttribute });
  showEmptyWhenDisabled = input(false, { transform: booleanAttribute });
  hideLabel = input(false, { transform: booleanAttribute });
  hasHint = input(false, { transform: booleanAttribute });
  hasHelp = input(false, { transform: booleanAttribute });
  hasAction = input(false, { transform: booleanAttribute });
  optionsInit = input(false, { transform: booleanAttribute });
  selectLabelIdentifier = input<KeysOfType<T, string>>();
  errorMessages = input<ErrorMessages>({});
  controlType = input<ControlType>('input');
  selectOptions = input<DropdownOption<T>[] | T[]>([]);
  selectIdentifier = input<KeysOfType<T, string | number>>();
  serverFilterCondition = input<(search: string) => boolean>((search) => search.length > 1);
  serverFilterOptions = input<(search: string, filtered: ReplaySubject<DropdownOption<T>[] | T[]>) => void>();
  subscriptSizing = input<SubscriptSizing>('fixed');
  helpCloseAfter = input(3000);
  @Input() override control?: FormControl<T>;

  public formattedSelectOptions: DropdownOption<T>[] = [];
  public formattedSelectOptionsFiltered: DropdownOption<T>[] = [];
  public isEmpty = true;
  // force the label to float on value
  public floatLabel: FloatLabelType = this.control?.value ? 'always' : 'auto';

  private searchValue?: T | string;
  private readonly filteredServerOptions: ReplaySubject<DropdownOption<T>[] | T[]> = new ReplaySubject(1);
  private readonly injector = inject(Injector);
  private initByInjector = false;

  constructor(private readonly changeDetector: ChangeDetectorRef) {
    super();
    this.filteredServerOptions.subscribe((options) => {
      this.formattedSelectOptionsFiltered = this.formatOptions(options);
      if (this.control?.value && typeof this.control?.value !== 'string') {
        this.control.setValue(this.control.value);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((this.controlType() === 'select' || this.controlType() === 'autocomplete') && this.selectOptions()) {
      this.formattedSelectOptions = this.formatOptions(this.selectOptions());
      if (this.controlType() === 'autocomplete' && this.control?.value) {
        this._filter(this.control.value, true);
      } else {
        this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      }
    } else {
      this.formattedSelectOptions = [];
    }
    if (changes['selectOptions'] && !changes['selectOptions'].isFirstChange()) {
      if (this.formattedSelectOptions?.length) {
        if (this.optionsInit() && (this.controlType() === 'autocomplete' || this.controlType() == 'select')) {
          this.control?.setValue(this.control.value ?? this.formattedSelectOptions[0].value);
        } else {
          const value = this.formattedSelectOptions.find((v) => this.checkIsValue(v, this.control?.value))?.value;
          if (this.required()) {
            this.control?.setValue(value ?? this.formattedSelectOptions[0].value);
          } else {
            this.control?.setValue(value ?? <T>null);
          }
        }
      } else if (this.formattedSelectOptions.length == 0) {
        this.control?.setValue(<T>null);
      }
      this.floatLabel = this.control?.value ? 'always' : 'auto';
    }
    if (changes['required']) {
      this.changeDetector.detectChanges();
    }
    if (changes['control'] && (this.controlType() === 'autocomplete' || this.controlType() === 'select')) {
      this.control?.valueChanges.subscribe((value) => {
        this._filter(value);
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.registerFormControl(this.injector.get(NgControl));
      this.initByInjector = true;
    }
    this.isEmpty = isNil(this.control?.value);
    if (this.controlType() === 'autocomplete' || this.controlType() === 'select') {
      this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      if (this.initByInjector) {
        this.control?.valueChanges.subscribe((value) => {
          this._filter(value);
        });
      }
    }
    this.changeDetector.detectChanges();
  }

  autocompleteFilter(value: string) {
    if (this.requireSelection()) {
      this._filter(value);
    }
  }

  _filter(value: T | string, force = false) {
    if (!value) {
      this.formattedSelectOptionsFiltered = this.formattedSelectOptions;
      return;
    }
    if (
      !(
        value === this.searchValue ||
        (typeof value !== 'string' && typeof this.searchValue === 'string' && this.searchValue === this.getLabel(value))
      ) ||
      force
    ) {
      this.searchValue = value;
      if (typeof value === 'string') {
        if (!this.serverFilterOptions()) {
          const loweredValue = value.toString().toLowerCase();
          this.formattedSelectOptionsFiltered = this.formattedSelectOptions.filter(
            (filter) =>
              filter.label.toLowerCase().includes(loweredValue) ||
              (typeof filter.value === 'string' && filter.value.toLowerCase().includes(loweredValue))
          );
        } else if (this.serverFilterCondition()(value)) {
          this.serverFilterOptions()?.(value, this.filteredServerOptions);
        }
      } else {
        this._filter(this.getLabel(value));
      }
    }
  }

  displayFn(newVal: T) {
    return this.formattedSelectOptionsFiltered.find((elem) => this.checkIsValue(elem, newVal))?.label ?? '';
  }

  compareByIdentifier = (option: T, value: T): boolean => {
    const identifier = this.selectIdentifier();
    if (!isNil(identifier) && !isNil(value) && !isNil(option)) {
      return option[identifier] == value[identifier];
    }
    return option == value;
  };

  private getLabel(newVal: T): string {
    return newVal && typeof newVal === 'object' && 'label' in newVal
      ? (newVal['label'] as string)
      : (this.formattedSelectOptions.find((elem) => this.checkIsValue(elem, newVal))?.label ?? '');
  }

  private checkIsValue(option: DropdownOption<T>, value?: T) {
    const identifier = this.selectIdentifier();
    if (!isNil(identifier) && !isNil(value)) {
      return option.value[identifier] === value[identifier];
    }
    return option.value === value;
  }

  override writeValue(value: T): void {
    super.writeValue(value);
    this.isEmpty = isNil(value);
  }

  private formatOptions(options: DropdownOption<T>[] | T[]): DropdownOption<T>[] {
    if (!options.length) {
      return [];
    }
    const identifier = this.selectLabelIdentifier();
    const option = options[0] as object;
    if (GeneralUtils.isArrayOfPrimitive<T>(options)) {
      return options.map((value) => ({
        label: String(value),
        value: value,
      }));
    } else {
      return ('label' in option && 'value' in option) || identifier === undefined
        ? options
        : (options as T[]).map((value) => {
            return {
              label: value[identifier] as string,
              value: value,
            };
          });
    }
  }
}

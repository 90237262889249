import { Injectable } from '@angular/core';
import { GisService, OlGmapComponent } from '@syngenta/ol-gmap';
import { Map, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom.js';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom.js';
import PinchZoom from 'ol/interaction/PinchZoom.js';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { transform } from 'ol/proj';
import WMSTileSource from 'ol/source/TileWMS';

import { LayerSubtype, LayerType } from '@core/model/application-api/layer.model';
import { WidgetType } from '@core/model/application-api/widget.model';
import { ApplicationConfig } from '@core/model/application.model';
import { ContextItem } from '@core/model/context-item.model';
import { LayerConfig, WfsLayerConfig, WmsLayerConfig, WmtsLayerConfig } from '@core/model/layer-config.model';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { MapUtils } from '@core/utils/map.utils';
import { DataLayerConfig, DataLayerGroupConfig } from '@feature/client-carto-app/data-layer-config.model';
import { WidgetService } from '@feature/client-carto-app/widgets/widgets.service';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { Extent } from 'ol/extent';
import { Geometry } from 'ol/geom';
import { forkJoin, map, Observable, of } from 'rxjs';
import { ApplicationApiService } from './api/application-api.service';
import { MultipleSelectionService } from './champ-propre/multiple-selection.service';
import { EditionService } from './edition.service';
import { LayerService } from './layer.service';
import { SelectionService } from './selection.service';

@Injectable({ providedIn: 'root' })
export class MapService {
  public readonly layers$ = new ContextItem<DataLayerGroupConfig[]>([]);
  public readonly zoomActive = new ContextItem<boolean>(true);
  public olGmapInstance?: OlGmapComponent;

  public get mapInstance(): Map {
    if (!this.olGmapInstance?.instance) {
      throw new Error('Map does not exist');
    }
    return this.olGmapInstance.instance;
  }

  private geoserverBaseUrl = '';
  private projection = '';
  private layerCodeToSpecify?: string;

  /**
   * Utilisé seulement avec le plugin vigievirose
   */
  private vigieViroseEnable = false;

  /**
   * Utilisé seulement avec le plugin Icare
   */
  private icareEnable = false;

  constructor(
    private readonly gisService: GisService,
    private readonly layerService: LayerService,
    private readonly selectionService: SelectionService,
    private readonly multipleSelectionService: MultipleSelectionService,
    private readonly editionService: EditionService,
    private readonly applicationService: ApplicationApiService,
    private readonly geoserverVariables: GeoserverVariablesService,
    private readonly statisticService: StatisticService,
    private readonly widgetService: WidgetService
  ) {
    this.gisService.registerProjection('2154').subscribe();
    this.configureApplicationWidgets();
    this.geoserverVariables.customerFilterState
      .getStream()
      .subscribe((customers) => this.setCustomerCqlFilter(customers));
    this.geoserverVariables.geoserverVariablesState.getStream().subscribe(() => {
      if (this.applicationService.currentApplication.getValue() != undefined) {
        this.updateCqlFilter();
        this.updateSldBody();
      }
    });
  }

  private configureApplicationWidgets() {
    this.widgetService
      .getWidgetContextItem(WidgetType.VIGIEVIROSE)
      .getStream()
      .subscribe((widget) => {
        const visible = widget?.visible ?? false;
        this.vigieViroseEnable = visible;
        this.selectionService.activeWidgetStatisticSelection(this.icareEnable || visible);
      });
    this.widgetService
      .getWidgetContextItem(WidgetType.ICARE)
      .getStream()
      .subscribe((widget) => {
        const visible = widget?.visible ?? false;
        this.icareEnable = visible;
        this.selectionService.activeWidgetStatisticSelection(this.vigieViroseEnable || visible);
      });
  }

  configure(olGmap: OlGmapComponent, appConfig: ApplicationConfig, isSpecification?: boolean, isStandardApp?: boolean) {
    this.olGmapInstance = olGmap;
    this.statisticService.mapInstance = this.mapInstance;
    this.geoserverBaseUrl = appConfig.geoserverBaseUrl ?? '';
    this.projection = appConfig.projection;
    MapUtils.saveDefaultMapProjection(this.mapInstance);
    if (isStandardApp) {
      this.multipleSelectionService.initSelectionService(this.mapInstance);
    } else {
      this.selectionService.initSelectionService(this.mapInstance);
    }
    this.editionService.setupEdition(this.mapInstance);

    if (isSpecification) {
      this.layerCodeToSpecify = appConfig.specification?.layerCode;
    }

    /* 
      Permet de recharger les données.
      Quand on zoom dans une emprise déjà requêtée, aucune requête n'est relancée.
      De ce fait, quand le nombre de features est plus grand que le nombre maximal
      de features requêtables par requête, certaines features n'apparaissent jamais.
     */
    this.mapInstance.getView().on('change:resolution', () => {
      this.getAllSimplesLayers()
        .filter((layer) => layer.config.type === LayerType.WFS)
        .filter((layer) => layer.displayParameters.isVisible)
        .forEach((layer) => layer.olLayer?.getSource()?.refresh());
    });
  }

  createLayers(layerGroups: DataLayerGroupConfig[], satelliteView = false): void {
    this.olGmapInstance?.toggleSatelliteView(satelliteView);
    const olLayerGroups$: Observable<Layer | LayerGroup>[] = [];
    layerGroups.forEach((group) => olLayerGroups$.push(this.createOlLayerGroup(group)));
    forkJoin(olLayerGroups$).subscribe((groups) => {
      this.mapInstance.setLayerGroup(new LayerGroup({ layers: groups }));
      this.olGmapInstance?.resize();
      this.layers$.setValue(layerGroups);
    });
  }

  fitToLayer(layer: LayerConfig, extent?: Extent, projection?: string): void {
    if (layer.bboxEpsg4326 && this.olGmapInstance) {
      const [minX, minY, maxX, maxY] = extent ?? layer.bboxEpsg4326;
      const [mapMinX, mapMinY] = this.olGmapInstance.toMapCoordinates([minX, minY], projection ?? 'EPSG:4326');
      const [mapMaxX, mapMaxY] = this.olGmapInstance.toMapCoordinates([maxX, maxY], projection ?? 'EPSG:4326');
      this.olGmapInstance.fitToExtent([mapMinX, mapMinY, mapMaxX, mapMaxY]);
    }
  }

  fitToGeom(geom: Geometry): void {
    if (this.olGmapInstance) {
      this.olGmapInstance.fitToExtent(geom.getExtent());
    }
  }

  hideAllLayers(exceptions: string[]) {
    const layers = this.getAllVisibleLayers();
    for (const layer of layers) {
      if (!exceptions.includes(layer.config.code ?? '')) {
        layer.setLayerVisibility(false);
      }
    }
  }

  getAllVisibleLayers(): DataLayerConfig[] {
    return this.applicationService.applicationLayers.filter((layer) => {
      return layer.displayParameters.isVisible && !layer.config.baseLayer;
    });
  }

  getAllSimplesLayers(): DataLayerConfig[] {
    return this.applicationService.applicationLayers.filter((layer) => {
      return !layer.config.baseLayer && (layer.config.type == LayerType.WMS || layer.config.type == LayerType.WFS);
    });
  }

  getLayerByCode(layerCode: string) {
    const layers = this.applicationService.applicationLayers.filter((layer) => {
      return layer.config.code === layerCode;
    });
    return layers[0];
  }

  getMapExtentCoordinatesWkt() {
    return MapUtils.getExtentCoordinatesWkt(this.mapInstance.getView().calculateExtent());
  }

  getMapExtentCoordinates() {
    return this.mapInstance.getView().calculateExtent();
  }

  setCustomerCqlFilter(customerCqlFilter: string) {
    const layers = this.getAllSimplesLayers().filter(
      (layer) => 'cqlCustomerAttribute' in layer.config && layer.config['cqlCustomerAttribute']
    );

    if (customerCqlFilter === '*') {
      layers.forEach((layer) => {
        const newFilter = (layer.config as WmsLayerConfig | WfsLayerConfig).cqlFilter;
        this.layerService.updateCqlFilter(layer, newFilter ?? '', this.geoserverBaseUrl);
      });
    } else {
      layers.forEach((layer) => {
        let newFilter = (layer.config as WmsLayerConfig | WfsLayerConfig).cqlFilter;
        const customerAttribute = (layer.config as WmsLayerConfig | WfsLayerConfig).cqlCustomerAttribute;
        const partFilter = `${customerAttribute} in (${customerCqlFilter || "''"})`;
        newFilter = newFilter ? `${partFilter} AND (${newFilter})` : partFilter;
        this.layerService.updateCqlFilter(layer, newFilter ?? '', this.geoserverBaseUrl);
      });
    }
  }

  updateStyle(layer: DataLayerConfig) {
    //si WMS on transforme le style en SLD et on passe en arguments des requetes WMS
    if (layer.config.type == LayerType.WMS) {
      const source = layer.olLayer?.getSource() as WMSTileSource;
      const config = layer.config as WmsLayerConfig;
      if (config.useCustomSld) {
        const sld = config.styleToSld();
        const computedSld = GeoserverLayersUtils.computeCqlFilterValue(
          sld,
          this.geoserverVariables.geoserverVariablesState.getValue()
        );
        source.updateParams({ SLD_BODY: computedSld });
      } else {
        if (layer.olLayer) {
          source.updateParams({ SLD_BODY: undefined });
        }
      }
    } else if (layer.olLayer instanceof VectorLayer || layer.olLayer instanceof VectorTileLayer) {
      if (layer.olLayer?.setStyle) {
        layer.olLayer.setStyle(layer.config.getStyleFunction());
      }
    }
  }

  updateSldBody() {
    const layers = this.getAllSimplesLayers().filter((layer) => layer.config.type == LayerType.WMS);

    layers.forEach((layer) => {
      const source = layer.olLayer?.getSource() as WMSTileSource;
      const config = layer.config as WmsLayerConfig;

      if (config.useCustomSld) {
        const sld = config.styleToSld();
        const computedSld = GeoserverLayersUtils.computeCqlFilterValue(
          sld,
          this.geoserverVariables.geoserverVariablesState.getValue()
        );
        source.updateParams({ SLD_BODY: computedSld });
      }
    });
  }

  updateCqlFilter() {
    if (this.vigieViroseEnable || this.icareEnable) {
      const customers = this.geoserverVariables.customerFilterState.getValue();
      this.setCustomerCqlFilter(customers);
    } else {
      const layers = this.getAllSimplesLayers();

      layers.forEach((layer) => {
        const newFilter = (layer.config as WmsLayerConfig | WfsLayerConfig).cqlFilter;
        this.layerService.updateCqlFilter(layer, newFilter ?? '', this.geoserverBaseUrl);
      });
    }
  }

  updateTimeParam(layers: DataLayerConfig[], date?: string) {
    layers.forEach((layer) => {
      if (layer.config.type == LayerType.WMS) {
        (layer.olLayer?.getSource() as WMSTileSource).updateParams({ TIME: date });
      }
    });
  }

  getScale(rounded?: boolean): number {
    return MapUtils.getMapScale(this.mapInstance, rounded);
  }

  zoomTo(location: Coordinate | number[], zoomlevel: number, lon?: number, lat?: number) {
    if (lon && lat) {
      location = [+lon, +lat];
    }
    this.mapInstance.getView().setCenter(location);
    this.mapInstance.getView().setZoom(zoomlevel);
  }

  setZoomActivated(activated: boolean) {
    for (const interaction of this.mapInstance.getInteractions().getArray()) {
      if (
        interaction instanceof MouseWheelZoom ||
        interaction instanceof DoubleClickZoom ||
        interaction instanceof PinchZoom
      ) {
        interaction.setActive(activated);
      }
    }
  }

  handleOverlay(overlay: Overlay, toReplace = false) {
    this.mapInstance.removeOverlay(overlay);
    if (toReplace) {
      this.mapInstance.addOverlay(overlay);
    }
  }

  private createOlLayerGroup(group: DataLayerGroupConfig): Observable<LayerGroup> {
    const olLayers$: Observable<Layer | LayerGroup>[] = [];
    group.layers.forEach((layer) => {
      const olLayer$ = this.createOlLayer(layer);
      if (olLayer$) {
        olLayers$.push(olLayer$);
      }
    });
    group.subgroups.forEach((subgroup) => olLayers$.push(this.createOlLayerGroup(subgroup)));
    const request$ = olLayers$.length ? forkJoin(olLayers$) : of([]);
    return request$.pipe(
      map((layers: (Layer | LayerGroup)[]) => {
        layers.forEach((layer) => {
          const index = layer.get('generatedId');
          group.visibilityChildState[index] = layer.getVisible();
          if (layer instanceof Layer) {
            layer.on('change:visible', group.computeVisibility.bind(group));
          } else if (layer instanceof LayerGroup) {
            layer.on('change:visible', group.computeVisibility.bind(group));
          }
        });
        const layerGroup = new LayerGroup({ visible: group.config.visible, zIndex: group.getZIndex(), layers });
        layerGroup.set('generatedId', group.generatedId);
        group.olLayerGroup = layerGroup;
        return layerGroup;
      })
    );
  }

  private createOlLayer(layer: DataLayerConfig): Observable<Layer> | undefined {
    let olLayer$: Observable<Layer> | undefined;

    const existingLayer = this.mapInstance
      .getAllLayers()
      .find((olLayer) => olLayer.get('generatedId') === layer.generatedId);

    if (layer.config.type === LayerType.GOOGLE_MAP) {
      this.olGmapInstance?.toggleSatelliteView(layer.displayParameters.isVisible);
    }

    if (existingLayer) {
      this.updateCqlFilter();
      const layerIndex = this.applicationService.applicationLayers.findIndex(
        (olLayer) => olLayer.generatedId === layer.generatedId
      );
      this.applicationService.applicationLayers.splice(layerIndex, 1);
      if (layer.config.subtype === LayerSubtype.HEATMAP) {
        olLayer$ = of(this.layerService.createWfsLayer(layer.config as WfsLayerConfig, this.geoserverBaseUrl, false));
      } else {
        olLayer$ = of(existingLayer);
      }
    } else {
      switch (layer.config.type) {
        case LayerType.OSM:
          if (this.olGmapInstance) {
            olLayer$ = of(this.olGmapInstance.getOSMLayer());
          }
          break;
        case LayerType.IGN:
          olLayer$ = of(this.layerService.createIgnLayer(layer.config as WmtsLayerConfig));
          break;
        case LayerType.WMTS:
          olLayer$ = this.layerService.createWmtsLayer(layer.config as WmtsLayerConfig, this.geoserverBaseUrl);
          break;
        case LayerType.WFS:
          olLayer$ = of(
            this.layerService.createWfsLayer(
              layer.config as WfsLayerConfig,
              this.geoserverBaseUrl,
              !!this.layerCodeToSpecify && layer.config.code === this.layerCodeToSpecify
            )
          );
          break;
        case LayerType.WMS:
          olLayer$ = of(
            this.layerService.createWmsLayer(layer.config as WmsLayerConfig, this.geoserverBaseUrl, this.projection)
          );
          break;
        case LayerType.VECTOR:
          olLayer$ = of(this.layerService.createVectorLayer(layer.config));
          break;
        case LayerType.VECTOR_TILE:
          olLayer$ = this.layerService.createVectorTileLayer(layer.config as WmtsLayerConfig, this.geoserverBaseUrl);
          break;
        default:
          break;
      }
    }

    if (olLayer$) {
      olLayer$ = olLayer$.pipe(
        map((olLayer) => {
          olLayer.setZIndex(layer.config.zIndex ?? 0);
          olLayer.setVisible(layer.displayParameters.isVisible);
          olLayer.setOpacity(layer.displayParameters.opacityValue);
          this.layerService.setLayerResolutions(olLayer, layer.config, MapUtils.getMapProjection(this.mapInstance));
          layer.olLayer = olLayer;
          this.applicationService.applicationLayers.push(layer);
          olLayer.set('title', layer.config.shortName);
          olLayer.set('generatedId', layer.generatedId);
          olLayer.set('widget', layer.config.fromWidget);
          return olLayer;
        })
      );
    }
    return olLayer$;
  }

  getCurrentCoordinates() {
    const coordinates = this.mapInstance.getView().getCenter();
    if (!coordinates) {
      return coordinates;
    }
    return transform(coordinates, MapUtils.getMapProjection(this.mapInstance), MapUtils.PROJECTION_CODE_OPENLAYERS);
  }

  getCurrentZoom() {
    return this.mapInstance.getView().getZoom();
  }

  setZoom(zoomLevel: number) {
    this.mapInstance.getView().setZoom(zoomLevel);
  }
}

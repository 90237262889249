import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TableComponent } from '@components/table/table.component';
import { CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { NotificationService } from '@core/services/notification.service';
import { exportToXlsx, XlsxSheet } from '@core/utils/xlsx.utils';
import { Parcel } from '../model/parcel.model';
import { OptiSemisService } from '../opti-semis.service';

@Component({
  standalone: true,
  selector: 'smv-opti-semis-user-parcel',
  templateUrl: './user-parcel.component.html',
  styleUrls: ['./user-parcel.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class UserParcelStatsComponent implements OnInit {
  title = input<string>($localize`Récapitulatif des parcelles`);

  public parcelsList: Parcel[] = [];
  public selection: Parcel[] = [];
  public actionsConfig: GeneralActions<Parcel> = {};
  public columns: Column<Parcel, DefaultType<number>>[] = [];
  public paging: PagingInfo = new PagingInfo('os-parcel-list');

  constructor(
    private readonly notificationService: NotificationService,
    private readonly optiSemisService: OptiSemisService
  ) {}

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      searchDisabled: false,
      searchPlaceholder: $localize`Rechercher une parcelle`,
      refreshDisabled: true,
    };
    this.columns = [
      {
        field: 'id',
        type: CellType.String,
        label: 'id',
        cellData: 'id',
        sort: true,
        hide: true,
      },
      {
        field: 'code',
        label: $localize`Code`,
        type: CellType.String,
        cellData: 'code',
      },
      {
        field: 'name',
        label: $localize`Nom`,
        type: CellType.String,
        cellData: 'name',
      },
      {
        field: 'longitude',
        label: 'X',
        type: CellType.String,
        cellData: 'longitude',
      },
      {
        field: 'latitude',
        label: 'Y',
        type: CellType.String,
        cellData: 'latitude',
      },
      {
        field: 'user',
        label: $localize`Propriétaire`,
        type: CellType.String,
        cellData: ['user', 'email'],
      },
      {
        field: 'customer',
        label: $localize`Client`,
        type: CellType.String,
        cellData: ['customer', 'name'],
        allowCellEmpty: true,
      },
      {
        field: 'crop',
        label: $localize`Culture`,
        type: CellType.String,
        cellData: ['crop', 'label'],
      },
      {
        field: 'variety',
        label: $localize`Variété`,
        type: CellType.String,
        cellData: 'variety',
      },
      {
        field: 'soil',
        label: $localize`Type de sol`,
        type: CellType.String,
        cellData: ['soil', 'label'],
      },
      {
        field: 'sowingDate',
        label: $localize`Date semis`,
        type: CellType.Date,
        cellData: 'sowingDate',
      },
      {
        field: 'sowingDensity',
        label: $localize`Densité semis`,
        type: CellType.String,
        cellData: 'sowingDensity',
      },
    ];

    this.findParcels();
  }

  findParcels() {
    this.optiSemisService.retrieveCreatedParcels(this.paging).subscribe({
      next: (result) => {
        if (result.page.totalElements > 0) {
          this.parcelsList = result.content;
          for (const parcel of result.content) {
            if (parcel.longitude != undefined) {
              parcel.longitude = Math.trunc(parcel.longitude * 10000) / 10000;
            }
            if (parcel.latitude != undefined) {
              parcel.latitude = Math.trunc(parcel.latitude * 10000) / 10000;
            }
          }
          this.parcelsList = result.content;
        } else {
          this.parcelsList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(parcels?: Parcel[]) {
    if (!parcels) {
      this.optiSemisService
        .retrieveAllCreatedParcels(this.paging)
        .subscribe((parcelList) => this.buildExportCalc(parcelList));
    } else {
      this.buildExportCalc(parcels);
    }
  }

  private buildExportCalc(parcels: Parcel[]) {
    const parcelsSheet: XlsxSheet = {
      name: $localize`Récapitulatif des parcelles`,
      headerRow: this.columns.slice(1).map((column) => column.label),
      dataRows: this.getExportRows(parcels),
    };

    exportToXlsx($localize`export_parcelle_cree`, [parcelsSheet]);
  }

  private getExportRows(parcels: Parcel[]): string[][] {
    const result: string[][] = [];
    parcels.forEach((e) => {
      result.push([
        e.code,
        e.name,
        String(e.longitude),
        String(e.latitude),
        e.user?.email ?? '',
        e.customer?.name ?? '',
        e.crop?.label ?? '',
        e.variety ?? '',
        e.soil?.label ?? '',
        String(e.sowingDate ?? ''),
        e.sowingDensity ?? '',
      ]);
    });
    return result;
  }
}

import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TableComponent } from '@components/table/table.component';
import { ActionType, CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { Customer } from '@core/model/customer.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { IcareService } from '../../icare/icare.service';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { Trap } from './trap.model';

@Component({
  standalone: true,
  selector: 'smv-icare-trapview-trap-stats',
  templateUrl: './trapview-trap-stats.component.html',
  styleUrls: ['./trapview-trap-stats.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class trapviewTrapStatsComponent implements OnInit {
  @Input() title = $localize`:Icare Administration|TrapView Trap Summary:Récapitulatif des pièges TrapView`;
  @Input() appId!: number;
  public trapsList: Trap[] = [];
  public selection: Trap[] = [];
  public actionsConfig: GeneralActions<Trap> = {};
  public columns: Column<Trap, DefaultType<string>>[] = [];
  public paging: PagingInfo = new PagingInfo('icare-trapview-trap-list');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private notificationService: NotificationService,
    private icareService: IcareService,
    private dialog: MatDialog
  ) {
    this.appId = data.appId;
    this.paging.sort = [
      {
        column: 'modifiedDate',
        order: 'desc',
      },
    ];
    this.icareService.synchronizeCustomer();
  }

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      searchDisabled: false,
      searchPlaceholder: $localize`:Icare Administration|TrapView Trap Summary:Rechercher par code/description/client`,
      refreshDisabled: true,
    };
    this.columns = [
      {
        field: 'id',
        type: CellType.String,
        label: 'id',
        cellData: 'id',
        sort: true,
        hide: true,
      },
      {
        field: 'serialNumber',
        label: $localize`Code`,
        type: CellType.String,
        cellData: 'serialNumber',
      },
      {
        field: 'description',
        label: $localize`Description`,
        type: CellType.String,
        cellData: 'description',
      },
      {
        field: 'status',
        label: $localize`Status`,
        type: CellType.String,
        cellData: 'status',
      },
      {
        field: 'customer',
        label: $localize`Client`,
        type: CellType.String,
        cellData: ['customer', 'name'],
        allowCellEmpty: true,
      },
      {
        field: 'modified',
        label: $localize`Date modification`,
        type: CellType.String,
        cellData: 'modified',
      },
      {
        field: 'imported',
        label: $localize`Importé`,
        type: CellType.Boolean,
        cellData: 'imported',
      },
      {
        field: 'actions',
        label: '',
        type: CellType.Action,
        cellData: '',
        actions: [
          {
            type: ActionType.Menu,
            tooltip: $localize`:Access tooltip:Plus d'actions`,
            identifier: 'serialNumber',
            menu: [
              {
                type: ActionType.Modify,
                identifier: 'serialNumber',
                label: $localize`:Button:Modifier`,
                tooltip: $localize`::Icare Administration|Modify tooltip:Modifier le client`,
              },
            ],
          },
        ],
      },
    ];

    this.findTraps();
  }

  findTraps() {
    this.icareService.retrieveTrapViewTraps(this.appId, this.paging).subscribe({
      next: (result) => {
        if (result.page.totalElements > 0) {
          this.trapsList = result.content;
        } else {
          this.trapsList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(traps?: Trap[]) {
    if (!traps) {
      this.icareService
        .retrieveAllTrapViewTraps(this.appId, this.paging)
        .subscribe((trapList) => this.buildExportCalc(trapList));
    } else {
      this.buildExportCalc(traps);
    }
  }

  private buildExportCalc(traps: Trap[]) {
    const trapsSheet: XlsxSheet = {
      name: $localize`:Icare Administration|Export Sheet:Récapitulatif des pièges`,
      headerRow: this.columns.slice(1).map((column) => column.label),
      dataRows: this.getExportRows(traps),
    };

    exportToXlsx($localize`:Icare Administration|Export file:export_piege_cree`, [trapsSheet]);
  }

  private getExportRows(traps: Trap[]): string[][] {
    const result: string[][] = [];
    traps.forEach((e) => {
      result.push([
        e.serialNumber,
        e.description ?? '',
        e.status,
        e.customer.name,
        String(e.modified ?? ''),
        e.imported
          ? $localize`:Icare Administration|Export label Imported:Importé`
          : $localize`:Icare Administration|Export label Not Imported:Non importé`,
      ]);
    });
    return result;
  }

  modifyOneTrap(trapId: string) {
    const selectedTrap = this.trapsList.find((trap) => trap.id === trapId);
    if (selectedTrap) {
      this.openSelectCustomerDialog([selectedTrap]);
    }
  }

  openSelectCustomerDialog(traps?: Trap[]) {
    this.dialog
      .open(CustomerInformationComponent)
      .afterClosed()
      .subscribe((result: false | Customer) => {
        if (result) {
          this.icareService
            .modifyCustomerOfTrapviewTraps(
              this.appId,
              result.code,
              traps?.map((trap) => trap.id),
              traps?.map((trap) => trap.serialNumber),
              this.paging.setPageFilters(new HttpParams()).get('filter')
            )
            .subscribe(() => this.findTraps());
        }
      });
  }
}

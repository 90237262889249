import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { NotificationService } from '@core/services/notification.service';
import { TrapFilter, WidgetTrap } from '@widgets/components/statistic/statistic.model';
import { StatisticService } from '@widgets/components/statistic/statistic.service';
import { IcareService } from '../icare.service';
import { TrapStatistic } from '../model/trap.model';

import { KeyValuePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import moment from 'moment';
import { finalize } from 'rxjs';
import { SheetStatisticComponent } from '../../components/sheet-statistic/sheet-statistic.component';
import { InsectType } from '../model/icare.model';
import { IcareTrapStatisticsComponent } from './icare-trap-statistics/icare-trap-statistics.component';

@Component({
  selector: 'smv-icare-sheet',
  standalone: true,
  imports: [SheetStatisticComponent, IcareTrapStatisticsComponent, MatExpansionModule, KeyValuePipe],
  templateUrl: './icare-sheet.component.html',
  styleUrls: ['./icare-sheet.component.scss'],
})
export class IcareSheetComponent implements OnDestroy {
  public loading = true;
  public isDataLoaded = false;

  public title = $localize`Service Eudémis`;
  public titleMulti = $localize`Pression ravageur sur la zone géographique`;

  public traps: WidgetTrap[] = [];
  public trapMulti: { [key in InsectType]?: TrapStatistic[] } = {};
  public trapStatements: { [index: string]: { [key in InsectType]?: TrapStatistic[] } } = {};

  private date?: string;
  multiMode = false;
  insectTypes = Object.values(InsectType);

  constructor(
    private icareService: IcareService,
    private statisticService: StatisticService,
    private applicationService: ApplicationApiService,
    private notificationService: NotificationService
  ) {
    const app = this.applicationService.currentApplication.getValue();
    if (app?.functionnalityConfig.campaignStart) {
      let startDate = moment(app.functionnalityConfig.campaignStart, 'DD/MM');
      if (startDate.isAfter(moment.now())) {
        startDate = startDate.subtract(1, 'year');
      }
      this.date = startDate.format('YYYY-MM-DD');
    }

    const statisticConfig = this.statisticService.statisticState.getValue();
    if (statisticConfig.filter) {
      this.multiMode = true;
      this.getAreaData(statisticConfig.filter);
    } else if (statisticConfig.traps) {
      this.getTrapsData(statisticConfig.traps);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.statisticService.statisticState.reset();
  }

  getTrapsData(traps: WidgetTrap[]) {
    const query = {} as TrapFilter;
    if (this.date) {
      query.startDate = this.date;
    }

    for (const trap of traps) {
      this.icareService
        .getTrapsForStatistics(trap.trap_code, query)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: (statsByTypes) => {
            this.trapStatements[trap.trap_code] = {};
            this.insectTypes.forEach((type) => {
              if (statsByTypes[type]?.length) {
                this.trapStatements[trap.trap_code][type] = statsByTypes[type];
              }
            });
            if (Object.keys(this.trapStatements[trap.trap_code]).length) {
              this.traps.push(trap);
            }
            this.isDataLoaded = true;
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.notificationService.error(
                $localize`:Icare Statistic:Vous n'avez pas les droits pour visualiser le piège sélectionné`
              );
            } else if (error.status === HttpStatusCode.BadRequest) {
              this.notificationService.error(
                $localize`:Icare Statistic:Une erreur s'est produite dû à un manque de cohérence des paramètres `
              );
            } else {
              this.notificationService.unknownServerError();
            }
          },
        });
    }
  }

  getAreaData(filter: TrapFilter) {
    filter.startDate = this.date;

    this.icareService
      .getMultiTrapByZoneForStatistics(filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (traps) => {
          this.trapMulti = traps;
          this.isDataLoaded = true;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.InternalServerError) {
            this.notificationService.unknownServerError();
          }
        },
      });
  }
}

import { Component, input, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OptiSemisService } from './opti-semis.service';
import { UserParcelStatsComponent } from './user-parcel-stats/user-parcel.component';

@Component({
  selector: 'smv-opti-semis',
  templateUrl: './opti-semis.component.html',
  styleUrls: ['./opti-semis.component.scss', '../../client-carto-app-panel.scss'],
})
export class OptiSemisComponent {
  applicationId = input.required<number>();
  canImport = input<boolean>(false);
  canValid = input<boolean>(false);
  panelHide = input<boolean>(true);
  displayed = output<void>();

  public labelZoom = $localize`:Optisemis:Code de la parcelle`;

  constructor(
    private readonly dialogModule: MatDialog,
    private readonly optiSemisService: OptiSemisService
  ) {}

  getPosition = (code: string) => this.optiSemisService.getParcelPosition(code);

  public importLabel = $localize`:OptiSemis|Import label:Importer des parcelles`;

  openTrapStats() {
    this.dialogModule.open(UserParcelStatsComponent, {
      data: {
        appId: this.applicationId(),
      },
    });
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { MapUtils } from '@core/utils/map.utils';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { SelectionService } from './selection.service';

import { GeneralUtils, Indexable } from '@core/utils/general.utils';
import { Feature } from 'ol';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import WFS, { WriteTransactionOptions } from 'ol/format/WFS';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Observable, Subject, map, of } from 'rxjs';
import X2JS from 'x2js';

export interface FeatureCategories {
  toAdd: Feature[];
  toUpdate: Feature[];
  toDelete: Feature[];
}

interface ValueCollection {
  ValueCollection: {
    member: Indexable<string>[];
  };
}

export type actionType = 'insert' | 'updateGeometry' | 'updateAttributes' | 'delete';

@Injectable({ providedIn: 'root' })
export class GeoserverService {
  protected messageFail = $localize`:Edition|Error message: La proposition de modification a échoué. Veuillez vérifier que tous les attributs de l'entité sélectionnée sont corrects.`;
  protected messageSuccess = $localize`:Edition|Success message:La proposition de modification a été enregistrée avec succès.`;
  protected messageError = $localize`:Edition|Error message:Impossible d'éditer la couche. Aucune url n'est définie pour cette couche.`;
  protected messageNoRights = $localize`:Edition|Error message:Impossible d'éditer la couche. Vous ne possedez pas les droits de modification.`;
  protected vectorSource?: VectorSource<Geometry>;

  public resetValidationFilters: Subject<void> = new Subject<void>();

  constructor(
    protected readonly selectionService: SelectionService,
    protected readonly authService: AuthService,
    protected readonly notificationService: NotificationService,
    protected readonly http: HttpClient
  ) {}

  protected updateWfsFeature(
    features: FeatureCategories,
    layer: DataLayerConfig,
    useConfigProj = true,
    action?: actionType
  ): Observable<string | boolean> {
    const url = this.getWfsTransactionUrl(layer.config as WfsLayerConfig);
    if (url) {
      if (features.toAdd.length || features.toUpdate.length || features.toDelete.length) {
        const featureRequest = new WFS().writeTransaction(
          features.toAdd,
          features.toUpdate,
          features.toDelete,
          this.getWriteOption(layer.config as WfsLayerConfig, useConfigProj)
        );

        return this.editFeature(url, featureRequest).pipe(
          map((response) => {
            if (response.indexOf('ExceptionText') > 0) {
              if (response.indexOf('is read-only') > 0) {
                this.notificationService.error(this.messageNoRights);
              } else {
                this.notificationService.error(this.messageFail);
              }
              return false;
            } else {
              const transaction = new WFS().readTransactionResponse(response);
              if (action !== 'delete' && transaction && features.toAdd.length === transaction.insertIds.length) {
                for (const index in features.toAdd) {
                  const newFeature = features.toAdd[index];
                  newFeature.setId(transaction.insertIds[index]);
                  newFeature.unset('geom', true);
                  this.selectionService.addVectorFeatureToSelection(newFeature, layer);
                }
              } else if ((action === 'updateGeometry' || action === 'updateAttributes') && features.toUpdate.length) {
                const feature = features.toUpdate[0];
                this.selectionService.addVectorFeatureToSelection(feature, layer);
              }
              this.vectorSource?.refresh();
              this.resetValidationFilters.next();
              return this.messageSuccess;
            }
          })
        );
      }
    } else {
      this.notificationService.error(this.messageError);
    }
    return of(false);
  }

  private editFeature(url: string, transaction: Node) {
    const bodyString = new XMLSerializer().serializeToString(transaction);
    const headers = new HttpHeaders().append('Content-Type', 'text/plain;charset=UTF8');

    return this.http.post(url, bodyString, { responseType: 'text', headers });
  }

  public getPropertiesFromGeoserver(url: string, ...propertyNames: string[]): Observable<Indexable<string[]>> {
    return this.http.get<GeoJSONFeatureCollection>(url).pipe(
      map((collection: GeoJSONFeatureCollection) => collection.features.map((feature) => feature.properties)),
      map((featuresProperties) => featuresProperties.filter(GeneralUtils.isNotNull)),
      map((properties) => {
        const values: Indexable<string[]> = {};
        for (const property of propertyNames) {
          values[property] = GeneralUtils.getDistinct(properties, property);
        }
        return values;
      })
    );
  }

  public getPropertyValuesFromGeoserver(url: string, propertyName: string): Observable<string[]> {
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((values) => {
        const parser = new X2JS();
        const parsedValues: ValueCollection = parser.xml2js(values);
        return parsedValues.ValueCollection.member;
      }),
      map((properties) => [...new Set(properties.map((property) => property[propertyName].toString()))])
    );
  }

  public getFeaturesFromGeoserver(url: string) {
    return this.http
      .get<GeoJSONFeatureCollection>(url)
      .pipe(map((collection: GeoJSONFeatureCollection) => collection.features));
  }

  private getWfsTransactionUrl(layer: WfsLayerConfig) {
    let transactionUrl = layer.url;
    const authKey = this.authService.getSessionAuthKey();
    if (authKey) {
      const params = new HttpParams().append('authkey', authKey);
      if (transactionUrl && transactionUrl.indexOf('?') > 0) {
        transactionUrl += '&' + params.toString();
      } else {
        transactionUrl += '?' + params.toString();
      }
    }
    return transactionUrl;
  }

  private getWriteOption(layer: WfsLayerConfig, useConfigProj: boolean): WriteTransactionOptions {
    // Toutes les interactions sont dans la projection de la carte
    return {
      srsName: (useConfigProj ? layer.projection : undefined) ?? MapUtils.PROJECTION_MAP?.getCode(),
      featureNS: layer.geoserverNameSpace ?? layer.geoserverLayerName.split(':')[0],
      featurePrefix: layer.geoserverPrefix ?? layer.geoserverLayerName.split(':')[0],
      featureType: layer.geoserverLayerName.split(':')[1],
      nativeElements: [],
    };
  }
}

import { CommonModule } from '@angular/common';
import { Component, input, OnChanges, OnDestroy, signal, WritableSignal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AAC_GEOSERVER_LAYER } from '@assets/widgets/qualih2o/qualih2o_base_config';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ReadOnlyToggleDirective } from '@components/form/readonly-toggle.directive';
import { AttributeType, GeometryType, LayerEditType, LayerProperty } from '@core/model/application-api/layer.model';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { EditionService } from '@core/services/edition.service';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { Selection, SelectionService } from '@core/services/selection.service';
import { Indexable } from '@core/utils/general.utils';
import { MapUtils } from '@core/utils/map.utils';
import { FeatureAttributesParser } from '@core/utils/parsers/feature-attributes-parser';
import { QualiH2oModule } from '@widgets/quali-h2o/quali-h2o.module';
import { DataLayerConfig } from '../data-layer-config.model';

import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { isNil, memoize } from 'lodash-es';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { debounceTime, delay, of, Subscription, tap } from 'rxjs';

type ErrorBehaviour = 'SILENT' | 'LOUD';

type ProgressStatus = 'STARTED' | 'DONE_INIT' | 'DONE' | 'VALUE_CHECKED';

@Component({
  selector: 'smv-feature-details',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MultiSelectComponent,
    ReadOnlyToggleDirective,
    QualiH2oModule,
  ],
  templateUrl: './feature-details.component.html',
  styleUrls: ['./feature-details.component.scss'],
})
export class FeatureDetailsComponent implements OnChanges, OnDestroy {
  canEdit = input<boolean>();

  public selection?: Selection;
  public selectedFeature?: Feature;
  public selectedLayer?: DataLayerConfig;

  public AttributeType = AttributeType;
  public LayerEditType = LayerEditType;
  public isAACFeature = false;
  public canUpdateGeom = false;

  public isSaving = false;
  public isSelectionChange = false;
  public parser?: FeatureAttributesParser;

  public options: Indexable<WritableSignal<string[] | DropdownOption<string | number>[]>> = {};

  private geomUpdated = false;
  private dataSourceProgress: Indexable<ProgressStatus> = {};
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly selectionService: SelectionService,
    private readonly editionService: EditionService,
    private readonly mapService: MapService,
    private readonly notificationService: NotificationService
  ) {
    this.subscriptions.add(
      this.selectionService.selectedFeatures
        .getStream()
        .pipe(debounceTime(100))
        .subscribe((selections) => {
          this.selection = selections?.find((selection) => selection.features.length);
          this.selectedFeature = this.selection?.features[0];
          this.isAACFeature =
            this.selection?.layer.config instanceof WmsLayerConfig &&
            this.selection.layer.config.geoserverLayerName === AAC_GEOSERVER_LAYER;

          if (this.selectedFeature && this.selectedLayer === this.selection?.layer && this.parser) {
            // TODO: use something better
            this.configToReinitOptions();
            this.parser.form.patchValue(this.selectedFeature.getProperties());
          } else if (this.selectedFeature) {
            this.selectedLayer = this.selection?.layer;
            this.parser = new FeatureAttributesParser(
              this.selectedFeature,
              this.selectedLayer?.config.properties,
              this.isEditable()
            );
            this.canUpdateGeom =
              this.selectedLayer?.config.geometryType === GeometryType.POINT &&
              !isNil(this.parser?.coordAttributes?.long?.value) &&
              !isNil(this.parser.coordAttributes.lat?.value);
            this.getArrayControl.cache.clear?.();
          }

          if (this.parser) {
            for (const attribute of this.parser.attributes) {
              if (!this.isDefaultDisplay(attribute)) {
                if (attribute.configuration?.dependsOn) {
                  this.parser.form.controls[attribute.configuration.dependsOn].valueChanges.subscribe(() => {
                    this.options[attribute.name] = signal(this.getSelectOptions(attribute));
                  });
                } else {
                  this.options[attribute.name] = signal(this.getSelectOptions(attribute));
                }
              }
              if (attribute.type === AttributeType.STRING_ARRAY) {
                const dropdownOptions = this.getArrayOptions(attribute.name);
                const options = this.filterArrayOptions(attribute.name, dropdownOptions);
                this.getArrayControl(attribute.name)?.patchValue(options);
              }
            }
          }

          this.parser?.savedForm.patchValue(this.parser?.form.value);
          this.setFormDisability();
          this.geomUpdated = false;
        })
    );
  }

  ngOnChanges() {
    this.setFormDisability();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setFormDisability() {
    if (this.isEditable()) {
      this.parser?.form.enable();
    } else {
      this.parser?.form.disable();
    }
  }

  isDefaultDisplay(attribute: LayerProperty) {
    const defaultValues = attribute.configuration?.defaultValues ?? {};
    return (
      this.parser?.form.disabled ||
      !attribute.editable ||
      ((Object.keys(defaultValues).length == 0 ||
        (defaultValues['default']?.length ?? 2) <= 1 ||
        (attribute.configuration?.dependsOn &&
          !defaultValues[this.parser?.form.controls[attribute.configuration.dependsOn]?.value])) &&
        !attribute.configuration?.dataSource?.length)
    );
  }

  getSelectOptions(attribute: LayerProperty) {
    if (!attribute.configuration || !this.parser) {
      return [];
    }
    const dependsAttributeValue =
      !isNil(attribute.configuration.dependsOn) && this.parser.form.controls[attribute.configuration.dependsOn]
        ? this.parser.form.controls[attribute.configuration.dependsOn].value
        : undefined;
    if (attribute.configuration.dataSource?.length) {
      if (this.isSelectionChange) {
        attribute.configuration.asyncFirstChecked = false;
      }
      if (
        !attribute.configuration.values &&
        !attribute.configuration.dataSourceChecked &&
        !attribute.configuration.dependsOn
      ) {
        this.dataSourceProgress[attribute.name] = 'STARTED';
        this.sendDataSourceRequest(attribute, 'LOUD', dependsAttributeValue);
      } else if (
        attribute.configuration.dependsOn &&
        !isNil(dependsAttributeValue) &&
        attribute.configuration.dependsOnSourceValueChecked != dependsAttributeValue
      ) {
        attribute.configuration.dependsOnSourceValueChecked = dependsAttributeValue;
        this.dataSourceProgress[attribute.name] = 'STARTED';
        this.sendDataSourceRequest(attribute, 'SILENT', dependsAttributeValue?.toString() ?? '');
      }
      if (this.selectedFeature && this.dataSourceProgress[attribute.name] == 'DONE_INIT') {
        this.parser.form.patchValue({ [attribute.name]: this.selectedFeature.get(attribute.name) });
        this.dataSourceProgress[attribute.name] = 'VALUE_CHECKED';
      }
      return attribute.configuration.values ?? [];
    }
    if (
      !attribute.configuration?.dependsOn ||
      attribute.configuration.defaultValues[dependsAttributeValue] == undefined
    ) {
      return attribute.configuration?.defaultValues['default'];
    }
    return attribute.configuration.defaultValues[dependsAttributeValue];
  }

  isRequired(attribute: LayerProperty) {
    const attrDependance = this.parser?.attributes.find((attr) => attr.name === attribute.configuration?.dependsOn);
    if (!attrDependance || attribute.configuration?.dataSource) {
      return !attribute.nillable;
    }
    const attrDependanceValue = this.parser?.form.controls[attrDependance.name].value;
    const isDepNil = isNil(attrDependanceValue);
    return !isDepNil && attribute.configuration?.defaultValues[attrDependanceValue]?.length != 0;
  }

  isEditable() {
    const layerEditable = this.selectedLayer?.config?.editable && this.selectedLayer?.isEditing;
    if (this.selectedFeature?.get('to_create')) {
      return this.canEdit() && layerEditable;
    }
    const canEditAttributes = this.selectedLayer?.config.editType
      ? [LayerEditType.ALL, LayerEditType.MODIFY, LayerEditType.ATTRIBUTES].includes(
          this.selectedLayer?.config.editType
        )
      : false;
    return layerEditable && canEditAttributes && this.canEdit();
  }

  updateGeom(long: number, lat: number) {
    const geom = this.selectedFeature?.getGeometry();
    if (geom) {
      const coordinate = transform([long, lat], MapUtils.PROJECTION_CODE_OPENLAYERS, MapUtils.PROJECTION_MAP);
      const point = geom as Point;
      point.setCoordinates(coordinate);
      if (this.selectedLayer) {
        this.mapService.fitToLayer(this.selectedLayer.config, point.getExtent(), MapUtils.PROJECTION_MAP?.getCode());
      }
      this.geomUpdated = true;
    }
  }

  resetModifications() {
    this.parser?.reset();
    this.geomUpdated = false;
  }

  confirmModifications() {
    this.parser?.savedForm.patchValue(this.parser?.form.value);
    if (this.selectedFeature && this.selectedLayer && this.parser) {
      this.isSaving = true;
      const modifyFeature = this.selectedFeature.clone();
      modifyFeature.setId(this.selectedFeature.getId());
      modifyFeature.setProperties(this.parser.form.value);
      if (this.selectedLayer.config.type === 'Vector') {
        this.selectedFeature.setProperties(this.parser.form.value);
      }
      this.editionService
        .updateFeature(
          this.selectedFeature,
          modifyFeature,
          this.selectedLayer,
          this.geomUpdated ? 'updateGeometry' : 'updateAttributes'
        )
        .add(() => {
          this.isSaving = false;
          this.geomUpdated = false;
        });
    }
  }

  getInputType(type: AttributeType) {
    return FeatureAttributesParser.getInputType(type);
  }

  private sendDataSourceRequest(
    attribute: LayerProperty,
    _errorBehaviour: ErrorBehaviour,
    dependsAttributeValue?: string
  ) {
    if (attribute.configuration?.dataSource) {
      attribute.configuration.dataSourceChecked = true;
      const data = this.editionService.getDataFromDataSource(attribute.configuration.dataSource, dependsAttributeValue);
      this.subscriptions.add(
        data.subscribe({
          next: (values) => {
            if (attribute.configuration) {
              this.dataSourceProgress[attribute.name] = attribute.configuration.asyncFirstChecked
                ? 'DONE'
                : 'DONE_INIT';
              attribute.configuration.asyncFirstChecked = true;
            }
            const dropdownOptions: DropdownOption<string | number>[] = [];
            for (const key of Object.keys(values)) {
              let value: string | number = key;
              if (attribute.type == AttributeType.NUMBER && !isNaN(Number(key))) {
                value = Number(key);
              }
              dropdownOptions.push({ label: values[key], value });
            }
            dropdownOptions.sort((a, b) => a.label.localeCompare(b.label));
            if (attribute.configuration) {
              attribute.configuration.values = dropdownOptions;
              this.options[attribute.name].set(dropdownOptions);
              if (this.parser && this.selectedFeature) {
                this.parser.form.patchValue({ [attribute.name]: this.selectedFeature.get(attribute.name) });
                if (attribute.type == AttributeType.STRING_ARRAY) {
                  const options = this.filterArrayOptions(attribute.name, dropdownOptions);
                  this.parser.form.get(`${attribute.name}Array`)?.patchValue(options);
                }
              }
            }
          },
          error: () => {
            switch (_errorBehaviour) {
              case 'LOUD':
                this.notificationService.error(
                  $localize`:Erreur: Échec de la récupération des données du champ: ` + attribute.name
                );
                break;
              case 'SILENT':
                if (attribute.configuration) {
                  attribute.configuration.values = [];
                  this.options[attribute.name].set([]);
                }
                break;
              default:
                break;
            }
          },
        })
      );
    }
  }

  private configToReinitOptions() {
    of(true)
      .pipe(
        tap(() => (this.isSelectionChange = true)),
        delay(500),
        tap(() => (this.isSelectionChange = false))
      )
      .subscribe();
  }

  getFilter = memoize((attributeName: string) => {
    return (search: string): DropdownOption<string | number>[] => {
      const filterValue = search.toLowerCase();
      const dropdownOptions = this.getArrayOptions(attributeName).filter((option) => {
        return (
          option.label.toLowerCase().includes(filterValue) ||
          option.value.toString().toLowerCase().includes(filterValue)
        );
      });
      return this.filterArrayOptions(attributeName, dropdownOptions, false);
    };
  });

  getArrayControl = memoize(
    (field) =>
      this.parser?.form.get(`${field}Array`) as FormControl<DropdownOption<string | number>[]> | null | undefined
  );

  onSelectionChange(selected: DropdownOption<String | number>[], attributeName: string) {
    const stringifiedValue = selected
      .map((option) => {
        return typeof option === 'string' ? option : option.value;
      })
      .join('|');
    this.parser?.form.controls[attributeName].setValue(stringifiedValue);
  }

  private getArrayOptions(attributeName: string): DropdownOption<string | number>[] {
    return this.options[attributeName]().map((option) => {
      if (typeof option === 'string') {
        return { label: option, value: option };
      }
      return option;
    });
  }

  private filterArrayOptions(
    attributeName: string,
    options: DropdownOption<string | number>[],
    includes = true
  ): DropdownOption<string | number>[] {
    const currentValueAsString = this.parser?.form.get(attributeName)?.getRawValue();
    if (currentValueAsString) {
      const ids = currentValueAsString.split('|');
      return options.filter((option) => {
        if (includes) {
          return ids.includes(option.value.toString());
        }
        return !ids.includes(option.value.toString());
      });
    }
    return [];
  }
}

<div [hidden]="panelHide()">
  <mat-card class="side-panel interra-scan-panel">
    <mat-card-title>
      <div>
        <span i18n="InterraScan|Title widget">InterraScan</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <div class="buttons-stats">
        @if (canEdit()) {
          <button mat-raised-button color="primary" (click)="openFarmerStats()">
            <span i18n="InterraScan|Farmer management">Gestion des agriculteurs</span>
          </button>
        }
        @if (canEdit() || (isOperateur$ | async)) {
          <button mat-raised-button color="primary" (click)="openParcelStats()">
            <span i18n="InterraScan|Parcel overview">Récapitulatif des parcelles</span>
          </button>
        }
        @if (canEdit()) {
          <button mat-raised-button color="primary" (click)="openParcelSynthesis()">
            <span i18n="InterraScan|Parcel synthesis">Synthèse parcellaire</span>
          </button>
        }
      </div>

      <smv-interra-scan-parcel-filter [appId]="applicationId()" />
      <smv-zoom-position [label]="labelZoom" [positionFunction]="getPosition" />
    </mat-card-content>
  </mat-card>
</div>

<div class="smv-table-management">
  @let actions = generalActions();
  @if (actions && !actions.tableOnly) {
    <div [ngClass]="{ 'smv-page-layout': actions.pageLayout }">
      <div class="smv-header">
        <div class="smv-actions">
          @if (!actions.searchDisabled && actions.searchPlaceholder) {
            <smv-search-field
              class="search-field"
              [placeholder]="actions.searchPlaceholder"
              (search)="search($event)"
            />
          }
          @let filters = columnFilters();
          @for (filter of filters; track filter) {
            @if (filter && filter.columnFilterList.size > 0) {
              <mat-form-field subscriptSizing="dynamic">
                <mat-label>{{ filter.filterLabel }}</mat-label>
                <mat-select
                  class="type-filter"
                  [value]="filter.selectedOption"
                  (selectionChange)="onColumnFilterChange(filter, $event.value)"
                >
                  <mat-select-trigger>
                    <div class="smv-filter">
                      <mat-icon>filter_list</mat-icon>
                      <span>{{ filter.selectedOption || filter.defaultOptionLabel }}</span>
                    </div>
                  </mat-select-trigger>
                  <mat-option [value]="''">{{ filter.defaultOptionLabel }}</mat-option>
                  @for (option of filter.columnFilterList; track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
          }
          <div class="action-buttons">
            @if (showUserFilter()) {
              <div>
                <button mat-mini-fab color="success" [matMenuTriggerFor]="menu">
                  <mat-icon>manage_search</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <form (click)="$event.stopPropagation()" #formFiltresRef>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is editor"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['editor']"
                        (change)="search(this.searchInput)"
                      >
                        Est éditeur
                      </mat-slide-toggle>
                    </div>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is administrator"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['admin']"
                        (change)="search(this.searchInput)"
                      >
                        Est administrateur
                      </mat-slide-toggle>
                    </div>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is right manager"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['rightAdmin']"
                        (change)="search(this.searchInput)"
                      >
                        Est gestionnaire de droits
                      </mat-slide-toggle>
                    </div>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is reader"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['reader']"
                        (change)="search(this.searchInput)"
                      >
                        Est lecteur
                      </mat-slide-toggle>
                    </div>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is validator"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['validator']"
                        (change)="search(this.searchInput)"
                      >
                        Est validateur
                      </mat-slide-toggle>
                    </div>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is specifier"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['specifier']"
                        (change)="search(this.searchInput)"
                      >
                        Est spécificateur
                      </mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div mat-menu-item>
                      <mat-slide-toggle
                        i18n="Slider|Is admin"
                        name="slideInput"
                        [(ngModel)]="inputSliderValues['superAdmin']"
                        (change)="search(this.searchInput)"
                      >
                        Est administrateur
                      </mat-slide-toggle>
                    </div>
                  </form>
                </mat-menu>
              </div>
            }
            @if (!actions.rightActionDisabled && actions.rightActionTooltip && actions.rightActionIcon) {
              <button
                mat-mini-fab
                color="secondary"
                (click)="openRightAction()"
                [matTooltip]="actions.rightActionTooltip"
              >
                <mat-icon>{{ actions.rightActionIcon }}</mat-icon>
              </button>
            }
            @if (!actions.refreshDisabled) {
              <button mat-mini-fab color="secondary" (click)="onRefresh()" matTooltip="Actualiser" i18n-matTooltip>
                <mat-icon>refresh</mat-icon>
              </button>
            }
            @if (!actions.addDisabled && actions.addTooltip) {
              <button mat-mini-fab color="success" (click)="onAdd()" [matTooltip]="actions.addTooltip">
                <mat-icon>{{ actions.addIcon }}</mat-icon>
              </button>
            }
            @if (!actions.deleteSelectedDisabled && actions.deleteTooltip) {
              <button
                mat-mini-fab
                color="warn"
                (click)="askToDelete()"
                [matTooltip]="actions.deleteTooltip"
                [disabled]="!allSelected && !someSelected()"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            }
            @if (!actions.actionSelectedDisabled && actions.actionSelectedTooltip && actions.actionSelectedIcon) {
              <button
                mat-mini-fab
                color="secondary"
                (click)="onActionSelected()"
                [matTooltip]="actions.actionSelectedTooltip"
                [disabled]="!allSelected && !someSelected()"
              >
                <mat-icon>{{ actions.actionSelectedIcon }}</mat-icon>
              </button>
            }
          </div>
        </div>
        @if (actions.propertiesSetter && actions.setterLabel) {
          <div class="properties-setter-container">
            <div class="label">{{ actions.setterLabel }}</div>
            @if (actions.setterText) {
              <p>{{ actions.setterText }}</p>
            }
            <div class="properties-setter padding-y">
              @for (column of columnsDef; track column.field) {
                @if (column.editable && getSetterControl(column.field)) {
                  @switch (column.type) {
                    @case (CellType.String) {
                      <smv-form-field-wrapper
                        [formControl]="getSetterControl(column.field)!"
                        [type]="column.editType ?? 'text'"
                        subscriptSizing="dynamic"
                      >
                        <span mat-label>{{ column.label }}</span>
                      </smv-form-field-wrapper>
                    }
                    @case (CellType.Boolean) {
                      <mat-slide-toggle
                        [formControl]="getSetterControl(column.field)!"
                        class="toggle"
                        labelPosition="before"
                      >
                        {{ column.label }}
                      </mat-slide-toggle>
                    }
                    @case (CellType.Select) {
                      @if (column.select && column.cellDataSelect && getSetterControl(column.field)) {
                        @if (column.select.enableAutocomplete) {
                          @if (column.select.multiSelect) {
                            <smv-multi-select
                              [label]="column.label"
                              [identifier]="column.select.identifier"
                              [maxSelected]="column.select.multiSelect.maxSelected"
                              [serverFilterFonction]="column.select.multiSelect.serverFilterOptions"
                              [control]="getSetterControl(column.field)!"
                              [displayRow]="column.select.multiSelect.displayRow"
                              [displayRowEm]="column.select.multiSelect.displayRowEm"
                            />
                          } @else {
                            <smv-form-field-wrapper
                              [formControl]="getSetterControl(column.field)!"
                              controlType="autocomplete"
                              [selectLabelIdentifier]="column.select.identifier"
                              [selectIdentifier]="column.select.valueExpr"
                              [selectOptions]="column.select.list"
                              [serverFilterOptions]="column.select.serverFilterOptions"
                              subscriptSizing="dynamic"
                            >
                              <span mat-label>{{ column.label }}</span>
                            </smv-form-field-wrapper>
                          }
                        } @else {
                          <mat-form-field subscriptSizing="dynamic" class="padding-y">
                            <mat-select
                              [formControl]="getSetterControl(column.field)!"
                              placeholder="Sélectionner"
                              i18n-placeholder
                              panelClass="select-panel"
                            >
                              @for (element of column.select.list; track element.id) {
                                <mat-option [value]="element[column.select.valueExpr]">
                                  {{ element[column.select.identifier] }}
                                </mat-option>
                              }
                            </mat-select>
                            <mat-label class="unselectable">{{ column.label }}</mat-label>
                          </mat-form-field>
                        }
                      }
                    }
                    @case (CellType.Date) {
                      <smv-form-field-wrapper
                        [formControl]="getSetterControl(column.field)!"
                        type="date"
                        subscriptSizing="dynamic"
                      >
                        <span mat-label>{{ column.label }}</span>
                      </smv-form-field-wrapper>
                    }
                  }
                }
              }
            </div>
            <button mat-raised-button class="setter-btn" (click)="setSelectionProperties()" color="primary">
              {{ actions.setterBtnLabel || defaultSetterBtnLabel }}
            </button>
          </div>
        }
      </div>
    </div>
  }

  <div class="smv-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortDisabled]="sortDisabled()"
      (matSortChange)="sortData($event)"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="allSelected"
            color="primary"
            [indeterminate]="someSelected()"
            (change)="selectAll($event.checked)"
          />
        </th>
        <!-- "dataIndex" instead of "index" because of multiTemplateDataRows -->
        <td mat-cell *matCellDef="let row; let i = dataIndex">
          @if (!(generalActions()?.hideCheckboxCondition && generalActions()?.hideCheckboxCondition(row))) {
            <mat-checkbox
              color="accent"
              [(ngModel)]="task.subtasks[i].selected"
              (ngModelChange)="updateAllSelected()"
            />
          }
        </td>
      </ng-container>

      @for (column of columnsDef; track column.field) {
        <ng-container matColumnDef="{{ column.field }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort">
            {{ column.label }}
            @if (column.helpText) {
              <smv-help-overlay>
                <div>{{ column.helpText }}</div>
              </smv-help-overlay>
            }
          </th>
          @switch (column.type) {
            @case (CellType.RowDetail) {
              <td mat-cell *matCellDef="let row">
                @let isExpanded = expandedRow === row;
                <button mat-icon-button (click)="expandRow(row, $event)">
                  @if (isExpanded) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  } @else {
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  }
                </button>
              </td>
            }
            @case (CellType.Boolean) {
              <td mat-cell *matCellDef="let row; dataIndex as idx" class="text-nowrap" [style.width]="column.width">
                @if (column.editable && getControl(idx, column.field)) {
                  <mat-slide-toggle [formControl]="getControl(idx, column.field)!" />
                } @else {
                  @if (getAttribute(row, column.cellData)) {
                    <mat-icon>check</mat-icon>
                  }
                }
              </td>
            }
            @case (CellType.BooleanLabels) {
              <td mat-cell *matCellDef="let row" class="text-nowrap" [style.width]="column.width">
                {{ getAttribute(row, column.cellData) === true ? column.cellDataTrue : column.cellDataFalse }}
              </td>
            }
            @case (CellType.String) {
              <td mat-cell *matCellDef="let row; dataIndex as idx" class="text-nowrap" [style.width]="column.width">
                @if (column.editable && getControl(idx, column.field)) {
                  <smv-form-field-wrapper
                    [formControl]="getControl(idx, column.field)!"
                    [type]="column.editType ?? 'text'"
                    subscriptSizing="dynamic"
                    hideLabel
                  />
                } @else {
                  <div class="cell">
                    @if (column.icon && checkIconVisibility(column, row)) {
                      <mat-icon>{{ getIcon(column, row) }}</mat-icon>
                    }
                    {{ getAttribute(row, column.cellData) }}
                  </div>
                }
              </td>
            }
            @case (CellType.StringSmall) {
              <td mat-cell *matCellDef="let row" class="text-nowrap text-small" [style.width]="column.width">
                <div class="cell">
                  @if (column.icon && checkIconVisibility(column, row)) {
                    <mat-icon>{{ getIcon(column, row) }}</mat-icon>
                  }
                  {{ getAttribute(row, column.cellData) }}
                </div>
              </td>
            }
            @case (CellType.Date) {
              <td mat-cell *matCellDef="let row; dataIndex as idx" class="text-nowrap" [style.width]="column.width">
                @if (column.editable && getControl(idx, column.field)) {
                  <smv-form-field-wrapper
                    [formControl]="getControl(idx, column.field)!"
                    type="date"
                    subscriptSizing="dynamic"
                    hideLabel
                  />
                } @else {
                  {{ getAttribute(row, column.cellData) | date: column.dateFormat }}
                }
              </td>
            }
            @case (CellType.Select) {
              <td mat-cell *matCellDef="let row; dataIndex as idx" [style.width]="column.width">
                @if (
                  column.select &&
                  !column.select.enableAutocomplete &&
                  column.cellDataSelect &&
                  getControl(idx, column.field)
                ) {
                  <mat-form-field subscriptSizing="dynamic" class="padding-y">
                    <mat-select
                      [formControl]="getControl(idx, column.field)!"
                      (selectionChange)="onChange(row)"
                      [disabled]="!!column.select.disabled && column.select.disabled(row)"
                      placeholder="Sélectionner"
                      i18n-palceholder
                      panelClass="select-panel"
                    >
                      @for (element of column.select.list; track element.id) {
                        <mat-option
                          [value]="element[column.select.valueExpr]"
                          [disabled]="
                            column.select.hideOptionCondition && column.select.hideOptionCondition(row, element)
                          "
                        >
                          {{ element[column.select.identifier] }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
                @if (
                  column.select &&
                  column.select.enableAutocomplete &&
                  column.cellDataSelect &&
                  getControl(idx, column.field)
                ) {
                  <div class="padding-y">
                    @if (column.select.multiSelect) {
                      <smv-multi-select
                        [label]="column.label"
                        [maxSelected]="column.select.multiSelect.maxSelected"
                        [identifier]="column.select.identifier"
                        [serverFilterFonction]="column.select.multiSelect.serverFilterOptions"
                        [control]="getControl(idx, column.field)"
                        [displayRow]="column.select.multiSelect.displayRow"
                        [displayRowEm]="column.select.multiSelect.displayRowEm"
                      />
                    } @else {
                      <smv-form-field-wrapper
                        [control]="getControl(idx, column.field)"
                        controlType="autocomplete"
                        [selectLabelIdentifier]="column.select.identifier"
                        [selectIdentifier]="column.select.valueExpr"
                        [selectOptions]="column.select.list"
                        [serverFilterOptions]="column.select.serverFilterOptions"
                        readOnly="column.select.hideOptionCondition && column.select.hideOptionCondition(row, element)"
                        subscriptSizing="dynamic"
                        hideLabel
                      >
                        <span mat-label>{{ column.label }}</span>
                      </smv-form-field-wrapper>
                    }
                  </div>
                }
              </td>
            }
            @case (CellType.Img) {
              <td mat-cell *matCellDef="let row" [style.width]="column.width">
                @if (row.logoName) {
                  <img
                    src="{{ column.cellData }}/{{ row.id }}/logo"
                    [alt]="row.logoName"
                    width="60em"
                    title="{{ row.logoName }}"
                  />
                } @else {
                  <em></em>
                }
              </td>
            }
            @case (CellType.Visibility) {
              <td mat-cell *matCellDef="let row" [style.width]="column.width">
                @if (getAttribute(row, column.cellData)) {
                  <mat-icon>lock_open</mat-icon>
                } @else {
                  <mat-icon>lock</mat-icon>
                }
              </td>
            }
            @case (CellType.Action) {
              <td
                mat-cell
                *matCellDef="let row"
                class="sticky-right"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
              >
                <ng-container *ngTemplateOutlet="rowActions; context: { $implicit: column.actions, row: row }" />
              </td>
            }

            @case (CellType.GeoJson) {
              <td
                mat-cell
                *matCellDef="let row"
                class="sticky-right"
                [style.minWidth]="column.minWidth"
                [style.width]="column.width"
              >
                @if (getAttribute(row, column.cellData)) {
                  <svg
                    viewBox="0 0 25 25"
                    class="filter-option-img"
                    [fromGeoJson]="getAttribute(row, column.cellData)"
                    [size]="5"
                    width="50px"
                    height="50px"
                  ></svg>
                }
              </td>
            }

            @case (CellType.Template) {
              <td mat-cell *matCellDef="let row; dataIndex as idx" class="text-nowrap" [style.width]="column.width">
                @let cellTemplate = column.template;
                @if (cellTemplate) {
                  <ng-container
                    *ngTemplateOutlet="
                      cellTemplate;
                      context: { $implicit: row, dataFrom: getControl(idx, column.dependsOn) }
                    "
                  />
                }
              </td>
            }
          }
        </ng-container>
      }

      <ng-container matColumnDef="rowDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="columnsDef.length">
          <div>
            @let rowTemplate = rowDetailTemplate();
            @if (rowTemplate) {
              <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: row }" />
            }
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !generalActions()?.stickyDisabled"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="expandRow(row, $event)"></tr>
      @if (rowDetailTemplate()) {
        <tr
          mat-row
          *matRowDef="let row; columns: ['rowDetail']"
          class="row-detail"
          [ngClass]="{ 'row-expanded': row === expandedRow }"
        ></tr>
      }
    </table>
    @if (!dataSource.data.length) {
      <div class="empty-grid-text" i18n="No data">Aucune donnée à afficher.</div>
    }
  </div>
  @if (!paginatorDisabled()) {
    <smv-paging [pagingInfo]="pagingInfo()" (pageChange)="onRefresh()" />
  }
</div>

<ng-template #loadTpl i18n="Table|Loadind data">Récupération des données...</ng-template>

<ng-template #rowActions let-actions let-row="row">
  @for (action of actions; track action) {
    @if (!(action.hide && action.hideCondition && action.hideCondition(row))) {
      @switch (action.type) {
        @case (ActionType.Router) {
          @if (action.linkProperty) {
            <button
              mat-icon-button
              color="primary"
              [routerLink]="[action.link, row[action.linkProperty]]"
              [matTooltip]="action.tooltip"
            >
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
          } @else {
            <button mat-icon-button color="primary" [routerLink]="[action.link, row.id]" [matTooltip]="action.tooltip">
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
          }
        }
        @case (ActionType.Menu) {
          <button
            mat-icon-button
            [matTooltip]="action.tooltip"
            [matMenuTriggerFor]="actionMenu"
            [matMenuTriggerData]="{ row: row, actions: action.menu }"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        }
        @case (ActionType.Default) {
          @if (action.label) {
            <button
              mat-stroked-button
              (click)="onDefaultAction(row[action.identifier], action.key); $event.stopPropagation()"
              [matTooltip]="action.tooltip"
              matTooltipPosition="before"
            >
              @if (action.icon) {
                <mat-icon>{{ action.icon }}</mat-icon>
              }
              <span>{{ action.label }}</span>
            </button>
          } @else {
            <button
              mat-icon-button
              (click)="onDefaultAction(row[action.identifier], action.key); $event.stopPropagation()"
              [matTooltip]="action.tooltip"
              matTooltipPosition="before"
            >
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
          }
        }
      }
    }
  }
</ng-template>

<ng-template #rowMenuActions let-actions let-row="row">
  @for (action of actions; track action) {
    @if (!(action.hide && action.hideCondition && action.hideCondition(row))) {
      @switch (action.type) {
        @case (ActionType.Modify) {
          <button mat-menu-item (click)="onModify(row.id)" [matTooltip]="action.tooltip" matTooltipPosition="before">
            <mat-icon>edit_user</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
        @case (ActionType.Delete) {
          <button
            mat-menu-item
            (click)="onDelete([row[action.identifier]], [row.id])"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>delete</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
        @case (ActionType.Default) {
          <button
            mat-menu-item
            (click)="onDefaultAction(row[action.identifier], action.key)"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
        @case (ActionType.Router) {
          @if (action.linkProperty) {
            <button mat-menu-item [routerLink]="[action.link, row[action.linkProperty]]">
              <mat-icon>{{ action.icon }}</mat-icon>
              <span>{{ action.label }}</span>
            </button>
          } @else {
            <button mat-menu-item [routerLink]="[action.link, row.id]">
              <mat-icon>{{ action.icon }}</mat-icon>
              <span>{{ action.label }}</span>
            </button>
          }
        }

        @case (ActionType.Link) {
          <button
            mat-menu-item
            (click)="goToLink(action.linkGetter(row))"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
      }
    }
  }
</ng-template>

<mat-menu #actionMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-actions="actions" let-row="row">
    <ng-container *ngTemplateOutlet="rowMenuActions; context: { $implicit: actions, row: row }" />
  </ng-template>
</mat-menu>

import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { SimpleUser, User } from '@core/model/user.model';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { CustomerFilterComponent } from '../../components/customer-filter/customer-filter.component';
import { GeoserverVariablesService } from '../../widgets-filter-cql.service';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-parcel-filter',
  templateUrl: './parcel-filter.component.html',
  styleUrls: ['./parcel-filter.component.scss'],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatRadioModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
    CustomerFilterComponent,
    MatSlideToggleModule,
  ],
})
export class ParcelFilterComponent implements OnInit {
  appId = input.required<number>();
  isOperator = input(false);
  title = $localize`:InterraScan|Parcel filter:Filtre des parcelles`;

  public technicians: DropdownOption<SimpleUser>[] = [];
  public operators: DropdownOption<SimpleUser>[] = [];

  public form = this.formBuilder.group({
    technician: [undefined as SimpleUser | undefined],
    operator: [undefined as SimpleUser | undefined],
    scanned: [undefined as boolean | undefined],
  });
  public isOperateur$ = this.authService.isOperateur();

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly geoserverVariablesService: GeoserverVariablesService
  ) {
    this.authService.getUserStream().subscribe((user) => {
      if (user?.isOperateur()) {
        this.form.controls.operator.disable();
        this.patchOperator();
      } else {
        this.form.controls.operator.enable();
      }
    });
  }

  ngOnInit(): void {
    this.getOperators();
    this.getTechnicians();
    this.setupCqlFilterUpdate();
  }

  private setupCqlFilterUpdate() {
    const formatMail = (user?: User | SimpleUser | null) => (user && `'${user.email}'`) ?? '';
    this.form.controls.operator.valueChanges.subscribe((operator) => {
      this.geoserverVariablesService.interrascanFilterState.setParticularValue('operator', formatMail(operator));
    });
    this.form.controls.technician.valueChanges.subscribe((technician) => {
      this.geoserverVariablesService.interrascanFilterState.setParticularValue('technician', formatMail(technician));
    });
    this.form.controls.scanned.valueChanges.subscribe((scanned) => {
      this.geoserverVariablesService.interrascanFilterState.setParticularValue('scanned', String(scanned ?? ''));
    });
  }

  private getTechnicians() {
    return this.userService.getTechnicianUserForCurrentApplication(this.appId()).subscribe((users) => {
      if (users.length) {
        this.technicians = users.map((user) => {
          return {
            label: `${user.prenom} ${user.nom}`,
            value: user,
          };
        });
      }
    });
  }

  private getOperators() {
    return this.userService.getOperatorUserForCurrentApplication(this.appId()).subscribe((users) => {
      if (users.length) {
        this.operators = users.map((user) => {
          return {
            label: `${user.prenom} ${user.nom}`,
            value: user,
          };
        });
        this.patchOperator();
      }
    });
  }

  private patchOperator() {
    const email = this.authService.getUserEmailInSync();
    if (email) {
      const operator = this.operators.find((o) => o.value.email === email)?.value;
      this.form.controls.operator.patchValue(operator);
    }
  }
}

<div class="smv-dialog">
  <h1 mat-dialog-title class="unselectable">
    <span i18n="Attribute Editor">Paramétrage de l'attribut</span> {{ attribute.controls.name.value }}
  </h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="Valeur par défaut" labelClass="unselectable" i18n-label="Attribute Editor|Tab name">
        @if (!isBooleanAttribute) {
          <smv-attribute-default-values [config]="config" [attribute]="attribute" />
        } @else {
          <mat-slide-toggle
            labelPosition="before"
            [formControl]="attribute.controls.defaultBoolean"
            class="toggle unselectable"
            i18n="Attribute editor|Boolean default value"
          >
            Définir la valeur par défaut à :
          </mat-slide-toggle>
        }
      </mat-tab>
      @if (!isBooleanAttribute) {
        <mat-tab label="Contraintes" labelClass="unselectable" i18n-label="Attribute Editor|Tab name">
          @if (!isDateAttribute) {
            <smv-form-field-wrapper [formControl]="attribute.controls.min" type="number">
              @if (!isNumberAttribute) {
                <span mat-label i18n="Attribute Editor|Constraint">Longueur minimale</span>
              } @else {
                <span mat-label i18n="Attribute Editor|Constraint">Valeur minimale</span>
              }
            </smv-form-field-wrapper>

            <smv-form-field-wrapper [formControl]="attribute.controls.max" type="number">
              @if (!isNumberAttribute) {
                <span mat-label i18n="Attribute Editor|Constraint">Longueur maximale</span>
              } @else {
                <span mat-label i18n="Attribute Editor|Constraint">Valeur maximale</span>
              }
            </smv-form-field-wrapper>

            <smv-form-field-wrapper [formControl]="attribute.controls.pattern" hasHelp>
              <span mat-label i18n="Attribute Editor|Constraint">Structure de la valeur</span>
              <span i18n="Attribute Editor|Constraint help" help>
                Utilisé seulement si aucune valeur par défaut n'est définie
              </span>
            </smv-form-field-wrapper>
            <smv-form-field-wrapper [formControl]="attribute.controls.excludePattern" hasHelp>
              <span mat-label i18n="Attribute Editor|Constraint">Structure à exclure</span>
              <span i18n="Attribute Editor|Constraint help" help>
                Par exemple : [0-9] pour interdire l'usage des chiffres
              </span>
            </smv-form-field-wrapper>
            <smv-form-field-wrapper [formControl]="attribute.controls.excludeValue">
              <span mat-label i18n="Attribute Editor|Constraint">Valeur à exclure</span>
            </smv-form-field-wrapper>
          }

          @if (isDateAttribute) {
            <smv-form-field-wrapper [formControl]="attribute.controls.dateMin" type="date">
              <span mat-label i18n="Attribute Editor|Constraint">Date minimale</span>
            </smv-form-field-wrapper>
            <smv-form-field-wrapper [formControl]="attribute.controls.dateMax" type="date">
              <span mat-label i18n="Attribute Editor|Constraint">Date maximale</span>
            </smv-form-field-wrapper>
          }

          <smv-form-field-wrapper [formControl]="attribute.controls.dataSource" hasHelp [helpCloseAfter]="10000">
            <span mat-label i18n="Attibute Editor|Data Source">Source des données</span>
            <span i18n="Attribute Editor|Data Source help" help>
              Lien et attributs de valeur et d'affichage<br />
              Formatage : httpURL::Chemin::NomVariableControle::NomVariableAffichage<br />
              Note: utiliser "[]" pour itérer sur un tableau<br />
              exemple: $URL::[]::code::name
            </span>
          </smv-form-field-wrapper>

          <smv-form-field-wrapper [formControl]="attribute.controls.dataSourceDisplay" hasHelp>
            <span mat-label i18n="Attibute Editor|Data visualisation">Source des données pour visualisation</span>
            <span i18n="Attribute Editor|Data visualisation help" help>
              Lien pour récupérer la valeur d'affichage
            </span>
          </smv-form-field-wrapper>

          <mat-slide-toggle
            labelPosition="before"
            [formControl]="attribute.controls.nillable"
            class="toggle unselectable"
            i18n="Attribute Editor|Constraint"
          >
            Permettre une valeur nulle :
          </mat-slide-toggle>
        </mat-tab>
      }
      <mat-tab label="Configuration des dépendances" labelClass="unselectable" i18n-label="Attribute Editor|Tab name">
        <smv-attribute-compute-values [attributes]="attributes" [attribute]="attribute" />
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>

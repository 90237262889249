import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { Indexable } from '@core/utils/general.utils';
import { environment } from '@env/environment';
import { LegalNoticesComponent } from '@feature/legal-notices/legal-notices.component';
import { PrivacyPolicyComponent } from '@feature/privacy-policy/privacy-policy.component';

interface MenuOption {
  label: string;
  superAdminOnly?: boolean;
  adminOnly?: boolean;
  path?: string;
  popup?: ComponentType<unknown>;
  popupOptions?: Indexable<unknown>;
}

@Component({
  selector: 'smv-sidemenu',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule, MatListModule, RouterModule],
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent {
  public copyrightYear = new Date().getFullYear();
  public smvVersion = environment.version;
  public menuOptions: MenuOption[] = [
    { path: '/', label: $localize`:Sidenav:Accueil` },
    { path: '/applications', label: $localize`:Sidenav:Mes applications` },
    { path: '/administration/applications', label: $localize`:Sidenav:Gestion des applications`, adminOnly: true },
    { path: '/administration/utilisateurs', label: $localize`:Sidenav:Gestion des utilisateurs`, adminOnly: true },
    { path: '/administration/clients', label: $localize`:Sidenav:Gestion des clients`, adminOnly: true },
    { path: '/administration/groupesClient', label: $localize`:Sidenav:Gestion des groupes client`, adminOnly: true },
    { path: '/administration/prestataires', label: $localize`:Sidenav:Gestion des prestataires`, superAdminOnly: true },
    {
      path: '/administration/synchronisation',
      label: $localize`:Sidenav:Gestion de la synchronisation`,
      superAdminOnly: true,
    },
  ];
  public bottomMenuOptions: MenuOption[] = [
    {
      label: $localize`:Links|Link to CGU:CGU`,
      popup: LegalNoticesComponent,
    },
    {
      label: $localize`:Links|Link to privacy policy:Politique de confidentialité`,
      popup: PrivacyPolicyComponent,
    },
  ];
  public isAdmin$ = this.authService.isAdmin();
  public isSuperAdmin$ = this.authService.isSuperAdmin();

  @Output() closeSideNav = new EventEmitter<boolean>();

  constructor(
    public readonly authService: AuthService,
    private readonly applicationService: ApplicationApiService,
    private readonly dialog: MatDialog
  ) {
    this.applicationService.currentApplication.getStream().subscribe((app) => {
      this.bottomMenuOptions[0].popupOptions = { appId: app?.id };
    });
  }

  openPopup(menuItem: MenuOption): void {
    if (menuItem.popup) {
      this.dialog.open(menuItem.popup, { data: menuItem.popupOptions });
    }
  }

  sendCloseSideNav() {
    this.closeSideNav.emit(true);
  }
}

import { CommonModule } from '@angular/common';
import { Component, input, model, OnChanges, output, signal } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { OnlyNumberKeysDirective } from '@components/form/only-number-keys.directive';
import { ProfileCode } from '@core/model/right.model';
import { SimpleUser, User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { NotificationService } from '@core/services/notification.service';
import { UserService } from '@core/services/user.service';
import { InterraScanService } from '@widgets/interra-scan/interra-scan.service';
import { InterraScanCrop } from '@widgets/interra-scan/model/crop.model';
import { Farmer } from '@widgets/interra-scan/model/farmer.model';
import { InterraScanOrder } from '@widgets/interra-scan/model/order.model';
import { Package } from '@widgets/interra-scan/model/package.model';
import { Parcel } from '@widgets/interra-scan/model/parcel.model';
import { isNil } from 'lodash-es';
import { filter, forkJoin, mergeMap, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'smv-interra-scan-parcel-detail-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
    OnlyNumberKeysDirective,
  ],
  templateUrl: './parcel-detail-form.component.html',
  styleUrls: ['./parcel-detail-form.component.scss'],
})
export class ParcelDetailFormComponent implements OnChanges {
  appId = input.required<number>();
  parcel = model<Partial<Parcel>>();
  formInvalid = output<boolean>();

  public farmers: DropdownOption<Farmer>[] = [];
  public orders: DropdownOption<InterraScanOrder>[] = [];
  public crops: DropdownOption<InterraScanCrop>[] = [];
  public packages: DropdownOption<Package>[] = [];
  public users: DropdownOption<SimpleUser>[] = [];

  private DEFAULT_ID = 'default';

  public form = this.formBuilder.group({
    parcelName: [undefined as string | undefined, Validators.required],
    package: [undefined as Package | undefined, Validators.required],
    farmer: [undefined as Farmer | undefined, Validators.required],
    order: [undefined as InterraScanOrder | undefined],
    idealScanDate: [undefined as Date | undefined],
    currentCrop: [undefined as InterraScanCrop | undefined],
    nextCrop: [undefined as InterraScanCrop | undefined],
    harvestDate: [undefined as Date | undefined],
    sowingDate: [undefined as Date | undefined],
    physicalAccess: [undefined as string | undefined],
    operator: [undefined as SimpleUser | undefined],
    scanned: [false as boolean],
    scanTime: [undefined as number | undefined],
    actualSurface: [undefined as number | undefined],
  });

  public isOperator = signal(false);
  public isAdmin = signal(false);
  public user$ = this.authService.getUserStream().pipe(
    tap((currentUser) => {
      this.isOperator.set(!!currentUser?.isOperateur());
      this.isAdmin.set(!!this.checkUserAdmin(currentUser));
      if (this.isOperator() && !this.isAdmin()) {
        this.form.controls.currentCrop.disable();
        this.form.controls.farmer.disable();
        this.form.controls.harvestDate.disable();
        this.form.controls.idealScanDate.disable();
        this.form.controls.nextCrop.disable();
        this.form.controls.operator.disable();
        this.form.controls.order.disable();
        this.form.controls.package.disable();
        this.form.controls.parcelName.disable();
        this.form.controls.physicalAccess.disable();
        this.form.controls.sowingDate.disable();
      }
    })
  );
  private init = false;

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly interraScanService: InterraScanService,
    private readonly applicationService: ApplicationApiService,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    private readonly authService: AuthService
  ) {
    this.form.controls.farmer.valueChanges.subscribe((farmer) => {
      this.orders = [];
      if (farmer) {
        this.createOrderObs(farmer.id).subscribe();
      }
    });

    this.form.controls.order.valueChanges.subscribe((order) => {
      if (order) {
        this.form.patchValue({ idealScanDate: order.idealScanDate });
      }
    });

    this.form.valueChanges.pipe(filter(() => this.init)).subscribe(() => {
      this.formInvalid.emit(this.form.invalid);
      if (this.form.valid) {
        const formValues = this.form.getRawValue();
        const newOrder = formValues.order;
        if (newOrder) {
          newOrder.idealScanDate = formValues.idealScanDate;
          formValues.order = newOrder;
        }

        this.parcel.set({ ...this.parcel(), ...formValues });
      }
    });
  }

  ngOnChanges(): void {
    const parcel = this.parcel();
    if (!isNil(this.appId()) && !isNil(parcel) && !this.init) {
      if (!isNil(parcel.order)) {
        parcel.order.idObs = parcel.order.id!;
      }
      if (!isNil(parcel.id)) {
        this.form.patchValue(parcel);
      }
      const farmerObs = this.user$
        .pipe(
          mergeMap((user) =>
            user?.isOperateur() && !this.checkUserAdmin(user)
              ? of([parcel?.farmer])
              : this.interraScanService.retrieveAllFarmers(this.appId())
          )
        )
        .pipe(
          tap((farmers) => {
            if (farmers.length) {
              this.farmers = farmers
                .filter((farmer) => !!farmer)
                .map((farmer) => {
                  return {
                    label: `${farmer.firstName} ${farmer.lastName} (${farmer.email})`,
                    value: farmer,
                  };
                });
            } else {
              this.notificationService.error(
                $localize`:InterraScan|No farmer declared:Aucun agriculteur n'est déclaré pour votre structure. Veuillez en créer dans la gestion des agriculteurs.`
              );
            }
          })
        );

      const cropObs = this.interraScanService.retrieveAllCrops(this.appId()).pipe(
        tap((crops) => {
          if (crops.length) {
            this.crops = crops.map((crop) => {
              return {
                label: `${crop.label}`,
                value: crop,
              };
            });
          } else {
            this.notificationService.error(
              $localize`:InterraScan|No crop declared:Aucune culture n'est déclarée pour votre structure.`
            );
          }
        })
      );
      const packageObs = this.interraScanService.retrieveAllPackages().pipe(
        tap(
          (packages) =>
            (this.packages = packages.reverse().map((packageOffer) => {
              return {
                label: packageOffer.label,
                value: packageOffer,
              };
            }))
        )
      );

      const usersObs = this.userService.getOperatorUserForCurrentApplication(this.appId()).pipe(
        tap((users) => {
          if (users.length) {
            this.users = users.map((user) => {
              return {
                label: `${user.prenom} ${user.nom}`,
                value: user,
              };
            });
          }
        })
      );
      const getOperatorsForUser = this.user$.pipe(
        mergeMap((user) => (user?.isOperateur() && !this.checkUserAdmin(user) ? of([]) : usersObs))
      );
      const promises = [farmerObs, cropObs, packageObs, getOperatorsForUser];
      forkJoin(promises).subscribe({
        next: () => {
          if (!isNil(this.parcel)) {
            this.form.patchValue(parcel);
          }
        },
        error: () => this.notificationService.unknownServerError(),
      });
      this.init = true;
    }
  }

  private createOrderObs(farmerId: string): Observable<InterraScanOrder[]> {
    const orderObs = this.interraScanService.retrieveAllOrders(farmerId).pipe(
      tap((orders) => {
        this.orders = [{ label: $localize`:New order:Nouvelle commande`, value: { idObs: this.DEFAULT_ID } }];
        if (orders.length) {
          this.orders.push(
            ...orders.map((order) => {
              order.idObs = order.id!;
              return {
                label: `${order.name} (${order.year})`,
                value: order,
              };
            })
          );
        }
        // On retrouve la commande de la parcelle, si on n'a pas changé d'agriculteur
        const parcel = this.parcel();
        if (!isNil(parcel) && parcel.order && parcel.farmer && parcel.farmer.id == farmerId) {
          this.form.patchValue({ order: parcel.order });
        }
      })
    );
    return orderObs;
  }

  private checkUserAdmin(user?: User) {
    const currentApp = this.applicationService.currentApplication.getValue();
    return user?.syngenta || (currentApp && user?.canEdit(currentApp, ProfileCode.ADMIN));
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiMessage } from '@core/model/api-message.model';
import { User } from '@core/model/user.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/user';
  private readonly isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private readonly userInfo$ = new BehaviorSubject<User | undefined>(undefined);
  private readonly specifierUser$ = new BehaviorSubject<User | undefined>(undefined);
  private readonly specifierEmail$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    protected override http: HttpClient,
    private readonly router: Router
  ) {
    super(http);
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  isLoggedInSync(): boolean {
    return this.isLoggedIn$.getValue();
  }

  isOperateur(): Observable<boolean> {
    return this.userInfo$.asObservable().pipe(map((user) => user?.isOperateur() ?? false));
  }

  isAdmin(): Observable<boolean> {
    return this.userInfo$.asObservable().pipe(map((user) => user?.isAdmin() ?? false));
  }

  isAdminInSync(): boolean {
    return this.userInfo$.getValue()?.isAdmin() ?? false;
  }

  isSuperAdmin(): Observable<boolean> {
    return this.userInfo$.asObservable().pipe(map((user) => user?.isSuperAdmin() ?? false));
  }

  isSuperAdminInSync(): boolean {
    return this.userInfo$.getValue()?.isSuperAdmin() ?? false;
  }

  getUserEmail(): Observable<string> {
    return this.userInfo$.asObservable().pipe(map((user) => user?.email ?? ''));
  }

  getUserEmailInSync(): string | undefined {
    return this.specifierUser$.getValue()?.email ?? this.specifierEmail$.getValue() ?? this.userInfo$.getValue()?.email;
  }

  getSpecifierUserEmailInSync(): string | undefined {
    return this.specifierUser$.getValue()?.email ?? this.specifierEmail$.getValue();
  }

  getSessionAuthKey(): string | undefined {
    return this.userInfo$.getValue()?.sessionAuthKey;
  }

  getUser(): User | undefined {
    return this.userInfo$.getValue();
  }

  getUserStream(): Observable<User | undefined> {
    return this.userInfo$.asObservable();
  }

  isAuthorizedSimulationVVInSync(): boolean {
    return this.userInfo$.getValue()?.authorizedSimulationVV ?? false;
  }

  isAuthorizedSimulationIcareInSync(): boolean {
    return this.userInfo$.getValue()?.authorizedSimulationIcare ?? false;
  }

  logIn(email: string, password: string): Observable<User> {
    const formData = new FormData();
    formData.set('username', email);
    formData.set('password', password);
    return this.http.post<User>(environment.apiUrl + '/login', formData).pipe(
      tap((user) => {
        this.saveToStorage(user);
        this.isLoggedIn$.next(true);
        this.userInfo$.next(new User(user));
      })
    );
  }

  logOut(): void {
    this.http.post<{ success: boolean }>(environment.apiUrl + '/logout', {}).subscribe((response) => {
      if (response.success) {
        this.isLoggedIn$.next(false);
        this.userInfo$.next(undefined);
        this.resetSpecifierUser();
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigateByUrl('/connexion');
      }
    });
  }

  signUp(
    email: string,
    lastName: string,
    firstName: string,
    password: string,
    country: string,
    phone?: string | null
  ): Observable<unknown> {
    const body = {
      email,
      nom: lastName,
      prenom: firstName,
      phone,
      password,
      country,
    };
    return this.http.post(this.baseUrl + '/signup', body);
  }

  verifyToken(token: string): Observable<User> {
    return this.http.post<User>(this.baseUrl + '/checktoken', token);
  }

  forgottenPassword(email: string): Observable<unknown> {
    return this.http.post(this.baseUrl + '/askpassword', email);
  }

  resetPassword(token: string, password: string): Observable<unknown> {
    return this.http.post(this.baseUrl + '/initpassword', { token, password });
  }

  modifyPassword(email: string, oldPassword: string, newPassword: string): Observable<ApiMessage> {
    const request = {
      email: email,
      oldpassword: oldPassword,
      newpassword: newPassword,
    };

    return this.http.post<ApiMessage>(this.baseUrl + '/changepassword', request);
  }

  checkAuthenticationStatus(token?: string): Observable<boolean> {
    return this.whoAmI(token).pipe(
      map((user) => {
        let loggedIn = false;
        if (user) {
          this.saveToStorage(user);
          loggedIn = true;
        }
        this.userInfo$.next(user);
        this.isLoggedIn$.next(loggedIn);
        return loggedIn;
      }),
      catchError(() => {
        this.isLoggedIn$.next(false);
        this.userInfo$.next(undefined);
        return of(false);
      })
    );
  }

  whoAmI(token?: string): Observable<User> {
    let headers;
    if (token) {
      headers = new HttpHeaders().append('Authorization', token);
    }
    return this.pipeExtractUser(this.http.get<User>(this.baseUrl, { headers: headers }));
  }

  updateUser(id: number, nom: string, prenom: string, country: string, phone?: string): Observable<User> {
    const request = {
      nom,
      prenom,
      country,
      phone,
    };

    return this.pipeExtractUser(this.http.post<User>(this.baseUrl + '/' + id + '/update', request));
  }

  deleteUser(id: number): Observable<unknown> {
    return this.http.delete(this.baseUrl + '/' + id);
  }

  setSpecifierUser(email: string, appId: number, restrict = true): Observable<User | undefined> {
    return this.pipeExtractUser(
      this.http.get<User>(this.baseUrl + `/specifierUser/${email}/application/${appId}`)
    ).pipe(
      tap((user: User) => this.specifierUser$.next(user)),
      catchError(() => {
        this.specifierUser$.next(undefined);
        if (!restrict) {
          this.specifierEmail$.next(email);
        }
        return of(undefined);
      })
    );
  }

  resetSpecifierUser() {
    this.specifierUser$.next(undefined);
    this.specifierEmail$.next(undefined);
  }

  private saveToStorage(user: User): void {
    localStorage.setItem('user', user.email);
    localStorage.setItem('sessionTimestamp', Date.now().toString());
  }
}

import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, effect, input, model, OnInit, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GeneralUtils, KeysOfType } from '@core/utils/general.utils';
import { filter, ReplaySubject, startWith, tap } from 'rxjs';

@Component({
  selector: 'smv-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent<N extends string | number, T extends object | string> implements OnInit {
  public filtered: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  public searchControl: FormControl<string | null> = new FormControl<string | null>('');

  label = input('');
  placeholder = input('');
  hint = input<string>();
  identifier = input<KeysOfType<T, N>>();
  appearance = input<MatFormFieldAppearance>('fill');
  displayRow = input<(element: T) => string>(this.displayChip.bind(this));
  displayRowEm = input<(element: T) => string>(() => '');
  searchCondition = input<(search: string) => boolean>(() => true);
  filterFonction = input<(search: string) => T[]>();
  serverFilterFonction = input<(search: string, filtered: ReplaySubject<T[]>) => void>();
  disabled = input(false, { transform: booleanAttribute });
  maxSelected = input<number>(0);
  control = input<FormControl<T[]>>();

  selected = model<T[]>([]);
  selectedChange = output<T[]>();

  private duplicateFilter = new ReplaySubject<T[]>(1);

  constructor() {
    effect(() => (this.disabled() ? this.searchControl.disable() : this.searchControl.enable()));
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        tap(() => this.filtered.next([])),
        filter(GeneralUtils.isNotNull),
        filter((search) => this.searchCondition()(search))
      )
      .subscribe((search) => {
        if (typeof search === 'string') {
          const filter = this.filterFonction();
          const serverFilter = this.serverFilterFonction();
          if (filter) {
            this.filtered.next(filter(search));
          } else if (serverFilter) {
            serverFilter(search, this.duplicateFilter);
          }
        }
      });
    this.control()
      ?.valueChanges.pipe(startWith(this.control()?.getRawValue()))
      .subscribe((values) => {
        if (values) {
          this.selected.set([...values]);
        }
      });

    this.duplicateFilter.subscribe((options) => {
      const identifier = this.identifier();
      if (options.length && identifier) {
        const elements = this.selected().map((el) => el[identifier]);

        const filteredOptions = options.filter((o) => !elements.includes(o[identifier]));
        this.filtered.next(filteredOptions);
      } else {
        this.filtered.next(options);
      }
    });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.selected().includes(event.option.value)) {
      this.selected().push(event.option.value);
      if (this.maxSelected() && this.maxSelected() === this.selected().length) {
        this.searchControl.disable();
      }
      this.onAction();
    }
  }

  displayChip(element: T): string {
    const id = this.identifier();
    if (id) {
      return element[id] as string;
    }
    if (typeof element === 'string') {
      return element;
    }
    if (typeof element === 'object' && 'label' in element) {
      return element.label as string;
    }
    return '';
  }

  remove(element: T): void {
    this.searchControl.enable();
    const index = this.selected().indexOf(element);

    if (index >= 0) {
      this.selected().splice(index, 1);
      this.onAction();
    }
  }

  onAction() {
    this.selectedChange.emit(this.selected());
    this.control()?.patchValue(this.selected());
  }
}

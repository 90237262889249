@if (isAACFeature) {
  <smv-aac-feature-actions [feature]="selectedFeature" />
}

@if (selectedFeature && parser) {
  <form [formGroup]="parser.form" class="smv-form">
    @for (attribute of parser.attributes; track $index) {
      @switch (attribute.type) {
        @case (AttributeType.BOOLEAN) {
          <mat-slide-toggle [formControlName]="attribute.name" [smvReadOnlyToggle]="!attribute.editable">
            {{ attribute.label ?? attribute.name }}
          </mat-slide-toggle>
        }
        @default {
          @if (isDefaultDisplay(attribute)) {
            <smv-form-field-wrapper
              [formControlName]="attribute.name"
              [type]="getInputType(attribute.type)"
              [readonly]="!attribute.editable"
              [required]="!attribute.nillable"
              [hasAction]="!parser.form.disabled && attribute.editable"
              [hasHint]="isEditable() && attribute.configuration?.displayInitialValue"
              showEmptyWhenDisabled
            >
              <span mat-label>{{ attribute.label ?? attribute.name }}</span>
              <button mat-icon-button (click)="parser.form.controls[attribute.name].setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <span mat-hint>
                <span i18n="Edition|Initial Value">Valeur initiale : </span>
                {{ parser.savedForm.get(attribute.name)?.value }}
              </span>
            </smv-form-field-wrapper>
          } @else {
            @if (attribute.type === AttributeType.STRING_ARRAY) {
              @if (getArrayControl(attribute.name)) {
                <smv-multi-select
                  [label]="attribute.label ?? attribute.name"
                  [filterFonction]="getFilter(attribute.name)"
                  [control]="getArrayControl(attribute.name)!"
                  (selectedChange)="onSelectionChange($event, attribute.name)"
                />
              }
            } @else {
              <smv-form-field-wrapper
                [formControlName]="attribute.name"
                [type]="getInputType(attribute.type)"
                [controlType]="options[attribute.name]().length >= 10 ? 'autocomplete' : 'select'"
                [selectOptions]="options[attribute.name]()"
                [readonly]="!attribute.editable"
                [required]="isRequired(attribute)"
                [hasAction]="!parser.form.disabled && attribute.editable && getSelectOptions(attribute).length >= 15"
                [optionsInit]="attribute.configuration?.asyncFirstChecked === false || isSelectionChange"
                [hasHint]="isEditable() && attribute.configuration?.displayInitialValue"
                showEmptyWhenDisabled
              >
                <span mat-label>{{ attribute.label ?? attribute.name }}</span>
                <button mat-icon-button (click)="parser.form.controls[attribute.name].setValue(null)">
                  <mat-icon>close</mat-icon>
                </button>
                <span mat-hint>
                  <span i18n="Edition|Initial Value">Valeur initiale : </span>
                  {{ parser.savedForm.get(attribute.name)?.value }}
                </span>
              </smv-form-field-wrapper>
            }
          }
        }
      }
    }

    @if (isEditable()) {
      <div class="smv-sticky-bottom-actions edit-button">
        @if (canUpdateGeom) {
          <button
            mat-stroked-button
            (click)="updateGeom(parser.coordAttributes.long?.value!, parser.coordAttributes.lat?.value!)"
            matTooltip="Repositionne la carte selon les attributs spécifiés comme latitude et longitude. Le point apparaîtra quand vous enregistrerez les modifications"
            matTooltipPosition="left"
            i18n-matTooltip="Edition|Update geometry"
            i18n="Button"
          >
            Repositionner la géométrie
          </button>
        }
        <button mat-stroked-button (click)="resetModifications()" i18n="Button">Réinitialiser les attributs</button>
        <button
          mat-stroked-button
          (click)="confirmModifications()"
          [disabled]="parser.form.invalid"
          [ngClass]="{ btnSpinner: isSaving, loaded: isSaving }"
          i18n="Button"
        >
          Enregistrer les modifications
        </button>
      </div>
    }
  </form>
} @else {
  <div i18n>Veuillez sélectionner un objet</div>
}

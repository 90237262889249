import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  input,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormFieldErrorsComponent } from '@components/form-field-errors/form-field-errors.component';
import { ControlValueAccessorImpl } from '@core/form/control-value-accessor.impl';
import { OnlyNumberKeysDirective } from '../only-number-keys.directive';

@Component({
  selector: 'smv-phone-field',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormFieldErrorsComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    OnlyNumberKeysDirective,
  ],
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PhoneFieldComponent), multi: true }],
})
export class PhoneFieldComponent extends ControlValueAccessorImpl<string | null> implements AfterViewInit {
  public customLabel = input(false, { transform: booleanAttribute });
  public customToolTip = input<string>();
  public countryPrefix = input<string>('+33');
  public validatorPattern = input<RegExp>(/^\+33\d{9}$/);

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.registerFormControl(this.injector.get(NgControl));
    this.control?.addValidators(Validators.pattern(this.validatorPattern()));
    this.changeDetector.detectChanges();
  }

  computeValue(value: string | null) {
    if (!value || value === this.countryPrefix() || value === '+') {
      return null;
    }
    if (!value.startsWith(this.countryPrefix())) {
      if (value.startsWith('+')) {
        return null;
      }
      if (value.startsWith('0')) {
        return this.countryPrefix() + value.substring(1);
      }
      return this.countryPrefix() + value;
    }
    return value;
  }
}

<div class="toggle">
  <mat-slide-toggle [formControl]="legendDisplayControl" i18n="Layer legend"> Afficher la légende </mat-slide-toggle>
  <smv-help-overlay i18n="Layer legend|Help">
    Si cette option est activée, la légende de la couche apparaitra dans la légende:
    <ul>
      <li>si la couche est visible dans la carte</li>
      <li>si la fonction Légende est activée pour la carte (voir Fonctions)</li>
    </ul>
  </smv-help-overlay>
</div>

<ng-container *ngIf="legendDisplayControl.value && isWms">
  <smv-form-field-wrapper [formControl]="legendUrlControl" hasHelp>
    <span mat-label i18n="Layer base style">URL de la légende</span>
    <div help>
      <p>
        <span i18n="Layer legend|Help">La légende utilisée par défaut est celle générée par l'opération </span>
        <a target="_blank" href="https://docs.geoserver.org/latest/en/user/services/wms/get_legend_graphic/index.html">
          GetLegendGraphics
        </a>
        <span i18n="Layer legend|Help">
          du serveur WMS, vous pouvez remplacer cette légende par un fichier image publié sur une URL que vous pouvez
          indiquer ici.
        </span>
      </p>
      <p i18n="Layer legend|Help">Si ce champ est vide, l'url sera générée selon la configuration de la couche.</p>
    </div>
  </smv-form-field-wrapper>

  <div *ngIf="legendUrlControl.value && layer.config.useCustomSld" class="consistency-warning">
    <p i18n="Layer legend">
      Vous avez déclaré un style personnalisé pour cette couche. L'url fourni peut ne pas correspondre au style affiché.
    </p>
    <p i18n="Layer legend">
      Pour laisser l'application interpréter la légende, veuillez laisser le champ de l'url vide.
    </p>
  </div>

  <p class="legend-preview">
    <span i18n="Layer legend">Aperçu de la légende : </span>
    <smv-wms-legend [layer]="clonedLayer" />
  </p>

  <div class="legend-reload">
    <button mat-stroked-button (click)="reloadPreview()" i18n="Layer legend">Recharger l'aperçu</button>
  </div>
</ng-container>

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[smvOnlyNumberKeys]',
  standalone: true,
})
export class OnlyNumberKeysDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    return new RegExp(/\d/).test(event.key);
  }
}

import { TemplateRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { ApplicationModel } from '@core/model/application-api/application.model';
import { Customer } from '@core/model/customer.model';
import { Right } from '@core/model/right.model';
import { User } from '@core/model/user.model';
import { KeysOfType } from '@core/utils/general.utils';
import { ReplaySubject } from 'rxjs';

export interface DefaultType<N extends number | string> {
  id: N;
}

export class CellType {
  static readonly Checkbox = 'Checkbox';
  static readonly String = 'String';
  static readonly StringSmall = 'StringSmall';
  static readonly Date = 'Date';
  static readonly Boolean = 'Boolean';
  static readonly BooleanLabels = 'BooleanLabels';
  static readonly Img = 'Img';
  static readonly Visibility = 'Visibility';
  static readonly Select = 'Select';
  static readonly Action = 'Action';
  static readonly GeoJson = 'GeoJson';
  static readonly Template = 'Template';
  static readonly RowDetail = 'RowDetail';
}

export class ActionType {
  static readonly Router = 'Router';
  static readonly Modify = 'Modify';
  static readonly Delete = 'Delete';
  static readonly Default = 'Default';
  static readonly Menu = 'Menu';
  static readonly Link = 'Link';
}

export interface ChangeAction {
  application: ApplicationModel;
  customer: Customer;
  right: Right;
  user: User;
}

export interface SelectionAction<N> {
  identifiers: string[];
  ids: N[];
}

export interface DefaultAction {
  identifier: string;
  key: string;
}

export interface Task<T, N extends number | string> {
  selected: boolean;
  subtasks: Subtask<T, N>[];
}

export interface Subtask<T, N extends number | string> {
  id: N;
  identifier: string;
  selected: boolean;
  element: T;
}

export interface Action<T> {
  type: ActionType;
  key?: string;
  label?: string;
  link?: string;
  linkProperty?: keyof T;
  linkGetter?: (element: T) => string;
  icon?: string;
  tooltip: string;
  identifier: keyof T;
  hide?: boolean;
  hideCondition?: (element: T) => boolean;
  menu?: Action<T>[];
}

export interface Select<T, V> {
  identifier: KeysOfType<V, string>;
  valueExpr: KeysOfType<V, string | number>;
  list: V[];
  enableAutocomplete?: boolean;
  multiSelect?: MultiSelect<V>;
  disabled?: (element: T) => boolean;
  hideOptionCondition?: (element: T, option: V) => boolean;
  serverFilterOptions?: (search: string, filtered: ReplaySubject<DropdownOption<V>[] | V[]>) => void;
}

interface MultiSelect<V> {
  maxSelected: number;
  serverFilterOptions?: (search: string, filtered: ReplaySubject<V[]>) => void;
  filterOptions?: (search: string) => string[];
  displayRow: (element: V) => string;
  displayRowEm: (element: V) => string;
  searchCondition: (search: string) => boolean;
}

export interface GeneralActions<T> {
  tableOnly?: boolean;
  pageLayout?: boolean;
  stickyDisabled?: boolean;
  checkboxDisabled?: boolean;
  checkboxAllSelected?: boolean;
  hideCheckboxCondition?: (element: T) => boolean;
  refreshDisabled?: boolean;
  addDisabled?: boolean;
  addIcon?: string;
  addTooltip?: string;
  deleteSelectedDisabled?: boolean;
  deleteTooltip?: string;
  actionSelectedDisabled?: boolean;
  actionSelectedIcon?: string;
  actionSelectedTooltip?: string;
  rightActionDisabled?: boolean;
  rightActionIcon?: string;
  rightActionTooltip?: string;
  searchDisabled?: boolean;
  searchPlaceholder?: string;
  defaultSort?: Sort;
  propertiesSetter?: boolean;
  setterLabel?: string;
  setterText?: string;
  setterBtnLabel?: string;
}

export interface Column<T, V> {
  field: string;
  label: string;
  type: CellType;
  cellData: string | string[];
  cellDataSelect?: string;
  allowCellEmpty?: boolean;
  actions?: Action<T>[];
  select?: Select<T, V>;
  sort?: boolean;
  hide?: boolean;
  helpText?: string;
  cellDataTrue?: string;
  cellDataFalse?: string;
  iconVisibility?: boolean | ((row: T) => boolean);
  icon?: string | ((row: T) => string);
  dateFormat?: string;
  minWidth?: string;
  width?: string;
  editable?: boolean;
  editType?: string;
  template?: TemplateRef<unknown>;
  rowDetailTemplate?: TemplateRef<unknown>;
  dependsOn?: string;
}

export interface ColumnFilter<T> {
  columnFilterList: Set<string>;
  defaultOptionLabel: string;
  filterColumn: keyof T;
  selectedOption?: string;
  filterLabel?: string;
}

@if (control) {
  <mat-form-field
    [class]="{ empty: isEmpty, disabled: control.disabled, 'hide-empty': !showEmptyWhenDisabled() }"
    [subscriptSizing]="subscriptSizing()"
    [floatLabel]="floatLabel"
  >
    @if (!hideLabel()) {
      <mat-label class="unselectable">
        <ng-content select="[mat-label]" />
      </mat-label>
    }

    @switch (controlType()) {
      @case ('textarea') {
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="15"
          [required]="required()"
          [readonly]="readonly()"
          [formControl]="control"
        ></textarea>
      }
      @case ('select') {
        <mat-select [required]="required()" [formControl]="control" [compareWith]="compareByIdentifier">
          @if (!required()) {
            <mat-option [value]="null" i18n="Form Field|Reset select">Aucun</mat-option>
          }
          @for (option of formattedSelectOptions; track $index) {
            <mat-option [value]="option.value">
              {{ option.label }}
            </mat-option>
          }
        </mat-select>
      }
      @case ('autocomplete') {
        <input
          type="text"
          matInput
          [formControl]="control"
          [required]="required()"
          [matAutocomplete]="auto"
          (input)="autocompleteFilter(autocompleteInput.value)"
          #autocompleteInput
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn.bind(this)"
          [requireSelection]="requireSelection()"
        >
          @if (!required()) {
            <mat-option [value]="null" i18n="Form Field|Reset select">Aucun</mat-option>
          }
          @for (option of formattedSelectOptionsFiltered; track $index) {
            <mat-option [value]="option.value">
              {{ option.label }}
            </mat-option>
          }
        </mat-autocomplete>
      }
      @default {
        @switch (type()) {
          @case ('number') {
            <input
              matInput
              [required]="required()"
              type="number"
              [step]="step()"
              [readonly]="readonly()"
              [formControl]="control"
            />
          }
          @case ('date') {
            <input matInput [required]="required()" type="date" [readonly]="readonly()" [formControl]="control" />
          }
          @default {
            <input
              matInput
              [required]="required()"
              [type]="type()"
              [readonly]="readonly()"
              [formControl]="control"
              [placeholder]="placeholder()"
            />
          }
        }
      }
    }

    @if (hasHint()) {
      <mat-hint>
        <ng-content select="[mat-hint]" />
      </mat-hint>
    }

    <mat-error>
      <smv-form-field-errors [field]="control" [messages]="errorMessages()" />
    </mat-error>
    @if (hasHelp()) {
      <smv-help-overlay matSuffix i18n [closeAfter]="helpCloseAfter()">
        <ng-content select="[help]" />
      </smv-help-overlay>
    }

    @if (hasAction()) {
      <span matSuffix>
        <ng-content select="[mat-icon-button]" />
      </span>
    }
  </mat-form-field>
}

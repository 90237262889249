@if (control) {
  <mat-form-field
    subscriptSizing="dynamic"
    floatLabel="always"
    [matTooltip]="customToolTip() ?? ''"
    matTooltipPosition="left"
  >
    @if (customLabel()) {
      <mat-label class="unselectable"><ng-content select="[mat-label]" /></mat-label>
    } @else {
      <mat-label class="unselectable" i18n="Label|Phone Number">Numéro de téléphone</mat-label>
    }
    <input
      matInput
      type="tel"
      [ngModel]="value"
      (ngModelChange)="updateValue(computeValue($event))"
      [errorStateMatcher]="errorStateMatcher"
      smvOnlyNumberKeys
    />
    @if (countryPrefix() && !value?.startsWith('+')) {
      <span matTextPrefix>{{ countryPrefix() }}</span>
    }
    <mat-error>
      <smv-form-field-errors [field]="control" />
    </mat-error>
  </mat-form-field>
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagingInfo } from '@core/model/paging-info.model';
import { Position } from '@core/model/position.model';
import { CommonService } from '@core/services/common.service';
import { Observable } from 'rxjs';
import { Constraint } from './model/constraint.model';
import { Crop } from './model/crop.model';
import { Parcel } from './model/parcel.model';
import { Recommendation } from './model/recommendation.model';

@Injectable({ providedIn: 'root' })
export class OptiSemisService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/optiSemis';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getCrops() {
    return this.http.get<Crop[]>(`${this.baseUrl}/crops`);
  }

  getRecommendations() {
    return this.http.get<Recommendation[]>(`${this.baseUrl}/recommendations`);
  }

  getConstraintCodes() {
    return this.http.get<Constraint[]>(`${this.baseUrl}/constraintCodes`);
  }

  retrieveCreatedParcels(paging: PagingInfo) {
    return this.sendListQuery<Parcel>(paging, `${this.baseUrl}/parcels/created`, false);
  }

  retrieveAllCreatedParcels(paging: PagingInfo) {
    let params = new HttpParams();
    if (paging.filters.length) {
      params = paging.setPageFilters(params);
    }
    return this.http.get<Parcel[]>(`${this.baseUrl}/parcels/created/all`, { params });
  }

  getParcelPosition(parcelCode: string): Observable<Position> {
    return this.http.get<Position>(`${this.baseUrl}/position/${parcelCode}`);
  }
}

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const CUSTOM_ICONS = [
  'exploitation.svg',
  'calendar-plan.svg',
  'crop.svg',
  'crop-protection.svg',
  'leaf.svg',
  'person-circle.svg',
  'spraying.svg',
  'build-circle.svg',
];

export function registerIcons(registry: MatIconRegistry, sanitizer: DomSanitizer): void {
  CUSTOM_ICONS.forEach((icon) => {
    registry.addSvgIcon(
      'smv_' + icon.replace('.svg', '').replaceAll('-', '_'),
      // Relative to app.component.ts
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/' + icon)
    );
  });
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { User } from '@core/model/user.model';
import { environment } from '@env/environment';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {
  protected readonly baseUrl = environment.apiUrl + '/rest';

  constructor(protected http: HttpClient) {}

  deleteEntities(entities: (number | string)[]): Observable<unknown> {
    let params = new HttpParams();
    params = params.appendAll({ ids: entities });

    return this.http.delete(this.baseUrl, { params });
  }

  sendListQuery<T>(pagingInfo?: PagingInfo, url?: string, modifyUrl = true): Observable<PaginatedResult<T>> {
    let params = new HttpParams();
    if (!url) {
      url = this.baseUrl;
    }
    if (pagingInfo) {
      if (pagingInfo.filters.length) {
        if (modifyUrl) {
          url += '/search';
        }
        params = pagingInfo.setPageFilters(params);
      }
      params = pagingInfo.setPageParams(params);
    } else {
      pagingInfo = new PagingInfo();
    }

    return this.pipePaginated(this.http.get<PaginatedResult<T>>(url, { params }), pagingInfo);
  }

  pipePaginated<T>(obs: Observable<PaginatedResult<T>>, pagingInfo: PagingInfo): Observable<PaginatedResult<T>> {
    return obs.pipe(
      tap((result: PaginatedResult<T>) => {
        pagingInfo.nbTotalRes = result.page.totalElements;
      })
    );
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  protected pipeExtractUser(obs: Observable<User>): Observable<User> {
    return obs.pipe(map((result: User) => new User(result)));
  }
}

import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { ProfileCode } from '@core/model/right.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';

export const ProfileGuard = (profile: ProfileCode): CanMatchFn => {
  return () => {
    const authService = inject(AuthService);
    const appApi = inject(ApplicationApiService);
    const currentApp = appApi.currentApplication.getValue();
    const user = authService.getUser();
    return !!(currentApp && user?.canEdit(currentApp, profile));
  };
};

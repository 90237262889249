import { DisplayFilter } from '@widgets/components/filter-type-data/filter-type-data.component';

export interface IcareFilter {
  type?: string;
  model?: string;
}

export interface IcareFilterConfig {
  displayFilter: DisplayFilter;
  dataFilter: IcareFilter;
}
export enum InsectType {
  LOBESIA = 'Lobesia',
  CRYPTOBLABES = 'Cryptoblabes',
}

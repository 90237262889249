import { Routes } from '@angular/router';
import { ProfileGuard } from '@core/guards/profile.guard';
import { ProfileCode } from '@core/model/right.model';

export const champPropreRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'prefix' },
  {
    path: 'home',
    loadComponent: () =>
      import('./champ-propre-home/champ-propre-home.component').then((c) => c.ChampPropreHomeComponent),
  },
  {
    path: 'farm',
    loadComponent: () =>
      import('./champ-propre-farm/champ-propre-farm.component').then((c) => c.ChampPropreFarmComponent),
  },
  {
    path: 'evaluation',
    loadComponent: () =>
      import('./champ-propre-evaluation/champ-propre-evaluation.component').then(
        (c) => c.ChampPropreEvaluationComponent
      ),
  },
  {
    path: 'programs',
    loadComponent: () =>
      import('./champ-propre-program/champ-propre-program.component').then((c) => c.ChampPropreProgramComponent),
  },
  {
    path: 'forecast',
    loadComponent: () =>
      import('./champ-propre-forecast/champ-propre-forecast.component').then((c) => c.ChampPropreForecastComponent),
  },
  {
    path: 'interventions',
    loadComponent: () =>
      import('./champ-propre-intervention/champ-propre-intervention.component').then(
        (c) => c.ChampPropreInterventionComponent
      ),
  },
  // Admin routes
  {
    path: 'admin/crops',
    canMatch: [ProfileGuard(ProfileCode.ADMIN)],
    loadComponent: () =>
      import('./champ-propre-admin/crop-management/crop-management.component').then((c) => c.CropManagementComponent),
  },
  {
    path: 'admin/products',
    canMatch: [ProfileGuard(ProfileCode.ADMIN)],
    loadComponent: () =>
      import('./champ-propre-admin/product-management/product-management.component').then(
        (c) => c.ProductManagementComponent
      ),
  },
  {
    path: 'admin/adventitia',
    canMatch: [ProfileGuard(ProfileCode.ADMIN)],
    loadComponent: () =>
      import('./champ-propre-admin/adventitia-management/adventitia-management.component').then(
        (c) => c.AdventitiaManagementComponent
      ),
  },
  {
    path: 'admin/program-assignment',
    canMatch: [ProfileGuard(ProfileCode.ADMIN)],
    loadComponent: () =>
      import('./champ-propre-admin/programs-attribution/programs-attribution.component').then(
        (c) => c.ProgramsAttributionComponent
      ),
  },
  {
    path: 'admin/programs',
    canMatch: [ProfileGuard(ProfileCode.ADMIN)],
    loadComponent: () =>
      import('./champ-propre-admin/program-management/program-management.component').then(
        (c) => c.ProgramManagementComponent
      ),
  },
];

import { CommonModule } from '@angular/common';
import { Component, input, OnDestroy, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@core/services/auth.service';
import { SelectionService } from '@core/services/selection.service';
import { GeneralUtils } from '@core/utils/general.utils';
import { ZoomPositionComponent } from '@widgets/components/zoom-position/zoom-position.component';
import { FarmerStatsComponent } from './farmer-stats/farmer-stats.component';
import { InterraScanService } from './interra-scan.service';
import { DataParcel, ParcelDetailComponent } from './parcel-detail/parcel-detail.component';
import { ParcelFilterComponent } from './parcel-filter/parcel-filter.component';
import { ParcelStatsComponent } from './parcel-stats/parcel-stats.component';
import { ParcelSynthesisComponent } from './parcel-synthesis/parcel-synthesis.component';

import { isNil } from 'lodash-es';
import { Feature } from 'ol';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'smv-interra-scan',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    ZoomPositionComponent,
    ParcelFilterComponent,
  ],
  templateUrl: './interra-scan.component.html',
  styleUrls: ['./interra-scan.component.scss', '../../client-carto-app-panel.scss'],
})
export class InterraScanComponent implements OnDestroy {
  applicationId = input.required<number>();
  canEdit = input(false);
  panelHide = input(true);
  displayed = output();
  public labelZoom = $localize`:InterraScan:Code de la parcelle`;
  public isOperateur$ = this.authService.isOperateur();

  private readonly rpgLayerCode = 'rpgLayer';
  private subscriptions = new Subscription();

  constructor(
    private readonly interraScanService: InterraScanService,
    private readonly selectionService: SelectionService,
    private readonly dialogModule: MatDialog,
    private readonly authService: AuthService
  ) {
    this.subscriptions.add(
      this.selectionService.selectedFeatures
        .getStream()
        .pipe(filter(() => this.interraScanService.activeDetailPopup.getValue()))
        .pipe(filter(GeneralUtils.isNotNull))
        .subscribe((selections) => {
          const filtered = selections.filter((selection) => selection.layer.config.code === this.rpgLayerCode);
          if (filtered.length && filtered[0].features.length) {
            this.openParcelDetail(filtered[0].features[0]);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPosition = (parcelId: string) => this.interraScanService.retrieveParcelPosition(parcelId);

  openFarmerStats() {
    this.dialogModule.open(FarmerStatsComponent, {
      data: {
        appId: this.applicationId(),
      },
    });
  }

  openParcelStats() {
    this.dialogModule.open(ParcelStatsComponent, {
      data: {
        appId: this.applicationId(),
      },
    });
  }

  openParcelSynthesis() {
    this.dialogModule.open(ParcelSynthesisComponent, {
      data: {
        appId: this.applicationId(),
      },
    });
  }

  private openParcelDetail(parcel: Feature) {
    const parcelId = parcel.get('id_parcel');
    if (!isNil(parcel.getGeometry()) && !isNil(parcelId)) {
      const data: DataParcel = {
        appId: this.applicationId(),
        parcel: {
          parcelId,
          surface: parcel.get('surf_parc'),
        },
      };
      this.dialogModule.open(ParcelDetailComponent, { data });
    }
  }
}

import { Directive, ElementRef, input, OnInit, Renderer2 } from '@angular/core';
import { default as tBbox } from '@turf/bbox';
import { BBox } from 'geojson';
import { GeoJSON2SVG } from 'geojson2svg';
import { GeoJSONObject } from 'ol/format/GeoJSON';

@Directive({
  selector: '[fromGeoJson]',
  standalone: true,
})
export class FromGeoJsonDirective implements OnInit {
  fromGeoJson = input.required<GeoJSONObject>();
  size = input.required<number>();

  private readonly path: SVGPathElement;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {
    this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.el.nativeElement.appendChild(this.path);
  }

  ngOnInit(): void {
    this.draw();
  }

  private draw() {
    const converter = new GeoJSON2SVG({
      mapExtent: extentToExtentObj(tBbox(this.fromGeoJson())),
      output: 'path',
      viewportSize: { width: this.size(), height: this.size() },
    });
    this.renderer.setAttribute(this.path, 'd', converter.convert(this.fromGeoJson()).join(''));
    this.renderer.setAttribute(this.el.nativeElement, 'viewBox', bboxToViewBox(this.path.getBBox()));
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'opacity 0.20s ease-in-out');
    this.renderer.setStyle(this.path, 'fill', 'none');
    this.renderer.setStyle(this.path, 'stroke', 'black');
    this.renderer.setStyle(this.path, 'stroke-width', '0.1');
  }
}

function extentToExtentObj(bbox: BBox) {
  return {
    left: bbox[0],
    bottom: bbox[1],
    right: bbox[2],
    top: bbox[3],
  };
}

function bboxToViewBox(bbox: DOMRect) {
  return `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
}

<mat-chip-grid #chipGrid>
  @for (element of selected(); track element) {
    <mat-chip (removed)="remove(element)">
      {{ displayChip(element) }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  }
</mat-chip-grid>

<mat-form-field class="vv-customer-filter" subscriptSizing="dynamic">
  <mat-label>{{ label() }}</mat-label>
  <input
    #inputIdForReset
    matInput
    type="text"
    [placeholder]="placeholder()"
    [formControl]="searchControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
  />
  @if (hint()) {
    <mat-hint>{{ hint() }}</mat-hint>
  }
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event); inputIdForReset.value = ''">
    @for (element of filtered | async; track element) {
      <mat-option [value]="element">
        {{ displayRow()(element) }} <em>{{ displayRowEm()(element) }}</em>
      </mat-option>
    }
  </mat-autocomplete>

  <div matSuffix>
    <ng-content slot="[suffix]" />
  </div>
</mat-form-field>

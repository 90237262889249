<div class="smv-dialog">
  <h1 mat-dialog-title i18n="Edition Validation|Feedback dialog title">Contenu du mail à {{ userEmail }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <smv-form-field-wrapper
      [formControl]="feedback"
      controlType="textarea"
      subscriptSizing="dynamic"
      hideLabel
      class="feedback-field"
    />
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="feedback.value" i18n="Button">Valider</button>
  </div>
</div>

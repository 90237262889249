import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '@core/services/common.service';
import { Observable } from 'rxjs';
import { Cultivation } from '../model/cultivation.model';

@Injectable({ providedIn: 'root' })
export class CultivationService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/cultivations';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getAll(): Observable<Cultivation[]> {
    return this.http.get<Cultivation[]>(this.baseUrl);
  }

  getByCodes(codes: string[]): Observable<Cultivation[]> {
    return this.http.post<Cultivation[]>(this.baseUrl, codes);
  }
}

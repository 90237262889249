<div class="smv-form">
  @for (parameter of widget.parameters; track $index) {
    @switch (parameter.id) {
      @case (ParameterId.DISPLAY_PROJ) {
        <smv-form-field-wrapper [formControl]="displayProj">
          <span mat-label i18n="Label|Projection">Projection</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.FORMAT) {
        <smv-form-field-wrapper [formControl]="format">
          <span mat-label i18n="Label|Format">Format</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.DIGIT_NUMBER) {
        <smv-form-field-wrapper type="number" [formControl]="digitNumber">
          <span mat-label i18n="Label|Significant digits">Nombre de chiffres significatifs</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.RESULT_COUNT) {
        <smv-form-field-wrapper type="number" [formControl]="resultCount">
          <span mat-label i18n="Label|Result count">Nombre de résultats</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.ZOOM) {
        <smv-form-field-wrapper type="number" [formControl]="zoom">
          <span mat-label i18n="Label|Zoom level">Zoom</span>
        </smv-form-field-wrapper>
      }
      <!--@case (ParameterId.LAYERNAME) {
        <smv-form-field-wrapper [formControl]="layername">
          <span mat-label i18n="Label|Layername">Nom des couches liées au widget</span>
        </smv-form-field-wrapper>
      }-->
      @case (ParameterId.STEP_DURATION) {
        <smv-form-field-wrapper type="number" [step]="100" [formControl]="stepDuration">
          <span mat-label i18n="Label|Step duration">Durée de chaque étape(en ms)</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.PERIODICITY) {
        <smv-form-field-wrapper
          controlType="select"
          [selectOptions]="PeriodicityOptions"
          [formControl]="periodicity"
          required
          hasHelp
        >
          <span mat-label i18n="Label|Periodicity">Périodicité</span>
          <span i18n="Widget Parameter|Help" help>Détermine le pas de temps</span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.START_DATE) {
        <smv-form-field-wrapper [formControl]="startDate" [helpCloseAfter]="15000" hasHelp>
          <span mat-label i18n="Label|Date begin">Date de début</span>
          <span i18n="Widget Parameter|Help" help>
            <p>
              Date de début au format YYYY-MM-JJ. On peut aussi l'exprimer par rapport à la date du jour: pour cela il
              faut indiquer une expression du type 'now+X' ou 'now-X', now étant la date du jour et X le nombre de
              périodes.
            </p>
            <p>
              Ex : pour avoir les 4 dernières semaines, on indiquera 'now-4' si la périodicité est Semaines ou 'now-28'
              si la périodicité est Jours.
            </p>
          </span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.END_DATE) {
        <smv-form-field-wrapper [formControl]="endDate" [helpCloseAfter]="15000" [errorMessages]="customErrors" hasHelp>
          <span mat-label i18n="Label|Date end">Date de fin</span>
          <span i18n="Widget Parameter|Help" help>
            <p>
              Date de fin au format YYYY-MM-JJ. On peut aussi l'exprimer par rapport à la date du jour: pour cela il
              faut indiquer une expression du type 'now+X' ou 'now-X', now étant la date du jour et X le nombre de
              périodes.
            </p>
            <p>
              Ex : pour avoir les 4 dernières semaines, on indiquera 'now-4' si la périodicité est Semaines ou 'now-28'
              si la périodicité est Jours.
            </p>
          </span>
        </smv-form-field-wrapper>
      }
      @case (ParameterId.GRADUATION) {
        <mat-slide-toggle [formControl]="displayGraduation" i18n="Widget Parameter">
          Afficher les graduations
        </mat-slide-toggle>
      }
    }
  }
</div>

<div class="smv-sticky-bottom-actions">
  <button mat-stroked-button (click)="closeParametersPanel.emit()">
    <mat-icon>arrow_back</mat-icon>
    <span i18n="Button">Revenir</span>
  </button>
  <button mat-flat-button color="primary" [disabled]="parametersForm.invalid" (click)="applyParametersChanges()">
    <mat-icon>app_registration</mat-icon>
    <span i18n="Button">Appliquer</span>
  </button>
</div>

<form [formGroup]="formFields" class="smv-form" [class]="{ readonly: readonly }">
  <!-- Common configuration available for all layers -->
  <div class="section-title" i18n>Informations générales sur la couche</div>

  <smv-form-field-wrapper formControlName="shortName">
    <span mat-label i18n="Layer editor">Nom de la couche</span>
  </smv-form-field-wrapper>

  <smv-form-field-wrapper formControlName="code" hasHelp>
    <span mat-label i18n="Layer editor">Code</span>
    <span i18n help>
      Code unique optionnel identifiant la couche pour le fonctionnement de certaines fonctionnalités
    </span>
  </smv-form-field-wrapper>

  <smv-form-field-wrapper formControlName="description" controlType="textarea">
    <span mat-label i18n="Layer editor">Description</span>
  </smv-form-field-wrapper>

  <smv-form-field-wrapper
    class="select-field"
    formControlName="geometryType"
    controlType="select"
    [selectOptions]="geometryTypes"
    required
  >
    <span mat-label i18n="Layer editor">Type de géométrie</span>
  </smv-form-field-wrapper>

  @if (!readonly) {
    <div class="edit-slider-group">
      <mat-slide-toggle formControlName="editable" i18n="Layer editor">Couche éditable</mat-slide-toggle>
      <smv-help-overlay i18n>
        Si cette option est activée, la couche sera éditable par un utilisateur ayant le profil éditeur sur cette
        application
      </smv-help-overlay>
    </div>
  }

  @if (formFields.value.editable) {
    <smv-form-field-wrapper
      class="select-field"
      formControlName="editType"
      controlType="select"
      [selectOptions]="editableProperties"
      required
    >
      <span mat-label i18n="Layer editor">Éléments éditables</span>
    </smv-form-field-wrapper>
  }

  <!-- Type-specific configuration -->
  <div class="section-title">{{ specificConfigSectionName }}</div>

  @if (formFields.controls.geoserverLayerName) {
    <smv-form-field-wrapper formControlName="geoserverLayerName">
      <span mat-label i18n="Layer editor">Nom de la couche GeoServer</span>
    </smv-form-field-wrapper>
  }

  @if (formFields.controls.url) {
    <smv-form-field-wrapper formControlName="url" hasHelp>
      <span mat-label i18n="Layer editor">URL du serveur</span>
      <span i18n help>URL complète vers les <strong>« Capabilities »</strong> du service à utiliser</span>
    </smv-form-field-wrapper>
  }

  @if (formFields.controls.maxFeatures) {
    <smv-form-field-wrapper formControlName="maxFeatures" type="number" hasHelp>
      <span mat-label i18n="Layer editor">Nombres d'entités maximum par requête</span>
      <span i18n help>Nombre d'entités maximum par requête reçu depuis le geoserver</span>
    </smv-form-field-wrapper>
  }

  @if (formFields.controls.geojsonUrl) {
    <smv-form-field-wrapper formControlName="geojsonUrl" hasHelp>
      <span mat-label i18n="Layer editor">URL</span>
      <span i18n help>URL complète vers le fichier GeoJSON</span>
    </smv-form-field-wrapper>
  }

  @if (formFields.controls.cqlFilter) {
    <smv-form-field-wrapper formControlName="cqlFilter" hasHelp>
      <span mat-label i18n="Layer editor">Filtre CQL</span>
      <span i18n help>Filtre CQL appliqué sur la source de données</span>
    </smv-form-field-wrapper>
  }

  @if (formFields.controls.cqlCustomerAttribute) {
    <smv-form-field-wrapper formControlName="cqlCustomerAttribute" hasHelp>
      <span mat-label i18n="Layer editor">Attribut client</span>
      <span i18n help>Nom de l'attribut client</span>
    </smv-form-field-wrapper>
  }

  <smv-form-field-wrapper formControlName="copyright" hasHelp>
    <span mat-label i18n="Layer editor">Copyright</span>
    <span i18n help>Copyright affiché en bas de la carte lorsque la couche est visible</span>
  </smv-form-field-wrapper>

  @if (formFields.value.copyright?.length) {
    <smv-form-field-wrapper formControlName="copyrightLink" hasHelp>
      <span mat-label i18n="Layer editor">Lien associé au copyright</span>
      <span i18n help>Lien vers le copyright affiché en bas de la carte lorsque la couche est visible</span>
    </smv-form-field-wrapper>
  }

  <!-- Layer BBOX (EPSG:4326) -->
  @if (showScaleSection) {
    <div class="section-title">
      <span i18n="Layer editor|Scale">Visibilité dépendante de l'échelle</span>
      <smv-help-overlay i18n="Layer editor|Scale">Format : 100000 (1:100000)</smv-help-overlay>
    </div>
    <span>
      <ng-container i18n="Layer editor|Scale">Échelle actuelle de la carte :</ng-container>
      {{ mapScale }}
    </span>
    <div class="scale-group">
      <smv-form-field-wrapper formControlName="minScaleDenominator" type="number" hasAction>
        <span mat-label i18n="Layer editor|Scale">Échelle min</span>
        <button
          mat-icon-button
          matTooltip="Utiliser l'échelle actuelle comme échelle minimale"
          i18n-matTooltip="Layer editor|Scale"
          (click)="fillMinResolution()"
        >
          <mat-icon>map</mat-icon>
        </button>
      </smv-form-field-wrapper>

      <smv-form-field-wrapper formControlName="maxScaleDenominator" type="number" hasAction>
        <span mat-label i18n="Layer editor|Scale">Échelle max</span>
        <button
          mat-icon-button
          matTooltip="Utiliser l'échelle actuelle comme échelle maximale"
          i18n-matTooltip="Layer editor|Scale"
          (click)="fillMaxResolution()"
        >
          <mat-icon>map</mat-icon>
        </button>
      </smv-form-field-wrapper>
    </div>
    @if (formFields.errors?.['invalidScales']) {
      <mat-error>
        <smv-form-field-errors [form]="formFields" [messages]="formErrors" />
      </mat-error>
    }
  }

  <!-- Layer BBOX (EPSG:4326) -->
  @if (showExtentSection) {
    <div class="section-title">
      <span i18n>Emprise de la couche (WGS84)</span>
      <smv-help-overlay i18n>Emprise de la couche avec coordonnées au format WGS84 - EPSG:4326</smv-help-overlay>
    </div>
    <div class="layer-extent-fields">
      <div class="coordinate-group">
        <smv-form-field-wrapper formControlName="extentMinX" type="number" [errorMessages]="extentXErrors">
          <span mat-label i18n="Layer editor|Extent coordinate">Min X</span>
        </smv-form-field-wrapper>

        <smv-form-field-wrapper formControlName="extentMinY" type="number" [errorMessages]="extentYErrors">
          <span mat-label i18n="Layer editor|Extent coordinate">Min Y</span>
        </smv-form-field-wrapper>
      </div>
      <div class="coordinate-group">
        <smv-form-field-wrapper formControlName="extentMaxX" type="number" [errorMessages]="extentXErrors">
          <span mat-label i18n="Layer editor|Extent coordinate">Max X</span>
        </smv-form-field-wrapper>

        <smv-form-field-wrapper formControlName="extentMaxY" type="number" [errorMessages]="extentYErrors">
          <span mat-label i18n="Layer editor|Extent coordinate">Max Y</span>
        </smv-form-field-wrapper>
      </div>
    </div>
    @if (formFields.errors?.['invalidCoordinates'] || formFields.errors?.['incompleteExtent']) {
      <mat-error>
        <smv-form-field-errors [form]="formFields" [messages]="formErrors" />
      </mat-error>
    }
  }
</form>

@if (selectedFeature) {
  <smv-panel [title]="title">
    @for (attribute of generalInfos; track $index) {
      <smv-form-field-wrapper [formControl]="attribute.control" [type]="attribute.type" readonly showEmptyWhenDisabled>
        <span mat-label>{{ attribute.label }}</span>
      </smv-form-field-wrapper>
    }
    @if (comment.value) {
      <smv-form-field-wrapper [formControl]="comment" controlType="textarea" readonly showEmptyWhenDisabled>
        <span mat-label i18n="Edition Validation">Commentaire</span>
      </smv-form-field-wrapper>
    }

    @if (areAttrModif && parser) {
      <smv-panel [title]="titleAttributes" [expanded]="false">
        <mat-slide-toggle class="unselectable" [formControl]="onlyModifAttr" i18n="Edition Validation">
          Voir uniquement les attributs modifiés
        </mat-slide-toggle>
        <form [formGroup]="parser.form" class="smv-form valid-attr-form">
          @for (attribute of onlyModifAttr.value ? modifAttributes : attributes; track $index) {
            @switch (attribute.type) {
              @case (AttributeType.BOOLEAN) {
                <mat-slide-toggle [formControlName]="attribute.name" [smvReadOnlyToggle]="true">
                  {{ attribute.label ?? attribute.name }}
                </mat-slide-toggle>
              }
              @default {
                <smv-form-field-wrapper
                  [formControlName]="attribute.name"
                  [type]="getInputType(attribute.type)"
                  readonly
                  showEmptyWhenDisabled
                  [hasHint]="!!originalFeatureProperties"
                >
                  <span mat-label>{{ attribute.label ?? attribute.name }}</span>
                  @if (originalFeatureProperties) {
                    <span mat-hint>
                      <span i18n="Edition Validation|Initial Value">Valeur initiale : </span>
                      {{ originalFeatureProperties[attribute.name] }}
                    </span>
                  }
                </smv-form-field-wrapper>
              }
            }
          }
        </form>
      </smv-panel>
    }
  </smv-panel>
}

import { NgClass } from '@angular/common';
import { Component, OnInit, signal, viewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { registerIcons } from '@core/configuration/icons.config';
import { CustomLayerStyle } from '@core/model/layer-style.model';
import { AuthService } from '@core/services/auth.service';
import { ServerStatusService } from '@core/services/server-status.service';
import { TemplateUtils } from '@core/utils/template.utils';
import { AnonymousFooterComponent } from '@layout/anonymous-footer/anonymous-footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { SidemenuComponent } from '@layout/sidemenu/sidemenu.component';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  selector: 'smv-root',
  imports: [AnonymousFooterComponent, HeaderComponent, MatSidenavModule, NgClass, RouterModule, SidemenuComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public readonly drawer = viewChild(MatDrawer);

  public readonly hasPageBackground = signal(false);
  public readonly isNoHeaderFooter = signal(false);

  public isLoggedIn = false;
  public isBackendUp = true;

  constructor(
    private readonly authService: AuthService,
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly router: Router,
    private readonly serverStatusService: ServerStatusService
  ) {}

  ngOnInit(): void {
    registerIcons(this.matIconRegistry, this.domSanitizer);

    // Use router events rather than the "currentApplication" ContextItem so that
    // there's no background flashing when the app is loaded
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.hasPageBackground.set(!event.url.startsWith('/applications/'));
    });

    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      if (!this.isLoggedIn) {
        this.drawer()?.close();
      }
    });
    TemplateUtils.noHeaderFooter.getStream().subscribe((value) => this.isNoHeaderFooter.set(value));
    this.serverStatusService.serverStatus.getStream().subscribe((value) => (this.isBackendUp = value != 'DOWN'));
    CustomLayerStyle.generateDefaultStyle();
  }
}

import { Injectable } from '@angular/core';
import { SimpleUser, User } from '@core/model/user.model';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class UserService extends CommonService {
  protected override baseUrl: string = this.baseUrl + '/user';

  getFarmersOfTechnicianAndCustomer(technicianMail: string, appId: number, customerCode: string) {
    const params = { technicianMail, customerCode, appId };
    return this.http.get<User[]>(this.baseUrl + `/farmers`, { params });
  }

  getTechnicianUserForCurrentApplication(appId: number): Observable<SimpleUser[]> {
    return this.http.get<SimpleUser[]>(`${this.baseUrl}/technicians`, { params: { appId } });
  }

  getOperatorUserForCurrentApplication(appId: number): Observable<SimpleUser[]> {
    return this.http.get<SimpleUser[]>(`${this.baseUrl}/operators`, { params: { appId } });
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureOption } from '@assets/applications/applications';
import { Customer } from '@core/model/customer.model';
import { User } from '@core/model/user.model';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/customer';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getCustomersWithActiveTraps(appId: number): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl + `/activeTraps/${appId}`);
  }

  searchCustomer(filterRequest: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl + `/search/ByNameOrCode/${filterRequest}`);
  }

  searchCustomersOfApp(appType: FeatureOption, filterRequest: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl + `/search/${appType.id}/ByNameOrCode/${filterRequest}`);
  }

  getCustomersWithSamples(appId: number, pastWeekCount: number, useSendDate: boolean): Observable<Customer[]> {
    const params = new HttpParams().set('useSendDate', useSendDate);
    return this.http.get<Customer[]>(this.baseUrl + `/samples/pastWeek/${pastWeekCount}/${appId}`, { params });
  }

  getMyCustomers(appId: number, withPublic = true, allowAllForSyngenta = true) {
    const params = { withPublic, appId, allowAllForSyngenta };
    return this.http.get<Customer[]>(this.baseUrl + `/ofUser`, { params });
  }

  getTechniciansOfCustomer(customerCode: string, appId: number) {
    const params = { customerCode, appId };
    return this.http.get<User[]>(this.baseUrl + `/technicians`, { params });
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { AttributeType, LayerProperty } from '@core/model/application-api/layer.model';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { LayerService } from '@core/services/layer.service';
import { Indexable } from '@core/utils/general.utils';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { LayerAttributeEditionComponent } from '../attribute-edition/attribute-edition.component';

import { isNil } from 'lodash-es';
import { debounce, interval } from 'rxjs';

export interface AttributeForm {
  name: FormControl<string>;
  label: FormControl<string | null>;
  type: FormControl<AttributeType | null>;
  editable: FormControl<boolean | null>;
  display: FormControl<boolean | null>;
  nillable: FormControl<boolean | null>;
  defaultValues: FormControl<Indexable<string[]>>;
  defaultBoolean: FormControl<boolean>;
  min: FormControl<number | null>;
  max: FormControl<number | null>;
  dateMin: FormControl<Date | null>;
  dateMax: FormControl<Date | null>;
  pattern: FormControl<string | null>;
  excludePattern: FormControl<string | null>;
  excludeValue: FormControl<string | null>;
  dependsOn: FormControl<string | null>;
  dataSource: FormControl<string | null>;
  dataSourceDisplay: FormControl<string | null>;
  displayInitialValue: FormControl<boolean | null>;
}

@Component({
  selector: 'smv-layer-attribute-tab',
  templateUrl: './layer-attribute-tab.component.html',
  styleUrls: ['./layer-attribute-tab.component.scss'],
})
export class LayerAttributeTabComponent implements OnInit {
  @Input() layer!: DataLayerConfig;
  @Input() readonly = false;
  @Output() propertyUpdate = new EventEmitter<LayerProperty[] | undefined>();

  @ViewChild(MatTable) table?: MatTable<FormGroup<AttributeForm>>;

  public isSortingAttributes = false;

  public readonly columns = ['name', 'label', 'type', 'visible', 'editable', 'actions'];
  public readonly attributeTypeOptions = [
    { label: $localize`:Layer attribute form|Attribute type:Caractères`, value: AttributeType.STRING },
    { label: $localize`:Layer attribute form|Attribute type:Booléen`, value: AttributeType.BOOLEAN },
    { label: $localize`:Layer attribute form|Attribute type:Entier`, value: AttributeType.INT },
    { label: $localize`:Layer attribute form|Attribute type:Double`, value: AttributeType.DOUBLE },
    { label: $localize`:Layer attribute form|Attribute type:Nombre`, value: AttributeType.NUMBER },
    { label: $localize`:Layer attribute form|Attribute type:Date`, value: AttributeType.DATE },
    { label: $localize`:Layer attribute form|Attribute type:DateTime`, value: AttributeType.DATE_TIME },
    { label: $localize`:Layer attribute form|Attribute type:StringArray`, value: AttributeType.STRING_ARRAY },
  ];
  public attributeFieldGroups = new FormArray<FormGroup<AttributeForm>>([]);

  constructor(
    private layerService: LayerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.layer.config.properties || !this.layer.config.properties.length) {
      this.reloadAttributes();
    } else if (this.layer.config.properties) {
      this.layer.config.properties.forEach((attribute) => this.addAttributeForm(attribute));
    }
    this.emitAttributes();

    if (this.readonly) {
      this.columns.pop();
      this.attributeFieldGroups.disable();
    }

    this.attributeFieldGroups.valueChanges.pipe(debounce(() => interval(500))).subscribe(() => this.emitAttributes());
  }

  reloadAttributes() {
    this.attributeFieldGroups.clear();
    this.layerService.getFeaturesTypes(this.layer).subscribe((featureProperties) => {
      if (featureProperties.properties.length) {
        featureProperties.properties.forEach((attribute) => {
          attribute.display = true;
          attribute.editable = true;
          this.addAttributeForm(attribute);
        });
      }
    });
    this.table?.renderRows();
  }

  toggleSortAttributes(state: boolean) {
    this.isSortingAttributes = state;
    this.readonly = state;
    if (state) {
      this.columns.pop();
      this.attributeFieldGroups.disable();
    } else {
      this.columns.push('actions');
      this.attributeFieldGroups.enable();
    }
  }

  addAttribute(): void {
    this.addAttributeForm();
    this.table?.renderRows();
  }

  removeAttribute(index: number): void {
    this.attributeFieldGroups.removeAt(index);
    this.table?.renderRows();
  }

  private addAttributeForm(attribute?: LayerProperty): void {
    const form: FormGroup<AttributeForm> = new FormGroup({
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      label: new FormControl<string | null>(null),
      type: new FormControl<AttributeType | null>(AttributeType.STRING, Validators.required),
      editable: new FormControl<boolean | null>(true),
      display: new FormControl<boolean | null>(true),
      nillable: new FormControl<boolean | null>(true),
      defaultValues: new FormControl<Indexable<string[]>>({}, { nonNullable: true }),
      defaultBoolean: new FormControl<boolean>(false, { nonNullable: true }),
      min: new FormControl<number | null>(null),
      max: new FormControl<number | null>(null),
      dateMin: new FormControl<Date | null>(null),
      dateMax: new FormControl<Date | null>(null),
      pattern: new FormControl<string | null>(null),
      excludePattern: new FormControl<string | null>(null),
      excludeValue: new FormControl<string | null>(null),
      dependsOn: new FormControl<string | null>(null),
      dataSource: new FormControl<string | null>(null),
      dataSourceDisplay: new FormControl<string | null>(null),
      displayInitialValue: new FormControl<boolean | null>(false),
    });
    this.attributeFieldGroups.push(form);

    if (attribute) {
      form.patchValue({ ...attribute });
      if (attribute.configuration) {
        form.patchValue({ ...attribute.configuration });
      }
    }
  }

  editAttrDefault(attr: FormGroup<AttributeForm>) {
    this.dialog.open(LayerAttributeEditionComponent, {
      data: { config: this.layer.config as WfsLayerConfig, attributes: this.attributeFieldGroups, attribute: attr },
      minWidth: '30vw',
      maxHeight: '90vh',
    });
  }

  onListDrop(event: CdkDragDrop<FormGroup<AttributeForm>[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.table?.renderRows();
  }

  private toLayerProperties(): LayerProperty[] {
    return this.attributeFieldGroups.value.map((attribute) => ({
      name: attribute.name ?? '',
      label: attribute.label ?? undefined,
      type: attribute.type ?? AttributeType.STRING,
      editable: !!attribute.editable,
      display: !!attribute.display,
      nillable: !!attribute.nillable,
      configuration: {
        defaultValues: attribute.defaultValues ?? {},
        defaultBoolean: attribute.defaultBoolean ?? false,
        min: !isNil(attribute.min) ? attribute.min : null,
        max: !isNil(attribute.max) ? attribute.max : null,
        dateMin: !isNil(attribute.dateMin) ? attribute.dateMin : null,
        dateMax: !isNil(attribute.dateMax) ? attribute.dateMax : null,
        pattern: attribute.pattern ?? null,
        excludePattern: (attribute.excludePattern?.length ?? 0) > 0 ? (attribute.excludePattern ?? null) : null,
        excludeValue: (attribute.excludeValue?.length ?? 0) > 0 ? (attribute.excludeValue ?? null) : null,
        dependsOn: attribute.dependsOn ?? undefined,
        dataSource: attribute.dataSource ?? null,
        dataSourceDisplay: attribute.dataSourceDisplay ?? null,
        displayInitialValue: attribute.displayInitialValue ?? false,
      },
    }));
  }

  private emitAttributes(): void {
    this.propertyUpdate.emit(this.attributeFieldGroups.valid ? this.toLayerProperties() : undefined);
  }
}

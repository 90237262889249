import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextItem } from '@core/model/context-item.model';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { Position } from '@core/model/position.model';
import { CommonService } from '@core/services/common.service';
import { TrapFilter } from '@widgets/components/statistic/statistic.model';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { Observable } from 'rxjs';
import { Trap } from '../icare-admin/trapview-trap-stats/trap.model';
import { IcareFilterConfig, InsectType } from './model/icare.model';
import { ReportStatistic, TrapDatum, TrapState, TrapStatistic } from './model/trap.model';

@Injectable({ providedIn: 'root' })
export class IcareService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/icare';

  public readonly filterState = new ContextItem<IcareFilterConfig>({
    displayFilter: 'FILTER_SIMULATION',
    dataFilter: {},
  });

  constructor(
    protected override http: HttpClient,
    private readonly geoserverFilter: GeoserverVariablesService
  ) {
    super(http);
  }

  getTrapsForStatistics(trapCode: string, query?: TrapFilter): Observable<{ [key in InsectType]?: TrapStatistic[] }> {
    const params = query ?? {};
    return this.http.get<{ [key in InsectType]?: TrapStatistic[] }>(
      this.baseUrl + '/' + trapCode + '/simulation/stats',
      {
        params,
      }
    );
  }

  getMultiTrapByZoneForStatistics(filter: TrapFilter): Observable<{ [key in InsectType]?: TrapStatistic[] }> {
    const customerFilterValue = this.geoserverFilter.customerFilterState.getValue();
    filter.customers = customerFilterValue != '*' ? customerFilterValue.replaceAll("'", '') : '';
    return this.http.get<{ [key in InsectType]?: TrapStatistic[] }>(
      this.baseUrl + '/multiTrapsInZone/simulation/stats' + filter.getGetMappingValue()
    );
  }

  /**
   * Obtenir les données de captures du rapport Eudémis (tableau).
   * @param date
   * @returns
   */
  getDateForTrapData(date: string): Observable<TrapDatum[]> {
    const params = {
      timestamp: date,
    };
    return this.http.get<TrapDatum[]>(this.baseUrl + '/icareReport/reportTrapData', { params });
  }

  /**
   * Obtenir les données de situation de vol du rapport Eudémis (tableau).
   * @param date
   * @returns
   */
  getDateForTrapStates(date: string): Observable<TrapState[]> {
    const params = {
      timestamp: date,
    };
    return this.http.get<TrapState[]>(this.baseUrl + '/icareReport/reportTrapStates', { params });
  }

  /**
   * Obtenir les statistiques sur les captures et les simulations Lobesia du rapport Eudémis (graphiques).
   * @param date
   * @returns
   */
  getDateForReportStatistics(date: string): Observable<ReportStatistic[]> {
    const params = {
      timestamp: date,
    };
    return this.http.get<ReportStatistic[]>(this.baseUrl + '/icareReport/reportTrapStatistics', { params });
  }

  getTrapPosition(trapCode: string): Observable<Position> {
    return this.http.get<Position>(`${this.baseUrl}/trap/${trapCode}`);
  }

  synchronizeCustomer() {
    this.http.get<void>(`${this.baseUrl}/trapview/customer/synchronize`).subscribe();
  }

  retrieveTrapViewTraps(appId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<Trap>> {
    return this.sendListQuery<Trap>(pagingInfo, `${this.baseUrl}/trapview/${appId}/traps`);
  }

  retrieveAllTrapViewTraps(appId: number, pagingInfo: PagingInfo): Observable<Trap[]> {
    let url = `${this.baseUrl}/trapview/${appId}/traps/list`;
    let params = new HttpParams();
    if (pagingInfo.filters.length) {
      url += '/search';
      params = pagingInfo.setPageFilters(params);
    }
    return this.http.get<Trap[]>(url, { params });
  }

  modifyCustomerOfTrapviewTraps(
    appId: number,
    customerCode: string,
    trapUuids?: string[],
    trapCodes?: string[],
    filter?: string | null
  ) {
    const body = {
      trapUuids,
      trapCodes,
      customerCode,
      filter,
    };
    return this.http.post<void>(`${this.baseUrl}/trapview/${appId}/traps/customer`, body);
  }
}

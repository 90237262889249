import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TableComponent } from '@components/table/table.component';
import { CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { Trap } from '@widgets/vigie-virose/model/trap.model';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';

@Component({
  standalone: true,
  selector: 'smv-vigievirose-user-trap-stats',
  templateUrl: './user-trap-stats.component.html',
  styleUrls: ['./user-trap-stats.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class UserTrapStatsComponent implements OnInit {
  @Input() title = $localize`Récapitulatif des pièges`;
  @Input() appId!: number;
  public trapsList: Trap[] = [];
  public selection: Trap[] = [];
  public actionsConfig: GeneralActions<Trap> = {};
  public columns: Column<Trap, DefaultType<number>>[] = [];
  public paging: PagingInfo = new PagingInfo('vv-trap-list');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private readonly notificationService: NotificationService,
    private readonly vigieViroseService: VigieViroseService
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      searchDisabled: false,
      searchPlaceholder: $localize`Rechercher un client`,
      refreshDisabled: true,
    };
    this.columns = [
      {
        field: 'id',
        type: CellType.String,
        label: 'id',
        cellData: 'id',
        sort: true,
        hide: true,
      },
      {
        field: 'code',
        label: $localize`Code`,
        type: CellType.String,
        cellData: 'code',
      },
      {
        field: 'active',
        label: $localize`Statut`,
        type: CellType.BooleanLabels,
        cellData: 'modified',
        cellDataFalse: 'Validé',
        cellDataTrue: 'À valider',
      },
      {
        field: 'customer',
        label: $localize`Client`,
        type: CellType.String,
        cellData: ['customer', 'name'],
        allowCellEmpty: true,
      },
      {
        field: 'cultivationCode',
        label: $localize`Culture`,
        type: CellType.String,
        cellData: 'cultivationCode',
      },
      {
        field: 'y',
        label: 'Y',
        type: CellType.String,
        cellData: 'y',
      },
      {
        field: 'x',
        label: 'X',
        type: CellType.String,
        cellData: 'x',
      },
      {
        field: 'zipCode',
        label: $localize`CP`,
        type: CellType.String,
        cellData: 'zipCode',
      },
      {
        field: 'cityName',
        label: $localize`Commune`,
        type: CellType.String,
        cellData: 'cityName',
      },
      {
        field: 'technician',
        label: $localize`Technicien`,
        type: CellType.String,
        cellData: ['technician', 'email'],
        allowCellEmpty: true,
      },
      {
        field: 'dateSemis',
        label: $localize`Date semis`,
        type: CellType.String,
        cellData: 'dateSemis',
      },
      {
        field: 'dateModif',
        label: $localize`Date modification`,
        type: CellType.String,
        cellData: 'dateModif',
      },
      {
        field: 'userModif',
        label: $localize`Modificateur`,
        type: CellType.String,
        cellData: ['userModif', 'email'],
        allowCellEmpty: true,
      },
      {
        field: 'dateCreat',
        label: $localize`Date création`,
        type: CellType.String,
        cellData: 'dateCreat',
      },
      {
        field: 'userCreat',
        label: $localize`Créateur`,
        type: CellType.String,
        cellData: ['userCreat', 'email'],
        allowCellEmpty: true,
      },
    ];

    this.findTraps();
  }

  findTraps() {
    this.vigieViroseService.retrieveCreatedTraps(this.appId, this.paging).subscribe({
      next: (result) => {
        if (result.page.totalElements > 0) {
          this.trapsList = result.content;
          for (const trap of result.content) {
            if (trap.x != undefined) {
              trap.x = Math.trunc(trap.x * 10000) / 10000;
            }
            if (trap.y != undefined) {
              trap.y = Math.trunc(trap.y * 10000) / 10000;
            }
          }
          this.trapsList = result.content;
        } else {
          this.trapsList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(traps?: Trap[]) {
    if (!traps) {
      this.vigieViroseService
        .retrieveAllCreatedTraps(this.appId, this.paging)
        .subscribe((trapList) => this.buildExportCalc(trapList));
    } else {
      this.buildExportCalc(traps);
    }
  }

  private buildExportCalc(traps: Trap[]) {
    const trapsSheet: XlsxSheet = {
      name: $localize`Récapitulatif des pièges`,
      headerRow: this.columns.slice(1).map((column) => column.label),
      dataRows: this.getExportRows(traps),
    };

    exportToXlsx($localize`export_piege_cree`, [trapsSheet]);
  }

  private getExportRows(traps: Trap[]): string[][] {
    const result: string[][] = [];
    traps.forEach((e) => {
      result.push([
        e.code,
        e.modified ? 'À valider' : 'Validé',
        e.customer.name,
        e.cultivationCode,
        String(e.y),
        String(e.x),
        e.zipCode,
        e.cityName,
        e.technician?.email ?? '',
        String(e.dateSemis ?? ''),
        String(e.dateModif ?? ''),
        e.userModif?.email ?? '',
        String(e.dateCreat ?? ''),
        e.userCreat?.email ?? '',
      ]);
    });
    return result;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParcelAreaStatistics } from '@core/model/parcel-api/parcel-stats.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParcelApiService {
  constructor(private http: HttpClient) {}

  computeStatsInArea(request: string): Observable<ParcelAreaStatistics> {
    return this.http.post<ParcelAreaStatistics>('/services-webapp-syngentamap/rest/parcels/area-statistics', request);
  }
}

<div class="smv-dialog">
  <h1 mat-dialog-title i18n>Choix du client</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <smv-form-field-wrapper
      [formControl]="customer"
      controlType="autocomplete"
      selectIdentifier="code"
      [selectOptions]="[]"
      [serverFilterOptions]="customerFilter"
      requireSelection
      required
      hasAction
    >
      <span mat-label i18n>Client</span>
      <button mat-icon-button (click)="customer.setValue(null)">
        <mat-icon>close</mat-icon>
      </button>
    </smv-form-field-wrapper>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" i18n="Button">Annuler</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="isDisabled()"
      [mat-dialog-close]="customer.value"
      i18n="Button"
    >
      Confirmer
    </button>
  </div>
</div>

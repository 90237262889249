<div class="smv-dialog">
  <h1 mat-dialog-title>{{ title() }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="content">
    @switch (state) {
      @case ('FETCHING') {
        <span i18n>Récupération des données de la parcelle...</span>
      }
      @case ('FORBIDDEN') {
        <span i18n>Parcelle déjà attribuée par un autre organisme</span>
      }
      @case ('ACCESS') {
        <smv-interra-scan-parcel-detail-form
          [appId]="data.appId"
          [(parcel)]="parcel"
          (formInvalid)="formInvalid = $event"
        />
      }
    }
    <div mat-dialog-actions>
      @switch (state) {
        @case ('ACCESS') {
          <button mat-button [mat-dialog-close]="false" i18n="Button">Annuler</button>
          <button mat-raised-button color="primary" [disabled]="formInvalid" (click)="saveParcel()" i18n="Button">
            Confirmer
          </button>
        }
        @default {
          <button mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
        }
      }
    </div>
  </div>
</div>

<smv-sheet-statistic [widgetLabel]="title" [loading]="loading" [isDataLoaded]="isDataLoaded" [titleMulti]="titleMulti">
  @if (multiMode) {
    <div statisticsMulti>
      @for (type of insectTypes; track type) {
        @if (trapMulti[type]) {
          <smv-icare-trap-statistics [insectType]="type" [multiple]="true" [traps]="trapMulti[type]" />
        }
      }
    </div>
  } @else if (traps.length) {
    <mat-accordion statisticsTrap>
      @for (trap of traps; track trap) {
        <mat-expansion-panel [expanded]="traps.length === 1">
          <mat-expansion-panel-header>
            <mat-panel-title class="title">
              @if (trap.trap_name) {
                {{ trap.trap_name }}
              } @else {
                {{ trap.trap_code }}
              }
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for (type of insectTypes; track type) {
            @if (trapStatements[trap.trap_code][type]) {
              <smv-icare-trap-statistics [insectType]="type" [traps]="trapStatements[trap.trap_code][type]" />
            }
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  }
</smv-sheet-statistic>

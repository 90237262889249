import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationModel } from '@core/model/application-api/application.model';
import { Application, ApplicationConfig, SpecificationConfig } from '@core/model/application.model';
import { ContextItem } from '@core/model/context-item.model';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { DataLayerConfig } from '@feature/client-carto-app/data-layer-config.model';
import { omit } from 'lodash-es';
import { Observable, map } from 'rxjs';
import { AdminService } from '../admin.service';

export interface SpecificationState {
  config?: SpecificationConfig;
  opened: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApplicationApiService extends AdminService {
  protected override readonly baseUrl: string = this.baseUrl + '/application';

  public applicationLayers: DataLayerConfig[] = [];
  public currentApplication = new ContextItem<Application | undefined>(undefined);
  public settingsPanelState = new ContextItem<boolean | undefined>(undefined);
  public isSpecification = new ContextItem<SpecificationState>({ opened: false });

  constructor(protected override http: HttpClient) {
    super(http);
  }

  checkManageableApplications(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/manageableApp/check');
  }

  getLoggedUserApplications(pagingInfo?: PagingInfo): Observable<PaginatedResult<ApplicationModel>> {
    return this.sendListQuery(pagingInfo, this.baseUrl + '/listMyApp');
  }

  getAccessibleApplications(pagingInfo?: PagingInfo): Observable<PaginatedResult<ApplicationModel>> {
    return this.sendListQuery(pagingInfo, this.baseUrl + '/listAccessibleApp');
  }

  getManageableApplications(pagingInfo: PagingInfo): Observable<PaginatedResult<ApplicationModel>> {
    return this.sendListQuery(pagingInfo, this.baseUrl + '/manageableApp');
  }

  getApplicationsByUser(userId: number, pagingInfo: PagingInfo): Observable<PaginatedResult<ApplicationModel>> {
    return this.sendListQuery(pagingInfo, this.baseUrl + '/byUser/' + userId);
  }

  getApplication(id: number | string): Observable<Application> {
    return this.pipeExtractApplication(this.http.get<Application>(this.baseUrl + `/${id}`));
  }

  addApplication(application: ApplicationModel): Observable<Application> {
    return this.pipeExtractApplication(this.http.post<Application>(this.baseUrl + '/addApplication', application));
  }

  updateApplication(application: ApplicationModel): Observable<Application> {
    return this.pipeExtractApplication(this.http.put<Application>(this.baseUrl + '/' + application.id, application));
  }

  updateApplicationConfig(application: ApplicationModel, configuration: ApplicationConfig): Observable<Application> {
    const payload = omit(application, ['parsedConfig']);
    payload.config = configuration.toApiConfig();
    return this.pipeExtractApplication(this.http.put<Application>(`${this.baseUrl}/${application.id}`, payload));
  }

  saveLogo(application: ApplicationModel, logo: File): Observable<ApplicationModel> {
    const formData: FormData = new FormData();
    formData.append('file', logo, logo.name);

    return this.pipeExtractApplication(
      this.http.put<Application>(this.baseUrl + `/${application.id}/logo/${logo.name}`, formData)
    );
  }

  deleteLogo(id: number): Observable<ApplicationModel> {
    return this.pipeExtractApplication(this.http.delete<Application>(this.baseUrl + `/${id}/logo`));
  }

  getHelpPdf(id: number) {
    return this.http.get(this.baseUrl + `/${id}/help-pdf`, { responseType: 'blob' });
  }

  saveHelpPdf(application: ApplicationModel, pdf: File): Observable<ApplicationModel> {
    const formData: FormData = new FormData();
    formData.append('file', pdf, pdf.name);

    return this.pipeExtractApplication(
      this.http.put<Application>(this.baseUrl + `/${application.id}/help-pdf`, formData)
    );
  }

  deleteHelpPdf(id: number): Observable<ApplicationModel> {
    return this.pipeExtractApplication(this.http.delete<Application>(this.baseUrl + `/${id}/help-pdf`));
  }

  editFeature(url: string, transaction: Node) {
    const bodyString = new XMLSerializer().serializeToString(transaction);

    return this.http.post(url, bodyString, { responseType: 'text' });
  }

  private pipeExtractApplication(obs: Observable<Application>): Observable<Application> {
    return obs.pipe(
      map((result: Application) => ({
        ...result,
        parsedConfig: new ApplicationConfig(result.config),
      }))
    );
  }
}

<div class="smv-dialog farmer-form">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <form [formGroup]="form" class="smv-form">
      <smv-form-field-wrapper formControlName="firstName">
        <span mat-label i18n="Label|First name">Prénom</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="lastName">
        <span mat-label i18n="Label|Last name">Nom</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="farmName">
        <span mat-label i18n="Label|Farm name">Exploitation</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="siret">
        <span mat-label i18n="Label|Siret">Siret</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper formControlName="email" required>
        <span mat-label i18n="Label|Email address">Adresse email</span>
      </smv-form-field-wrapper>
      <smv-phone-field formControlName="phone" class="form-control" />
      <smv-form-field-wrapper
        formControlName="customer"
        controlType="select"
        [selectOptions]="customers"
        selectIdentifier="id"
        required
      >
        <span mat-label i18n="Label|Customer">Client</span>
      </smv-form-field-wrapper>
      <smv-form-field-wrapper
        formControlName="technician"
        controlType="select"
        [selectOptions]="technicians"
        selectIdentifier="id"
        required
      >
        <span mat-label i18n="Label|Technician">Technicien</span>
      </smv-form-field-wrapper>
    </form>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false" i18n="Button">Annuler</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        [mat-dialog-close]="form.getRawValue()"
        i18n="Button"
      >
        Confirmer
      </button>
    </div>
  </div>
</div>

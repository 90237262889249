import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DialogService } from '@components/dialog/dialog.service';
import { TableComponent } from '@components/table/table.component';
import {
  ActionType,
  CellType,
  Column,
  DefaultAction,
  DefaultType,
  GeneralActions,
  SelectionAction,
} from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { AuthService } from '@core/services/auth.service';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { InterraScanService } from '../interra-scan.service';
import { ParcelStats } from '../model/parcel.stats.model';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-parcel-stats',
  templateUrl: './parcel-stats.component.html',
  styleUrls: ['./parcel-stats.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class ParcelStatsComponent implements OnInit {
  @Input() title = $localize`:InterraScan|Parcel overview:Récapitulatif des parcelles`;
  @Input() appId!: number;
  public parcelsList: ParcelStats[] = [];
  public selection: ParcelStats[] = [];
  public actionsConfig: GeneralActions<ParcelStats> = {};
  public columns: Column<ParcelStats, DefaultType<string>>[] = [];
  public paging: PagingInfo = new PagingInfo('interra-scan-parcel-list');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private readonly interraScanService: InterraScanService,
    private readonly mapService: MapService,
    private readonly notificationService: NotificationService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    this.authService.isOperateur().subscribe((isOp) => {
      this.actionsConfig = {
        checkboxAllSelected: true,
        searchDisabled: false,
        deleteSelectedDisabled: isOp,
        deleteTooltip: $localize`:InterraScan|Delete parcels:Supprimer l'attribution de parcelles`,
        searchPlaceholder: $localize`:InterraScan|Parcel overview:Rechercher par parcelle/agriculteur/package/technicien`,
        refreshDisabled: true,
      };
    });
    this.columns = [
      {
        field: 'geom',
        label: $localize`:Shape|Shape:Forme parcelle`,
        type: CellType.GeoJson,
        cellData: 'geom',
      },
      {
        field: 'parcelId',
        label: $localize`:Label|Parcel id:Parcelle n°`,
        type: CellType.String,
        cellData: 'parcelId',
        sort: true,
      },
      {
        field: 'parcelName',
        label: $localize`:Label|Parcel name:Parcelle`,
        type: CellType.String,
        cellData: 'parcelName',
        sort: true,
      },
      {
        field: 'farmer.customer.name',
        label: $localize`:Label|Customer:Client`,
        type: CellType.String,
        cellData: 'customer',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'farmer.technician.email',
        label: $localize`:Label|Technician:Technicien`,
        type: CellType.String,
        cellData: 'technician',
        sort: true,
      },
      {
        field: 'operator.email',
        label: $localize`:Label|Operator:Opérateur`,
        type: CellType.String,
        cellData: 'operator',
        sort: true,
      },
      {
        field: 'farmer.email',
        label: $localize`:Label|Farmer:Agriculteur`,
        type: CellType.String,
        cellData: 'farmerMail',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'farmer.phone',
        label: $localize`:Label|Phone Number:Téléphone`,
        type: CellType.String,
        cellData: 'farmerPhone',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'order.name',
        label: $localize`:Label|Order:Commande`,
        type: CellType.String,
        cellData: 'orderName',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'currentCrop.label',
        label: $localize`:Label|Current crop:Culture en place`,
        type: CellType.String,
        allowCellEmpty: true,
        cellData: 'currentCrop',
        sort: true,
      },
      {
        field: 'harvestDate',
        label: $localize`:Label|Harvest date:Date de récolte`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'harvestDate',
        sort: true,
      },
      {
        field: 'nextCrop',
        label: $localize`:Label|Next crop:Culture suivante`,
        type: CellType.String,
        allowCellEmpty: true,
        cellData: 'nextCrop',
        sort: true,
      },
      {
        field: 'sowingDate',
        label: $localize`:Label|Sowing date:Date de semis`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'sowingDate',
        sort: true,
      },
      {
        field: 'order.idealScanDate',
        label: $localize`:Label|ideal scan date:Date de scan idéale`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'idealScanDate',
        sort: true,
      },
      {
        field: 'selectedPackage.label',
        label: $localize`:Label|InterraScan package:Package`,
        type: CellType.String,
        cellData: 'packageLabel',
        sort: true,
      },
      {
        field: 'physicalAccess',
        label: $localize`:Label|Parcel physical access:Commentaire d'accès physique`,
        type: CellType.String,
        cellData: 'physicalAccess',
      },
      {
        field: 'surface',
        label: $localize`:Label|Area:Surface (ha)`,
        type: CellType.String,
        cellData: 'surface',
        sort: true,
      },
      {
        field: 'longitude',
        label: 'X',
        type: CellType.String,
        cellData: 'longitude',
      },
      {
        field: 'latitude',
        label: 'Y',
        type: CellType.String,
        cellData: 'latitude',
      },
      {
        field: 'scanned',
        label: $localize`:Label|Scanned:Scannée`,
        type: CellType.Boolean,
        cellData: 'scanned',
        sort: true,
      },
      {
        field: 'actualSurface',
        label: $localize`:Label|Actual surface:Surface réelle`,
        type: CellType.String,
        cellData: 'actualSurface',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'scanTime',
        label: $localize`:Label|Scan time:Temps de scan`,
        type: CellType.String,
        cellData: 'scanTime',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'modificator',
        label: $localize`:Label|Last editor:Modificateur`,
        type: CellType.String,
        cellData: 'modificatorEmail',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'modificationDate',
        label: $localize`:Label|Last modification date:Dernière modification`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'modificationDate',
      },
      {
        field: 'creationDate',
        label: $localize`:Label|Creation date:Création`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'creationDate',
      },
      {
        field: 'actions',
        label: '',
        type: CellType.Action,
        cellData: '',
        actions: [
          {
            type: ActionType.Menu,
            tooltip: $localize`:Access tooltip:Plus d'actions`,
            identifier: 'id',
            menu: [
              {
                type: ActionType.Default,
                identifier: 'parcelId',
                icon: 'fit_screen',
                label: $localize`:Button:Zoomer sur la parcelle`,
                tooltip: $localize`:InterraScan|Zoom tooltip:Zoomer sur la parcelle`,
              },
              {
                type: ActionType.Link,
                linkGetter: (row) => this.createMapsLink(row.latitude, row.longitude),
                label: $localize`:InterraScan|Google map:Google map`,
                icon: 'map',
                identifier: 'parcelId',
                tooltip: $localize`:InterraScan| Google map link:Lien google map`,
              },
              {
                type: ActionType.Delete,
                hide: true,
                hideCondition: (parcel) =>
                  !!this.authService.getUserEmailInSync() && parcel.operator === this.authService.getUserEmailInSync(),
                identifier: 'parcelId',
                label: $localize`:Button:Supprimer`,
                tooltip: $localize`:InterraScan|Delete tooltip:Supprimer l'attribution de cette parcelle`,
              },
            ],
          },
        ],
      },
    ];
    this.findParcels();
  }

  createMapsLink(latitude: number, longitude: number) {
    return `https://maps.google.com/?q=${latitude},${longitude}`;
  }

  findParcels() {
    this.interraScanService.retrieveParcels(this.appId, this.paging).subscribe({
      next: (result) => {
        this.parcelsList = result.content || [];
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(parcels?: ParcelStats[]) {
    if (!parcels) {
      this.interraScanService
        .retrieveAllParcels(this.appId, this.paging)
        .subscribe((allParcels) => this.buildExportCalc(allParcels));
    } else {
      this.buildExportCalc(parcels);
    }
  }

  private buildExportCalc(parcels: ParcelStats[]) {
    const columnsToExport = this.columns.filter((column) => !['geom', 'actions'].includes(column.field));
    const rowBuilder = (parcel: ParcelStats) =>
      columnsToExport.map((column) => String(parcel[column.cellData as keyof ParcelStats] ?? ''));

    const parcelsSheet: XlsxSheet = {
      name: $localize`:InterraScan|Export Sheet:Récapitulatif des parcelles`,
      headerRow: columnsToExport.map((column) => column.label),
      dataRows: parcels.map(rowBuilder),
    };

    exportToXlsx($localize`:InterraScan|Export file:export_parcelles`, [parcelsSheet]);
  }

  openConfirmDeleteDialog(deleteData: SelectionAction<string>) {
    if (deleteData.identifiers.length > 0) {
      this.dialogService.openParcelDeleteConfirm(deleteData.identifiers).subscribe((result: boolean) => {
        if (result) {
          this.interraScanService.deleteParcels(deleteData.ids).subscribe({
            next: () => {
              this.notificationService.success(
                $localize`:InterraScan|Parcel deletion successful:Les attributions des parcelles ont été correctement supprimés.`
              );
              this.findParcels();
            },
            error: (error: HttpErrorResponse) => {
              if (error.status == 400) {
                this.notificationService.error(
                  $localize`:InterraScan|Parcel not found:Une erreur est survenue, la parcelle n'a pas été trouvé. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
                );
              } else {
                this.notificationService.unknownServerError();
              }
            },
          });
        }
      });
    }
  }

  zoomToParcel(data: DefaultAction) {
    this.interraScanService.retrieveParcelPosition(data.identifier).subscribe({
      next: (position) => {
        if (position?.geom) {
          this.mapService.fitToGeom(position?.geom);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.notificationService.error(error.error.ErrorResponse.message.split(';')[0]);
      },
    });
  }
}

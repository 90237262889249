@if (application && currentUser) {
  <div class="layout" [class]="application.functionnalityConfig.code ?? 'standard'">
    @if (application.configurationType === 'CARTO') {
      <smv-client-carto-app [(application)]="application" [currentUser]="currentUser" />
    } @else {
      <div class="standard-app-container">
        <!-- Standard apps should be used through custom routes -->
        <router-outlet />
      </div>
    }
  </div>
}

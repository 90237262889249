<mat-expansion-panel expanded>
  <mat-expansion-panel-header>{{ title }}</mat-expansion-panel-header>

  <div class="filter">
    @if (!(isOperateur$ | async)) {
      <smv-customer-filter [appId]="appId()" />
    }
    <form [formGroup]="form">
      <div class="form-control">
        <smv-form-field-wrapper
          formControlName="technician"
          controlType="autocomplete"
          selectIdentifier="email"
          [selectOptions]="technicians"
          requireSelection
        >
          <span mat-label i18n="Label|Technician">Techniciens</span>
        </smv-form-field-wrapper>
        <smv-form-field-wrapper
          formControlName="operator"
          controlType="autocomplete"
          selectIdentifier="email"
          [selectOptions]="operators"
          requireSelection
        >
          <span mat-label i18n="Label|Operator">Opérateur</span>
        </smv-form-field-wrapper>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header i18n="Label|Scanned">Parcelles scannées</mat-expansion-panel-header>
          <mat-radio-group formControlName="scanned" [selected]="default" class="scanned">
            <mat-radio-button #default [value]="undefined" i18n="Button|All">Toutes</mat-radio-button>
            <mat-radio-button [value]="true" i18n="Button|Yes">Oui</mat-radio-button>
            <mat-radio-button [value]="false" i18n="Button|No">Non</mat-radio-button>
          </mat-radio-group>
        </mat-expansion-panel>
      </div>
    </form>
  </div>
</mat-expansion-panel>

import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

export class PagingInfo {
  public pageSize = this.getPageSize();
  public sort: { column: string; order: SortDirection }[] = [];
  public currentPage = 0;
  public nbTotalRes = 0;
  public filters: { name: string; value: string | number | boolean }[] = [];

  constructor(private readonly storageKey?: string) {}

  setNbTotalRes(nb: number) {
    this.nbTotalRes = nb;
  }

  setPageSize(size: number) {
    if (this.storageKey) {
      localStorage.setItem(this.storageKey, `${size}`);
    }
    this.pageSize = size;
  }

  getPageSize(): number {
    const userPageSize = this.storageKey ? localStorage.getItem(this.storageKey) : null;
    return userPageSize ? +userPageSize : 25;
  }

  setPageParams(params: HttpParams): HttpParams {
    let updatedParams = params.set('size', this.pageSize).set('page', this.currentPage);
    this.sort.forEach((sort) => {
      updatedParams = updatedParams.append('sort', `${sort.column},${sort.order}`);
    });
    return updatedParams;
  }

  setPageFilters(params: HttpParams): HttpParams {
    let updatedParams = params;
    this.filters.forEach((filter) => {
      updatedParams = updatedParams.append(filter.name, filter.value);
    });
    return updatedParams;
  }

  addPageFilter(name: string, value: string | number | boolean) {
    this.filters.push({ name, value });
  }
}

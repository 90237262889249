import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { PhoneFieldComponent } from '@components/form/phone-field/phone-field.component';
import { Customer } from '@core/model/customer.model';
import { User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { CustomerService } from '@core/services/customer.service';
import { Farmer } from '@feature/client-carto-app/widgets/interra-scan/model/farmer.model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'smv-interra-scan-farmer-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
    PhoneFieldComponent,
  ],
  templateUrl: './farmer-form.component.html',
  styleUrls: ['./farmer-form.component.scss'],
})
export class InterraScanFarmerFormComponent {
  public currentFarmer?: Farmer = inject(MAT_DIALOG_DATA);

  public title = $localize`:InterraScan|Form title:Création d'un agriculteur`;
  public customers: DropdownOption<Customer>[] = [];
  public technicians: DropdownOption<User>[] = [];

  private appId?: number;

  public form = this.formBuilder.group({
    firstName: [undefined as string | undefined, Validators.required],
    lastName: [undefined as string | undefined, Validators.required],
    farmName: [undefined as string | undefined, Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [undefined as string | undefined, Validators.required],
    customer: [undefined as Customer | undefined, Validators.required],
    siret: [undefined as string | undefined],
    technician: [undefined as User | undefined, Validators.required],
  });

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly customerService: CustomerService,
    private readonly applicationService: ApplicationApiService
  ) {
    if (!isNil(this.currentFarmer)) {
      this.form.patchValue(this.currentFarmer);
      this.title = $localize`:InterraScan|Form title:Modification d'un agriculteur`;
    }
    this.appId = this.applicationService.currentApplication.getValue()?.id;
    if (this.appId) {
      this.customerService.getMyCustomers(this.appId, false).subscribe((customers) => {
        this.customers = customers.map((customer) => {
          return {
            label: `${customer.name} (${customer.code})`,
            value: customer,
          };
        });
        if (!isNil(this.currentFarmer)) {
          this.form.patchValue(this.currentFarmer);
        }
      });
    }
    this.form.controls.customer.valueChanges.subscribe((value) => this.getSelectTechniciansOptions(value));
  }

  private getSelectTechniciansOptions(customer?: Customer) {
    if (customer && this.appId) {
      this.customerService
        .getTechniciansOfCustomer(customer.code, this.appId)
        .subscribe((technicians) => (this.technicians = technicians.map((t) => ({ label: t.email, value: t }))));
    }
  }
}

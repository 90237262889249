import { Injectable, signal } from '@angular/core';
import { Feature, Map, MapBrowserEvent } from 'ol';
import { Select } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';

import { ContextItem } from '@core/model/context-item.model';
import { SelectEvent } from 'ol/interaction/Select';

interface Selection {
  added?: Feature[];
  updated?: Feature[];
  deleted?: Feature[];
  init?: boolean;
}

type EventType = 'click' | 'contextmenu';

@Injectable({ providedIn: 'root' })
export class MultipleSelectionService {
  private selection = new Select({
    style: new Style({
      stroke: new Stroke({ color: [153, 0, 153], width: 2 }),
      fill: new Fill({ color: [153, 0, 153, 0.5] }),
    }),
  });

  public selectedFeatureTooltip = new ContextItem<Feature | null>(null);
  public readonly selectionChange = signal<Selection>({});

  private multipleSelectionSource = new VectorSource({});

  private map?: Map;

  initSelectionService(mapInstance: Map) {
    this.map = mapInstance;
    this.addClickSelections();
    this.addRightClickSelection();
  }

  initHighlightSectionLayer() {
    const fillStyle = new Fill({
      color: 'rgb(195, 0, 255)',
    });
    new VectorLayer({
      properties: {
        title: 'Multiple Selection Layer',
      },
      map: this.map,
      source: this.multipleSelectionSource,
      style: [
        new Style({
          image: new Circle({
            fill: fillStyle,
            radius: 10,
          }),
          fill: fillStyle,
        }),
      ],
      zIndex: 300,
    });
  }

  addClickSelections() {
    this.map?.addInteraction(this.selection);
    this.selection.on('select', this.onFeatureSelected.bind(this));
    this.initHighlightSectionLayer();
  }

  addRightClickSelection() {
    this.map?.getViewport().addEventListener('contextmenu', (event: MouseEvent) => {
      event.preventDefault();
      if (this.map) {
        const pixel = this.map.getEventPixel(event);
        const mapBrowserEvent = new MapBrowserEvent('singleclick', this.map, event, false);
        (mapBrowserEvent as MapBrowserEvent<MouseEvent>).pixel = pixel;
        this.selectFeature(mapBrowserEvent, 'contextmenu');
      }
    });
    this.map?.addEventListener('movestart', () => this.selectedFeatureTooltip.reset());
  }

  private onFeatureSelected(event: SelectEvent) {
    this.selectedFeatureTooltip.reset();
    if (event.selected.length) {
      this.updateSelection(event.selected[0], 'click');
    }
  }

  private selectFeature(event: MapBrowserEvent<MouseEvent>, eventType: EventType = 'click') {
    this.selectedFeatureTooltip.reset();

    if (!this.map) return;

    const features =
      this.map.getFeaturesAtPixel(event.pixel, {
        layerFilter: (l) => {
          return l.getZIndex() > 1;
        },
      }) ?? [];
    if (features.length === 0) return;
    this.updateSelection(features[0] as Feature, eventType);
  }

  public updateSelection(feature: Feature, eventType: EventType) {
    if (this.selection.getActive()) {
      const matchingFeature = this.multipleSelectionSource
        .getFeatures()
        .find((f) => f.get('id_parcel') === feature.get('id_parcel'));
      if (eventType === 'contextmenu') {
        if (matchingFeature) {
          this.selectedFeatureTooltip.setValue(matchingFeature);
        }
      } else {
        if (!matchingFeature) {
          this.multipleSelectionSource.addFeature(feature);
          this.selectionChange.set({ added: [feature] });
        }
      }
    }
  }

  public updateSelectionName(feature: Feature) {
    if (this.selection.getActive()) {
      const matchingFeature = this.multipleSelectionSource
        .getFeatures()
        .find((f) => f.get('id_parcel') === feature.get('id_parcel'));
      if (matchingFeature) {
        this.multipleSelectionSource.removeFeature(matchingFeature);
        this.multipleSelectionSource.addFeature(feature);
        this.selectionChange.set({ updated: [feature] });
        this.selectedFeatureTooltip.reset();
      }
    }
  }

  public deleteSelection(feature: Feature) {
    if (this.selection.getActive()) {
      const matchingFeature = this.multipleSelectionSource
        .getFeatures()
        .find((f) => f.get('id_parcel') === feature.get('id_parcel'));
      if (matchingFeature) {
        this.multipleSelectionSource.removeFeature(matchingFeature);
        this.selectionChange.set({ deleted: [feature] });
        this.selectedFeatureTooltip.reset();
      }
    }
  }

  public initSelection(features: Feature[]) {
    this.multipleSelectionSource.clear();
    this.multipleSelectionSource.addFeatures(features);
    this.selectionChange.set({ init: true, added: features });
  }

  public resetSelection() {
    this.multipleSelectionSource.clear();
    this.selectionChange.set({ init: true, added: [] });
  }
}

<div class="smv-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="content">
    <smv-table
      class="table mat-elevation-z2"
      identifier="id"
      [generalActions]="actionsConfig"
      [columns]="columns"
      [data]="farmersList"
      (selectedRows)="selection = $event"
      (add)="openModifyDialog()"
      (modify)="openModifyDialog($event)"
      (refresh)="paging = $event; findFarmers()"
      (delete)="openConfirmDeleteDialog($event)"
      [pagingInfo]="paging"
    />
  </div>
  <div mat-dialog-actions>
    @if (paging.nbTotalRes) {
      <button mat-flat-button color="primary" (click)="exportToCalc()" i18n="Button">
        Exporter les {{ paging.nbTotalRes }} résultats
      </button>
    }

    <button
      mat-flat-button
      color="primary"
      (click)="exportToCalc(selection)"
      [disabled]="!selection.length"
      i18n="Button"
    >
      Exporter la sélection
    </button>
    <button mat-flat-button mat-dialog-close i18n="Button">Fermer</button>
  </div>
</div>

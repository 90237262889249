import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Application } from '@core/model/application.model';
import { User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { NavigationService } from '@core/services/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { ClientCartoAppModule } from '@feature/client-carto-app/client-carto-app.module';

@Component({
  selector: 'smv-client-app-layout',
  standalone: true,
  imports: [ClientCartoAppModule, RouterModule],
  templateUrl: './client-app-layout.component.html',
  styleUrl: './client-app-layout.component.scss',
})
export class ClientAppLayoutComponent implements OnInit, OnDestroy {
  public application?: Application;
  public currentUser?: User;

  constructor(
    private readonly applicationApi: ApplicationApiService,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly navigation: NavigationService
  ) {}

  ngOnInit(): void {
    const appId = this.route.snapshot.paramMap.get('id');
    if (appId) {
      this.authService.whoAmI().subscribe((user: User) => {
        if (user) {
          this.currentUser = user;
        }
        this.loadApplication(appId);
      });
    }
  }

  ngOnDestroy(): void {
    this.navigation.headerTitle.set(undefined);
    this.navigation.headerImage.set(undefined);
    this.applicationApi.currentApplication.saveToBackup();
    this.applicationApi.currentApplication.setValue(undefined);
    this.applicationApi.settingsPanelState.setValue(undefined);
    this.applicationApi.isSpecification.reset();
    this.authService.resetSpecifierUser();
  }

  private loadApplication(appId: string) {
    this.applicationApi.getApplication(appId).subscribe({
      next: (app) => {
        this.application = app;
        this.applicationApi.currentApplication.setValue(app);

        if (app.displayedTitle) {
          this.navigation.headerTitle.set(app.nom);
        }
        if (app.logoName) {
          this.navigation.headerImage.set({
            id: app.id,
            name: app.logoName,
            baseUrl: this.applicationApi.getBaseUrl(),
          });
        } else {
          this.navigation.headerImage.set(undefined);
        }

        if (this.application.configurationType === 'STANDARD') {
          this.loadStandardApp();
        }
      },
      error: (error) => {
        if (error.status == 401) {
          this.notificationService.error(
            $localize`:Application Display|Error not accessible:Vous n'avez pas les autorisations nécessaires pour accéder à l'application demandée.`
          );
        } else if (error.status == 403) {
          this.notificationService.error(
            $localize`:Application Display|Error not connected:L'application à laquelle vous avez tenté d'accéder est privée. Veuillez vous connecter pour y accéder.`
          );
        } else if (error.status == 404) {
          this.notificationService.error(
            $localize`:Application Display|Error not found:L'application à laquelle vous avez tenté d'accéder n'existe pas.`
          );
        } else {
          this.notificationService.unknownServerError();
        }
        this.router.navigate(['']);
      },
    });
  }

  private loadStandardApp(): void {
    if (this.application?.functionnalityConfig.code === 'CHAMP_PROPRE') {
      this.router.navigate(['./champ-propre'], { relativeTo: this.route });
    }
  }
}

import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Indexable } from '@core/utils/general.utils';
import { Observable, map } from 'rxjs';

const REST_API = 'services-webapp-syngentamap/rest';

/**
 * Auto-extract wrapped body
 * Ex: { User: { id: 1 } } => { id: 1 }
 */
export const responseWrapperInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    map((resp) => {
      const isRestResponse = resp instanceof HttpResponse && resp.body && typeof resp.body === 'object';
      if (req.url.includes(REST_API) && isRestResponse) {
        const keys = Object.keys(resp.body);
        let newBody = resp.body;
        if (keys.length) {
          newBody = (resp.body as Indexable<object>)[keys[0]];
        }
        return resp.clone({ body: newBody });
      }

      return resp;
    })
  );
};

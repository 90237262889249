<div [hidden]="panelHide()">
  <mat-card class="side-panel validation-panel">
    <mat-card-title>
      <div>
        <span i18n="Edition Validation|Title" class="unselectable">Validation de l'édition</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <mat-slide-toggle [formControl]="validationView" class="unselectable" i18n>
        Activer la vue de validation
      </mat-slide-toggle>

      @if (validationView.value && layerConfig) {
        @if (!this.loading) {
          <p>
            {{ displayEditedFeatures?.length ?? 0 }}
            <span i18n="Edition Validation|Number of edited features">
              entité(s) à valider correspond(ent) aux filtres
            </span>
            <br />
            @if (warnings > 0) {
              <span i18n="Edition Validation|Number of edited features in warning" class="warning-label">
                dont {{ warnings }} en alerte
              </span>
            }
          </p>
        } @else {
          <div class="valign_childs">
            <mat-icon class="spin">autorenew</mat-icon>
            <p>Chargement en cours</p>
          </div>
        }
        <button
          mat-stroked-button
          (click)="zoomToEditedFeature()"
          class="zoom"
          [disabled]="!displayEditedFeatures"
          i18n="Edition Validation|Zoom to an edited feature"
        >
          Zoomer sur une entité à valider
        </button>
        @if (layerConfig.validationConfiguration?.warnings) {
          <smv-validation-filter-warning
            [layerConfig]="layerConfig"
            [editedFeatures]="editedFeatures"
            [originalFeatures]="originalFeatures"
            (warningIds)="handleWarnings($event)"
          />
        }
        @if (linkedEntities && linkedEntities.duplicates.length) {
          <smv-validation-filter-duplicate [duplicates]="linkedEntities.duplicates" />
        }

        <smv-validation-filter-type />

        <smv-validation-filter-data [layer]="layerConfig" />

        <smv-validation-feature (feature)="selectedFeature = $event" />

        <div class="wrapper smv-sticky-bottom-actions">
          <div class="valid-button">
            <div
              matTooltip="Plusieurs propositions de modifications pour une même entité font parties des entités filtrées"
              [matTooltipDisabled]="disableTooltip()"
              matTooltipPosition="before"
              i18n-matTooltip="Edition Validation|Multiple proposals"
            >
              <button
                mat-raised-button
                color="warn"
                (click)="saveDecision('REJECT')"
                [ngClass]="{ btnSpinner: isSaving }"
                [disabled]="disableButton('REJECT')"
              >
                @if (notifyOption.value && notifyConfig.REJECT) {
                  <mat-icon>email</mat-icon>
                }
                {{ labelRejectButton }}
              </button>
            </div>
            <div
              matTooltip="Plusieurs propositions de modifications pour une même entité font parties des entités filtrées"
              [matTooltipDisabled]="disableTooltip()"
              matTooltipPosition="before"
              i18n-matTooltip="Edition Validation|Multiple proposals"
            >
              <button
                mat-raised-button
                color="primary"
                (click)="saveDecision('VALID')"
                [ngClass]="{ btnSpinner: isSaving }"
                [disabled]="disableButton('VALID')"
              >
                @if (notifyOption.value && notifyConfig.VALID) {
                  <mat-icon>email</mat-icon>
                }
                {{ labelValidButton }}
              </button>
            </div>
          </div>
          <div class="options">
            @if (layerConfig.validationConfiguration && (groupedOption.enabled || notifyOption.enabled)) {
              <button mat-icon-button [matMenuTriggerFor]="validationOptionsMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
            }
          </div>
        </div>
      }
    </mat-card-content>
  </mat-card>
</div>

<mat-menu #validationOptionsMenu class="unselectable">
  <mat-radio-group [formControl]="groupedOption">
    @for (option of optionsGrouped; track $index) {
      <mat-radio-button (click)="$event.stopPropagation()" [value]="option.value">
        {{ option.label }}
      </mat-radio-button>
    }
  </mat-radio-group>
  <mat-divider />
  <mat-radio-group [formControl]="notifyOption">
    @for (option of optionsNotify; track $index) {
      <mat-radio-button (click)="$event.stopPropagation()" [value]="option.value">
        {{ option.label }}
      </mat-radio-button>
    }
  </mat-radio-group>
</mat-menu>
